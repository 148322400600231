import { Theme } from '../types/types';

export const DEFAULT_THEMES: Theme[] = [
  { id: 'belgian-train', name: 'Belgian Train', className: 'belgian-train' },
  { id: 'mountain', name: 'Mountain', className: 'mountain' },
  { id: 'forest', name: 'Forest', className: 'forest' },
  { id: 'dark-soul', name: 'Dark Soul', className: 'dark-soul' },
  { id: 'terminal', name: 'Terminal', className: 'terminal' },
  { id: 'dirty-purple', name: 'Dirty Purple', className: 'dirty-purple' },
  {
    id: 'dont-try-that-one',
    name: "Don't Try That One",
    className: 'dont-try-that-one',
  },
  { id: 'mkbhd', name: 'MKBHD', className: 'mkbhd' },
  { id: 'more-coffee', name: 'More Coffee', className: 'more-coffee' },
  { id: 'boring-white', name: 'Boring White', className: 'boring-white' },
];
