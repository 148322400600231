export const vsCodeShortchutMac = [
  { key: 'shift+meta+minus', command: 'Zoom Out' },
  { key: 'shift+meta+p', command: 'Show Command Palette' },
  { key: 'meta+p', command: 'Quick Open' },
  // { "key": "shift+meta+n", "command": "New Window" },
  // { "key": "meta+w", "command": "Close Window" },
  { key: 'meta+comma', command: 'Open Settings' },
  // { "key": "meta+k meta+s", "command": "Open Keyboard Shortcuts" },
  { key: 'meta+x', command: 'Cut' },
  { key: 'meta+c', command: 'Copy' },
  { key: 'alt+down', command: 'Move Lines Down' },
  { key: 'alt+up', command: 'Move Lines Up' },
  { key: 'shift+alt+down', command: 'Copy Lines Down' },
  { key: 'shift+alt+up', command: 'Copy Lines Up' },
  { key: 'shift+meta+k', command: 'Delete Lines' },
  { key: 'meta+enter', command: 'Insert Line Below' },
  { key: 'shift+meta+enter', command: 'Insert Line Above' },
  { key: 'shift+meta+backslash', command: 'Jump to Bracket' },
  { key: 'meta+bracketright', command: 'Indent Line' },
  { key: 'meta+bracketleft', command: 'Outdent Line' },
  { key: 'home', command: 'Go to Line Start' },
  { key: 'end', command: 'Go to Line End' },
  { key: 'meta+up', command: 'Go to File Top' },
  { key: 'meta+down', command: 'Go to File Bottom' },
  // { "key": "ctrl+pgup", "command": "Scroll Line Up" },
  // { "key": "ctrl+pgdn", "command": "Scroll Line Down" },
  // { "key": "meta+pgup", "command": "Scroll Page Up" },
  // { "key": "meta+pgdn", "command": "Scroll Page Down" },
  { key: 'alt+meta+bracketleft', command: 'Fold' },
  { key: 'alt+meta+bracketright', command: 'Unfold' },
  // { "key": "meta+k meta+[", "command": "Fold All" },
  // { "key": "meta+k meta+bracketright", "command": "Unfold All" },
  // { "key": "meta+k meta+0", "command": "Fold All Subregions" },
  // { "key": "meta+k meta+j", "command": "Unfold All Subregions" },
  // { "key": "meta+k meta+c", "command": "Add Line Comment" },
  // { "key": "meta+k meta+u", "command": "Remove Line Comment" },
  { key: 'meta+slash', command: 'Toggle Line Comment' },
  { key: 'shift+alt+a', command: 'Toggle Block Comment' },
  { key: 'alt+z', command: 'Toggle Word Wrap' },
  // { "key": "alt++click", "command": "Add Cursor" },
  { key: 'alt+meta+up', command: 'Add Cursor Above' },
  { key: 'alt+meta+down', command: 'Add Cursor Below' },
  { key: 'meta+u', command: 'Undo Cursor Position' },
  { key: 'shift+alt+i', command: 'Add Cursors to Line Ends' },
  { key: 'meta+l', command: 'Select Current Line' },
  { key: 'shift+meta+l', command: 'Select All Occurrences' },
  { key: 'meta+f2', command: 'Select All Occurrences' },
  { key: 'ctrl+shift+meta+right', command: 'Expand Selection' },
  { key: 'ctrl+shift+meta+left', command: 'Shrink Selection' },
  // { "key": "shift+alt++drag", "command": "Column Selection" },
  { key: 'meta+f', command: 'Find' },
  { key: 'alt+meta+f', command: 'Replace' },
  { key: 'meta+g', command: 'Find Next' },
  { key: 'shift+meta+g', command: 'Find Previous' },
  { key: 'alt+enter', command: 'Select All Matches' },
  { key: 'meta+d', command: 'Add Selection to Next Find Match' },
  // { "key": "meta+k meta+d", "command": "Move Last Selection to Next Find Match" },
  { key: 'ctrl+space', command: 'Trigger Suggestion' },
  { key: 'meta+i', command: 'Trigger Suggestion' },
  { key: 'shift+meta+space', command: 'Trigger Parameter Hints' },
  { key: 'shift+alt+f', command: 'Format Document' },
  // { "key": "meta+k meta+f", "command": "Format Selection" },
  { key: 'f12', command: 'Go to Definition' },
  { key: 'alt+f12', command: 'Peek Definition' },
  // { "key": "meta+k f12", "command": "Open Definition to the Side" },
  { key: 'meta+period', command: 'Quick Fix' },
  { key: 'shift+f12', command: 'Show References' },
  { key: 'f2', command: 'Rename Symbol' },
  // { "key": "meta+k meta+x", "command": "Trim Trailing Whitespace" },
  // { "key": "meta+k m", "command": "Change Language Mode" },
  { key: 'meta+t', command: 'Show All Symbols' },
  { key: 'ctrl+g', command: 'Go to Line' },
  { key: 'shift+meta+o', command: 'Go to Symbol' },
  { key: 'shift+meta+m', command: 'Show Problems' },
  { key: 'f8', command: 'Go to Next Problem' },
  { key: 'shift+f8', command: 'Go to Previous Problem' },
  { key: 'ctrl+shift+tab', command: 'Navigate Editor Groups' },
  { key: 'ctrl+minus', command: 'Navigate Back' },
  { key: 'ctrl+shift+minus', command: 'Navigate Forward' },
  // { "key": "meta+w", "command": "Close Editor" },
  // { "key": "meta+k f", "command": "Close Folder" },
  { key: 'meta+backslash', command: 'Split Editor' },
  { key: 'meta+1', command: 'Focus First Editor Group' },
  { key: 'meta+2', command: 'Focus Second Editor Group' },
  { key: 'meta+3', command: 'Focus Third Editor Group' },
  // { "key": "meta+n", "command": "New File" },
  { key: 'meta+s', command: 'Save' },
  // { "key": "meta+w", "command": "Close" },
  // { "key": "meta+k w", "command": "Close All" },
  { key: 'shift+meta+t', command: 'Reopen Closed Editor' },
  // { "key": "ctrl+tab", "command": "Open Next Recently Used Editor" },
  // { "key": "meta+k r", "command": "Reveal in Finder" },
  // { "key": "meta+k o", "command": "Open in New Window" },
  { key: 'ctrl+meta+f', command: 'Toggle Full Screen' },
  { key: 'meta+equal', command: 'Zoom In' },
  { key: 'meta+b', command: 'Toggle Sidebar' },
  { key: 'shift+meta+e', command: 'Show Explorer' },
  { key: 'shift+meta+f', command: 'Show Search' },
  { key: 'ctrl+shift+g', command: 'Show Source Control' },
  { key: 'shift+meta+d', command: 'Show Debug' },
  { key: 'shift+meta+x', command: 'Show Extensions' },
  { key: 'shift+meta+h', command: 'Replace in Files' },
  // { "key": "ctrl++backquote", "command": "Toggle Terminal" },
  { key: 'f9', command: 'Toggle Breakpoint' },
  { key: 'f5', command: 'Start/Continue Debugging' },
  { key: 'f11', command: 'Step Into' },
  { key: 'shift+f11', command: 'Step Out' },
  { key: 'meta+c', command: 'Copy' },
];

export const macOsShortcut = [
  { key: 'meta+x', command: 'cut' },
  { key: 'meta+c', command: 'copy' },
  { key: 'meta+v', command: 'paste' },
  { key: 'meta+z', command: 'undo' },
  { key: 'shift+meta+z', command: 'redo' },
  { key: 'meta+a', command: 'select all' },
  { key: 'meta+f', command: 'find' },
  { key: 'meta+g', command: 'find next' },
  { key: 'shift+meta+g', command: 'find previous' },
  { key: 'meta+h', command: 'hide app' },
  // { "key": "option+meta+h", "command": "hide others" },
  { key: 'meta+m', command: 'minimize window' },
  // { "key": "option+meta+m", "command": "minimize all windows" },
  { key: 'meta+o', command: 'open file' },
  { key: 'meta+p', command: 'print' },
  { key: 'meta+s', command: 'save' },
  { key: 'meta+t', command: 'new tab' },
  { key: 'meta+w', command: 'close window' },
  { key: 'option+meta+w', command: 'close all windows' },
  { key: 'option+meta+esc', command: 'force quit' },
  { key: 'meta+space', command: 'spotlight search' },
  { key: 'option+meta+space', command: 'spotlight search in Finder' },
  { key: 'control+meta+space', command: 'show emoji & symbols' },
  { key: 'control+meta+f', command: 'enter full screen' },
  { key: 'space', command: 'quick look' },
  { key: 'meta+tab', command: 'switch between apps' },
  { key: 'meta+backquote', command: 'switch between windows' },
  { key: 'shift+meta+5', command: 'screenshot menu' },
  { key: 'shift+meta+3', command: 'screenshot full screen' },
  { key: 'shift+meta+4', command: 'screenshot selection' },
  { key: 'shift+meta+n', command: 'new folder in Finder' },
  { key: 'meta+,', command: 'open preferences' },
  { key: 'meta+d', command: 'duplicate selected files' },
  { key: 'meta+e', command: 'eject disk' },
  { key: 'meta+i', command: 'get info' },
  { key: 'meta+r', command: 'refresh or show original' },
  { key: 'shift+meta+c', command: 'open Computer' },
  { key: 'shift+meta+d', command: 'open Desktop folder' },
  { key: 'shift+meta+f', command: 'open Recents' },
  { key: 'shift+meta+g', command: 'go to folder' },
  { key: 'shift+meta+h', command: 'open Home folder' },
  { key: 'shift+meta+i', command: 'open iCloud Drive' },
  { key: 'shift+meta+k', command: 'open Network' },
  // { "key": "option+meta+l", "command": "open Downloads" },
  { key: 'shift+meta+n', command: 'create new folder' },
  { key: 'shift+meta+o', command: 'open Documents folder' },
  { key: 'shift+meta+p', command: 'toggle preview in Finder' },
  { key: 'shift+meta+r', command: 'open AirDrop' },
  { key: 'shift+meta+t', command: 'toggle tabs in Finder' },
  { key: 'control+shift+meta+t', command: 'add to Dock' },
  { key: 'shift+meta+u', command: 'open Utilities folder' },
  // { "key": "option+meta+d", "command": "toggle Dock" },
  { key: 'control+meta+t', command: 'add to Finder sidebar' },
  // { "key": "option+meta+p", "command": "toggle path bar in Finder" },
  // { "key": "option+meta+s", "command": "toggle Finder sidebar" },
  { key: 'meta+slash', command: 'toggle Finder status bar' },
  { key: 'meta+j', command: 'show view options' },
  { key: 'meta+k', command: 'connect to server' },
  { key: 'control+meta+a', command: 'create alias' },
  { key: 'meta+n', command: 'new Finder window' },
  // { "key": "option+meta+n", "command": "create new smart folder" },
  { key: 'meta+t', command: 'toggle tab bar in Finder' },
  // { "key": "option+meta+t", "command": "toggle toolbar in Finder" },
  // { "key": "option+meta+v", "command": "move clipboard files" },
  { key: 'meta+y', command: 'quick look files' },
  // { "key": "option+meta+y", "command": "quick look slideshow" },
  { key: 'meta+1', command: 'view as icons in Finder' },
  { key: 'meta+2', command: 'view as list in Finder' },
  { key: 'meta+3', command: 'view as columns in Finder' },
  { key: 'meta+4', command: 'view as gallery in Finder' },
  { key: 'meta+bracketleft', command: 'go back in Finder' },
  { key: 'meta+bracketright', command: 'go forward in Finder' },
  { key: 'meta+up', command: 'open enclosing folder' },
  { key: 'meta+down', command: 'open selected item' },
  { key: 'meta+delete', command: 'move to trash' },
  { key: 'shift+meta+delete', command: 'empty trash' },
  // { "key": "option+shift+meta+delete", "command": "empty trash without confirmation" },
  // { "key": "meta+power", "command": "sleep/wake" },
  // { "key": "option+meta+power", "command": "sleep Mac" },
  { key: 'control+shift+power', command: 'sleep display' },
  { key: 'control+meta+power', command: 'shutdown dialog' },
  { key: 'control+meta+q', command: 'lock screen' },
  { key: 'shift+meta+q', command: 'log out' },
];

export const cursorShortcut = [
  {
    key: 'escape escape',
    command: 'workbench.action.exitZenMode',
    when: 'inZenMode',
  },
  {
    key: 'shift+escape',
    command: 'closeReferenceSearch',
    when: 'inReferenceSearchEditor && !config.editor.stablePeek',
  },
  {
    key: 'escape',
    command: 'closeReferenceSearch',
    when: 'inReferenceSearchEditor && !config.editor.stablePeek',
  },
  {
    key: 'escape',
    command: 'editor.closeTestPeek',
    when: 'testing.isInPeek && !config.editor.stablePeek || testing.isPeekVisible && !config.editor.stablePeek',
  },
  {
    key: 'shift+cmd+j',
    command: 'aiSettings.action.openhidden',
    when: '!isSettingsPaneOpen',
  },
  {
    key: 'shift+escape',
    command: 'cancelSelection',
    when: 'editorHasSelection && textInputFocus',
  },
  {
    key: 'escape',
    command: 'cancelSelection',
    when: 'editorHasSelection && textInputFocus',
  },
  { key: 'cmd+down', command: 'cursorBottom', when: 'textInputFocus' },
  {
    key: 'shift+cmd+down',
    command: 'cursorBottomSelect',
    when: 'textInputFocus',
  },
  {
    key: 'shift+alt+cmd+down',
    command: 'cursorColumnSelectDown',
    when: 'textInputFocus',
  },
  {
    key: 'shift+alt+cmd+left',
    command: 'cursorColumnSelectLeft',
    when: 'textInputFocus',
  },
  {
    key: 'shift+alt+cmd+pagedown',
    command: 'cursorColumnSelectPageDown',
    when: 'textInputFocus',
  },
  {
    key: 'shift+alt+cmd+pageup',
    command: 'cursorColumnSelectPageUp',
    when: 'textInputFocus',
  },
  {
    key: 'shift+alt+cmd+right',
    command: 'cursorColumnSelectRight',
    when: 'textInputFocus',
  },
  {
    key: 'shift+alt+cmd+up',
    command: 'cursorColumnSelectUp',
    when: 'textInputFocus',
  },
  { key: 'ctrl+n', command: 'cursorDown', when: 'textInputFocus' },
  { key: 'down', command: 'cursorDown', when: 'textInputFocus' },
  { key: 'shift+down', command: 'cursorDownSelect', when: 'textInputFocus' },
  {
    key: 'cmd+right',
    command: 'cursorEnd',
    when: 'textInputFocus',
    args: { sticky: false },
  },
  {
    key: 'end',
    command: 'cursorEnd',
    when: 'textInputFocus',
    args: { sticky: false },
  },
  {
    key: 'shift+cmd+right',
    command: 'cursorEndSelect',
    when: 'textInputFocus',
    args: { sticky: false },
  },
  {
    key: 'shift+end',
    command: 'cursorEndSelect',
    when: 'textInputFocus',
    args: { sticky: false },
  },
  { key: 'cmd+left', command: 'cursorHome', when: 'textInputFocus' },
  { key: 'home', command: 'cursorHome', when: 'textInputFocus' },
  {
    key: 'shift+cmd+left',
    command: 'cursorHomeSelect',
    when: 'textInputFocus',
  },
  { key: 'shift+home', command: 'cursorHomeSelect', when: 'textInputFocus' },
  { key: 'ctrl+b', command: 'cursorLeft', when: 'textInputFocus' },
  { key: 'left', command: 'cursorLeft', when: 'textInputFocus' },
  { key: 'shift+left', command: 'cursorLeftSelect', when: 'textInputFocus' },
  { key: 'ctrl+e', command: 'cursorLineEnd', when: 'textInputFocus' },
  {
    key: 'ctrl+shift+e',
    command: 'cursorLineEndSelect',
    when: 'textInputFocus',
  },
  { key: 'ctrl+a', command: 'cursorLineStart', when: 'textInputFocus' },
  {
    key: 'ctrl+shift+a',
    command: 'cursorLineStartSelect',
    when: 'textInputFocus',
  },
  { key: 'pagedown', command: 'cursorPageDown', when: 'textInputFocus' },
  {
    key: 'shift+pagedown',
    command: 'cursorPageDownSelect',
    when: 'textInputFocus',
  },
  { key: 'pageup', command: 'cursorPageUp', when: 'textInputFocus' },
  {
    key: 'shift+pageup',
    command: 'cursorPageUpSelect',
    when: 'textInputFocus',
  },
  { key: 'ctrl+f', command: 'cursorRight', when: 'textInputFocus' },
  { key: 'right', command: 'cursorRight', when: 'textInputFocus' },
  { key: 'shift+right', command: 'cursorRightSelect', when: 'textInputFocus' },
  { key: 'cmd+up', command: 'cursorTop', when: 'textInputFocus' },
  { key: 'shift+cmd+up', command: 'cursorTopSelect', when: 'textInputFocus' },
  { key: 'ctrl+p', command: 'cursorUp', when: 'textInputFocus' },
  { key: 'up', command: 'cursorUp', when: 'textInputFocus' },
  { key: 'shift+up', command: 'cursorUpSelect', when: 'textInputFocus' },
  { key: 'ctrl+backspace', command: 'deleteLeft', when: 'textInputFocus' },
  { key: 'ctrl+h', command: 'deleteLeft', when: 'textInputFocus' },
  { key: 'shift+backspace', command: 'deleteLeft', when: 'textInputFocus' },
  { key: 'backspace', command: 'deleteLeft', when: 'textInputFocus' },
  { key: 'ctrl+delete', command: 'deleteRight', when: 'textInputFocus' },
  { key: 'ctrl+d', command: 'deleteRight', when: 'textInputFocus' },
  { key: 'delete', command: 'deleteRight', when: 'textInputFocus' },
  { key: 'cmd+a', command: 'editor.action.selectAll' },
  { key: 'cmd+c', command: 'execCopy' },
  { key: 'cmd+x', command: 'execCut' },
  { key: 'cmd+v', command: 'execPaste' },
  { key: 'cmd+p', command: 'expandLineSelection', when: 'textInputFocus' },
  {
    key: 'cmd+down',
    command: 'inlineChat.arrowOutDown',
    when: 'inlineChatFocused && inlineChatHasProvider && inlineChatInnerCursorLast && !accessibilityModeEnabled && !isEmbeddedDiffEditor',
  },
  {
    key: 'cmd+up',
    command: 'inlineChat.arrowOutUp',
    when: 'inlineChatFocused && inlineChatHasProvider && inlineChatInnerCursorFirst && !accessibilityModeEnabled && !isEmbeddedDiffEditor',
  },
  {
    key: 'ctrl+o',
    command: 'lineBreakInsert',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'shift+tab',
    command: 'outdent',
    when: 'editorTextFocus && !editorReadonly && !editorTabMovesFocus',
  },
  { key: 'shift+cmd+z', command: 'redo' },
  { key: 'ctrl+pagedown', command: 'scrollLineDown', when: 'textInputFocus' },
  { key: 'ctrl+pageup', command: 'scrollLineUp', when: 'textInputFocus' },
  { key: 'cmd+pagedown', command: 'scrollPageDown', when: 'textInputFocus' },
  { key: 'cmd+pageup', command: 'scrollPageUp', when: 'textInputFocus' },
  {
    key: 'tab',
    command: 'tab',
    when: 'editorTextFocus && !editorReadonly && !editorTabMovesFocus',
  },
  { key: 'cmd+z', command: 'undo' },
  {
    key: 'shift+down',
    command: 'cursorColumnSelectDown',
    when: 'editorColumnSelection && textInputFocus',
  },
  {
    key: 'shift+left',
    command: 'cursorColumnSelectLeft',
    when: 'editorColumnSelection && textInputFocus',
  },
  {
    key: 'shift+pagedown',
    command: 'cursorColumnSelectPageDown',
    when: 'editorColumnSelection && textInputFocus',
  },
  {
    key: 'shift+pageup',
    command: 'cursorColumnSelectPageUp',
    when: 'editorColumnSelection && textInputFocus',
  },
  {
    key: 'shift+right',
    command: 'cursorColumnSelectRight',
    when: 'editorColumnSelection && textInputFocus',
  },
  {
    key: 'shift+up',
    command: 'cursorColumnSelectUp',
    when: 'editorColumnSelection && textInputFocus',
  },
  {
    key: 'shift+escape',
    command: 'removeSecondaryCursors',
    when: 'editorHasMultipleSelections && textInputFocus',
  },
  {
    key: 'escape',
    command: 'removeSecondaryCursors',
    when: 'editorHasMultipleSelections && textInputFocus',
  },
  {
    key: 'cmd+down',
    command: 'notebook.cell.chat.arrowOutDown',
    when: 'inlineChatFocused && inlineChatInnerCursorLast && notebookCellChatFocused && !accessibilityModeEnabled && !notebookCellEditorFocused',
  },
  {
    key: 'cmd+up',
    command: 'notebook.cell.chat.arrowOutUp',
    when: 'inlineChatFocused && inlineChatInnerCursorFirst && notebookCellChatFocused && !accessibilityModeEnabled && !notebookCellEditorFocused',
  },
  {
    key: 'cmd+up',
    command: 'notebook.cell.focusChatWidget',
    when: "editorTextFocus && inputFocus && notebookEditorFocused && !accessibilityModeEnabled && !isEmbeddedDiffEditor && notebookEditorCursorAtBoundary != 'bottom' && notebookEditorCursorAtBoundary != 'none'",
  },
  {
    key: 'cmd+down',
    command: 'notebook.cell.focusNextChatWidget',
    when: "editorTextFocus && inputFocus && notebookEditorFocused && !accessibilityModeEnabled && !isEmbeddedDiffEditor && notebookEditorCursorAtBoundary != 'none' && notebookEditorCursorAtBoundary != 'top'",
  },
  {
    key: 'cmd+down',
    command: 'inlineChat.focus',
    when: "editorTextFocus && inlineChatVisible && !accessibilityModeEnabled && !inlineChatFocused && !isEmbeddedDiffEditor && inlineChatOuterCursorPosition == 'above'",
  },
  {
    key: 'cmd+up',
    command: 'inlineChat.focus',
    when: "editorTextFocus && inlineChatVisible && !accessibilityModeEnabled && !inlineChatFocused && !isEmbeddedDiffEditor && inlineChatOuterCursorPosition == 'below'",
  },
  {
    key: 'escape',
    command: 'notebook.cell.chat.acceptChanges',
    when: 'inlineChatFocused && notebookCellChatFocused && notebookChatUserDidEdit && !notebookCellEditorFocused',
  },
  {
    key: 'down',
    command: 'notebook.cell.chat.nextFromHistory',
    when: 'inlineChatFocused && notebookCellChatFocused',
  },
  {
    key: 'up',
    command: 'notebook.cell.chat.previousFromHistory',
    when: 'inlineChatFocused && notebookCellChatFocused',
  },
  {
    key: 'f12',
    command: 'goToNextReference',
    when: 'inReferenceSearchEditor || referenceSearchVisible',
  },
  {
    key: 'f4',
    command: 'goToNextReference',
    when: 'inReferenceSearchEditor || referenceSearchVisible',
  },
  {
    key: 'shift+f12',
    command: 'goToPreviousReference',
    when: 'inReferenceSearchEditor || referenceSearchVisible',
  },
  {
    key: 'shift+f4',
    command: 'goToPreviousReference',
    when: 'inReferenceSearchEditor || referenceSearchVisible',
  },
  {
    key: 'cmd+enter',
    command: 'refactorPreview.apply',
    when: "refactorPreview.enabled && refactorPreview.hasCheckedChanges && focusedView == 'refactorPreview'",
  },
  {
    key: 'alt+enter',
    command: 'testing.editFocusedTest',
    when: "focusedView == 'workbench.view.testing'",
  },
  {
    key: 'escape',
    command: 'notebook.cell.quitEdit',
    when: 'inputFocus && notebookEditorFocused && !editorHasMultipleSelections && !editorHasSelection && !editorHoverVisible && !inlineChatFocused',
  },
  {
    key: 'ctrl+enter',
    command: 'notebook.cell.quitEdit',
    when: "inputFocus && notebookEditorFocused && !inlineChatFocused && notebookCellType == 'markup'",
  },
  {
    key: 'escape',
    command: 'inlineChat.discard',
    when: 'inlineChatHasProvider && inlineChatVisible && !inlineChatUserDidEdit',
  },
  {
    key: 'cmd+f',
    command: 'actions.find',
    when: 'editorFocus || editorIsOpen',
  },
  { key: 'cmd+e', command: 'actions.findWithSelection' },
  {
    key: 'enter',
    command: 'breakpointWidget.action.acceptInput',
    when: 'breakpointWidgetVisible && inBreakpointWidget',
  },
  {
    key: 'cmd+up',
    command: 'chat.action.focus',
    when: "chatCursorAtTop && inChatInput && chatLocation == 'panel'",
  },
  {
    key: 'cmd+up',
    command: 'chat.action.focus',
    when: "inChatInput && isLinux && chatLocation == 'panel' || inChatInput && isWindows && chatLocation == 'panel'",
  },
  {
    key: 'shift+escape',
    command: 'closeBreakpointWidget',
    when: 'breakpointWidgetVisible && textInputFocus',
  },
  {
    key: 'escape',
    command: 'closeBreakpointWidget',
    when: 'breakpointWidgetVisible && textInputFocus',
  },
  { key: 'cmd+u', command: 'cursorUndo', when: 'textInputFocus' },
  { key: 'alt+right', command: 'cursorWordEndRight', when: 'textInputFocus' },
  {
    key: 'shift+alt+right',
    command: 'cursorWordEndRightSelect',
    when: 'textInputFocus',
  },
  { key: 'alt+left', command: 'cursorWordLeft', when: 'textInputFocus' },
  {
    key: 'shift+alt+left',
    command: 'cursorWordLeftSelect',
    when: 'textInputFocus',
  },
  {
    key: 'ctrl+alt+left',
    command: 'cursorWordPartLeft',
    when: 'textInputFocus',
  },
  {
    key: 'ctrl+shift+alt+left',
    command: 'cursorWordPartLeftSelect',
    when: 'textInputFocus',
  },
  {
    key: 'ctrl+alt+right',
    command: 'cursorWordPartRight',
    when: 'textInputFocus',
  },
  {
    key: 'ctrl+shift+alt+right',
    command: 'cursorWordPartRightSelect',
    when: 'textInputFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'deleteAllLeft',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'cmd+delete',
    command: 'deleteAllRight',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'ctrl+k',
    command: 'deleteAllRight',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'alt+backspace',
    command: 'deleteWordLeft',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'ctrl+alt+backspace',
    command: 'deleteWordPartLeft',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'ctrl+alt+delete',
    command: 'deleteWordPartRight',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'alt+delete',
    command: 'deleteWordRight',
    when: 'textInputFocus && !editorReadonly',
  },
  { key: 'tab', command: 'editor.action.acceptGPT4', when: 'hasGPT4Diff' },
  {
    key: 'f7',
    command: 'editor.action.accessibleDiffViewer.next',
    when: 'isInDiffEditor',
  },
  {
    key: 'shift+f7',
    command: 'editor.action.accessibleDiffViewer.prev',
    when: 'isInDiffEditor',
  },
  {
    key: 'cmd+r cmd+c',
    command: 'editor.action.addCommentLine',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'cmd+d',
    command: 'editor.action.addSelectionToNextFindMatch',
    when: 'editorFocus',
  },
  {
    key: 'alt+cmd+.',
    command: 'editor.action.autoFix',
    when: 'textInputFocus && !editorReadonly && supportedCodeAction =~ /(\\s|^)quickfix\\b/',
  },
  {
    key: 'shift+alt+a',
    command: 'editor.action.blockComment',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'escape',
    command: 'editor.action.cancelSelectionAnchor',
    when: 'editorTextFocus && selectionAnchorSet',
  },
  {
    key: 'cmd+f2',
    command: 'editor.action.changeAll',
    when: 'editorTextFocus && !editorReadonly',
  },
  { key: 'cmd+c', command: 'editor.action.clipboardCopyAction' },
  { key: 'cmd+x', command: 'editor.action.clipboardCutAction' },
  { key: 'cmd+v', command: 'editor.action.clipboardPasteAction' },
  {
    key: 'cmd+/',
    command: 'editor.action.commentLine',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'shift+alt+down',
    command: 'editor.action.copyLinesDownAction',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'shift+alt+up',
    command: 'editor.action.copyLinesUpAction',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'shift+cmd+k',
    command: 'editor.action.deleteLines',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'alt+f3',
    command: 'editor.action.dirtydiff.next',
    when: 'editorTextFocus && !textCompareEditorActive',
  },
  {
    key: 'shift+alt+f3',
    command: 'editor.action.dirtydiff.previous',
    when: 'editorTextFocus && !textCompareEditorActive',
  },
  {
    key: 'enter',
    command: 'editor.action.extensioneditor.findNext',
    when: "webviewFindWidgetFocused && !editorFocus && activeEditor == 'workbench.editor.extension'",
  },
  {
    key: 'shift+enter',
    command: 'editor.action.extensioneditor.findPrevious',
    when: "webviewFindWidgetFocused && !editorFocus && activeEditor == 'workbench.editor.extension'",
  },
  {
    key: 'cmd+f',
    command: 'editor.action.extensioneditor.showfind',
    when: "!editorFocus && activeEditor == 'workbench.editor.extension'",
  },
  {
    key: 'shift+alt+f',
    command: 'editor.action.formatDocument',
    when: 'editorHasDocumentFormattingProvider && editorTextFocus && !editorReadonly && !inCompositeEditor',
  },
  {
    key: 'shift+alt+f',
    command: 'editor.action.formatDocument.none',
    when: 'editorTextFocus && !editorHasDocumentFormattingProvider && !editorReadonly',
  },
  {
    key: 'cmd+r cmd+f',
    command: 'editor.action.formatSelection',
    when: 'editorHasDocumentSelectionFormattingProvider && editorTextFocus && !editorReadonly',
  },
  {
    key: 'cmd+down',
    command: 'editor.action.goToBottomHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'end',
    command: 'editor.action.goToBottomHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'enter',
    command: 'editor.action.goToFocusedStickyScrollLine',
    when: 'stickyScrollFocused',
  },
  {
    key: 'cmd+f12',
    command: 'editor.action.goToImplementation',
    when: 'editorHasImplementationProvider && editorTextFocus',
  },
  {
    key: 'shift+f12',
    command: 'editor.action.goToReferences',
    when: 'editorHasReferenceProvider && editorTextFocus && !inReferenceSearchEditor && !isInEmbeddedEditor',
  },
  {
    key: 'cmd+up',
    command: 'editor.action.goToTopHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'home',
    command: 'editor.action.goToTopHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'escape',
    command: 'editor.action.hideColorPicker',
    when: 'standaloneColorPickerVisible',
  },
  {
    key: 'shift+cmd+.',
    command: 'editor.action.inPlaceReplace.down',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'shift+cmd+,',
    command: 'editor.action.inPlaceReplace.up',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'cmd+]',
    command: 'editor.action.indentLines',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'escape',
    command: 'editor.action.inlineEdit.reject',
    when: 'inlineEditVisible && !editorReadonly',
  },
  {
    key: 'escape',
    command: 'editor.action.inlineEdits.hide',
    when: 'inlineEditsVisible',
  },
  {
    key: 'alt+]',
    command: 'editor.action.inlineEdits.showNext',
    when: 'inlineEditsVisible && !editorReadonly',
  },
  {
    key: 'alt+[',
    command: 'editor.action.inlineEdits.showPrevious',
    when: 'inlineEditsVisible && !editorReadonly',
  },
  {
    key: 'escape',
    command: 'editor.action.inlineSuggest.hide',
    when: 'inlineSuggestionVisible',
  },
  {
    key: 'alt+]',
    command: 'editor.action.inlineSuggest.showNext',
    when: 'inlineSuggestionVisible && !editorReadonly',
  },
  {
    key: 'alt+[',
    command: 'editor.action.inlineSuggest.showPrevious',
    when: 'inlineSuggestionVisible && !editorReadonly',
  },
  {
    key: 'enter',
    command: 'editor.action.insertColorWithStandaloneColorPicker',
    when: 'standaloneColorPickerFocused',
  },
  {
    key: 'alt+cmd+up',
    command: 'editor.action.insertCursorAbove',
    when: 'editorTextFocus',
  },
  {
    key: 'shift+alt+i',
    command: 'editor.action.insertCursorAtEndOfEachLineSelected',
    when: 'editorTextFocus',
  },
  {
    key: 'alt+cmd+down',
    command: 'editor.action.insertCursorBelow',
    when: 'editorTextFocus',
  },
  {
    key: 'cmd+enter',
    command: 'editor.action.insertLineAfter',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'shift+cmd+enter',
    command: 'editor.action.insertLineBefore',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'ctrl+j',
    command: 'editor.action.joinLines',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'shift+cmd+\\',
    command: 'editor.action.jumpToBracket',
    when: 'editorTextFocus',
  },
  {
    key: 'shift+cmd+f2',
    command: 'editor.action.linkedEditing',
    when: 'editorHasRenameProvider && editorTextFocus && !editorReadonly',
  },
  { key: 'alt+f8', command: 'editor.action.marker.next', when: 'editorFocus' },
  {
    key: 'f8',
    command: 'editor.action.marker.nextInFiles',
    when: 'editorFocus',
  },
  {
    key: 'shift+alt+f8',
    command: 'editor.action.marker.prev',
    when: 'editorFocus',
  },
  {
    key: 'shift+f8',
    command: 'editor.action.marker.prevInFiles',
    when: 'editorFocus',
  },
  {
    key: 'alt+cmd+f8',
    command: 'editor.action.marker.this',
    when: 'editorFocus',
  },
  {
    key: 'alt+down',
    command: 'editor.action.moveLinesDownAction',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'alt+up',
    command: 'editor.action.moveLinesUpAction',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'cmd+r cmd+d',
    command: 'editor.action.moveSelectionToNextFindMatch',
    when: 'editorFocus',
  },
  { key: 'alt+f9', command: 'editor.action.nextCommentThreadAction' },
  {
    key: 'cmd+r alt+cmd+down',
    command: 'editor.action.nextCommentingRange',
    when: "accessibilityModeEnabled && commentFocused || accessibilityModeEnabled && editorFocus || accessibilityHelpIsShown && accessibilityModeEnabled && accessibleViewCurrentProviderId == 'comments'",
  },
  {
    key: 'f3',
    command: 'editor.action.nextMatchFindAction',
    when: 'editorFocus',
  },
  {
    key: 'cmd+g',
    command: 'editor.action.nextMatchFindAction',
    when: 'editorFocus',
  },
  {
    key: 'enter',
    command: 'editor.action.nextMatchFindAction',
    when: 'editorFocus && findInputFocussed',
  },
  {
    key: 'cmd+f3',
    command: 'editor.action.nextSelectionMatchFindAction',
    when: 'editorFocus',
  },
  {
    key: 'shift+alt+o',
    command: 'editor.action.organizeImports',
    when: 'textInputFocus && !editorReadonly && supportedCodeAction =~ /(\\s|^)source\\.organizeImports\\b/',
  },
  {
    key: 'cmd+[',
    command: 'editor.action.outdentLines',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'alt+down',
    command: 'editor.action.pageDownHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'pagedown',
    command: 'editor.action.pageDownHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'alt+up',
    command: 'editor.action.pageUpHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'pageup',
    command: 'editor.action.pageUpHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'alt+f12',
    command: 'editor.action.peekDefinition',
    when: 'editorHasDefinitionProvider && editorTextFocus && !inReferenceSearchEditor && !isInEmbeddedEditor',
  },
  {
    key: 'shift+cmd+f12',
    command: 'editor.action.peekImplementation',
    when: 'editorHasImplementationProvider && editorTextFocus && !inReferenceSearchEditor && !isInEmbeddedEditor',
  },
  { key: 'shift+alt+f9', command: 'editor.action.previousCommentThreadAction' },
  {
    key: 'cmd+r alt+cmd+up',
    command: 'editor.action.previousCommentingRange',
    when: "accessibilityModeEnabled && commentFocused || accessibilityModeEnabled && editorFocus || accessibilityHelpIsShown && accessibilityModeEnabled && accessibleViewCurrentProviderId == 'comments'",
  },
  {
    key: 'shift+f3',
    command: 'editor.action.previousMatchFindAction',
    when: 'editorFocus',
  },
  {
    key: 'shift+cmd+g',
    command: 'editor.action.previousMatchFindAction',
    when: 'editorFocus',
  },
  {
    key: 'shift+enter',
    command: 'editor.action.previousMatchFindAction',
    when: 'editorFocus && findInputFocussed',
  },
  {
    key: 'shift+cmd+f3',
    command: 'editor.action.previousSelectionMatchFindAction',
    when: 'editorFocus',
  },
  {
    key: 'cmd+.',
    command: 'editor.action.quickFix',
    when: 'editorHasCodeActionsProvider && textInputFocus && !editorReadonly',
  },
  {
    key: 'ctrl+shift+r',
    command: 'editor.action.refactor',
    when: 'editorHasCodeActionsProvider && textInputFocus && !editorReadonly',
  },
  {
    key: 'alt+cmd+backspace',
    command: 'editor.action.removeBrackets',
    when: 'editorTextFocus',
  },
  {
    key: 'cmd+r cmd+u',
    command: 'editor.action.removeCommentLine',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'f2',
    command: 'editor.action.rename',
    when: 'editorHasRenameProvider && editorTextFocus && !editorReadonly',
  },
  {
    key: 'f12',
    command: 'editor.action.revealDefinition',
    when: 'editorHasDefinitionProvider && editorTextFocus',
  },
  {
    key: 'cmd+f12',
    command: 'editor.action.revealDefinition',
    when: 'editorHasDefinitionProvider && editorTextFocus && isWeb',
  },
  {
    key: 'cmd+r f12',
    command: 'editor.action.revealDefinitionAside',
    when: 'editorHasDefinitionProvider && editorTextFocus && !isInEmbeddedEditor',
  },
  {
    key: 'cmd+r cmd+f12',
    command: 'editor.action.revealDefinitionAside',
    when: 'editorHasDefinitionProvider && editorTextFocus && isWeb && !isInEmbeddedEditor',
  },
  {
    key: 'down',
    command: 'editor.action.scrollDownHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'left',
    command: 'editor.action.scrollLeftHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'right',
    command: 'editor.action.scrollRightHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'up',
    command: 'editor.action.scrollUpHover',
    when: 'editorHoverFocused',
  },
  {
    key: 'escape',
    command: 'editor.action.selectEditor',
    when: 'stickyScrollFocused',
  },
  {
    key: 'cmd+r cmd+k',
    command: 'editor.action.selectFromAnchorToCursor',
    when: 'editorTextFocus && selectionAnchorSet',
  },
  {
    key: 'shift+cmd+l',
    command: 'editor.action.selectHighlights',
    when: 'editorFocus',
  },
  {
    key: 'down',
    command: 'editor.action.selectNextStickyScrollLine',
    when: 'stickyScrollFocused',
  },
  {
    key: 'up',
    command: 'editor.action.selectPreviousStickyScrollLine',
    when: 'stickyScrollFocused',
  },
  {
    key: 'cmd+r cmd+b',
    command: 'editor.action.setSelectionAnchor',
    when: 'editorTextFocus',
  },
  {
    key: 'shift+f10',
    command: 'editor.action.showContextMenu',
    when: 'textInputFocus',
  },
  {
    key: 'cmd+r cmd+i',
    command: 'editor.action.showHover',
    when: 'editorTextFocus',
  },
  {
    key: 'ctrl+shift+right',
    command: 'editor.action.smartSelect.expand',
    when: 'editorTextFocus',
  },
  {
    key: 'ctrl+shift+cmd+right',
    command: 'editor.action.smartSelect.expand',
    when: 'editorTextFocus',
  },
  {
    key: 'ctrl+shift+left',
    command: 'editor.action.smartSelect.shrink',
    when: 'editorTextFocus',
  },
  {
    key: 'ctrl+shift+cmd+left',
    command: 'editor.action.smartSelect.shrink',
    when: 'editorTextFocus',
  },
  {
    key: 'alt+cmd+f',
    command: 'editor.action.startFindReplaceAction',
    when: 'editorFocus || editorIsOpen',
  },
  {
    key: 'cmd+enter',
    command: 'editor.action.submitComment',
    when: 'commentEditorFocused',
  },
  { key: 'ctrl+shift+m', command: 'editor.action.toggleTabFocusMode' },
  { key: 'alt+z', command: 'editor.action.toggleWordWrap' },
  {
    key: 'ctrl+t',
    command: 'editor.action.transposeLetters',
    when: 'textInputFocus && !editorReadonly',
  },
  {
    key: 'shift+cmd+space',
    command: 'editor.action.triggerParameterHints',
    when: 'editorHasSignatureHelpProvider && editorTextFocus',
  },
  {
    key: 'cmd+i',
    command: 'editor.action.triggerSuggest',
    when: 'editorHasCompletionItemProvider && textInputFocus && !editorReadonly && !suggestWidgetVisible',
  },
  {
    key: 'alt+escape',
    command: 'editor.action.triggerSuggest',
    when: 'editorHasCompletionItemProvider && textInputFocus && !editorReadonly && !suggestWidgetVisible',
  },
  {
    key: 'ctrl+space',
    command: 'editor.action.triggerSuggest',
    when: 'editorHasCompletionItemProvider && textInputFocus && !editorReadonly && !suggestWidgetVisible',
  },
  {
    key: 'cmd+r cmd+x',
    command: 'editor.action.trimTrailingWhitespace',
    when: 'editorTextFocus && !editorReadonly',
  },
  {
    key: 'enter',
    command: 'editor.action.webvieweditor.findNext',
    when: "webviewFindWidgetFocused && !editorFocus && activeEditor == 'WebviewEditor'",
  },
  {
    key: 'shift+enter',
    command: 'editor.action.webvieweditor.findPrevious',
    when: "webviewFindWidgetFocused && !editorFocus && activeEditor == 'WebviewEditor'",
  },
  {
    key: 'escape',
    command: 'editor.action.webvieweditor.hideFind',
    when: "webviewFindWidgetVisible && !editorFocus && activeEditor == 'WebviewEditor'",
  },
  {
    key: 'cmd+f',
    command: 'editor.action.webvieweditor.showFind',
    when: "webviewFindWidgetEnabled && !editorFocus && activeEditor == 'WebviewEditor'",
  },
  {
    key: 'f7',
    command: 'editor.action.wordHighlight.next',
    when: 'editorTextFocus && hasWordHighlights',
  },
  {
    key: 'shift+f7',
    command: 'editor.action.wordHighlight.prev',
    when: 'editorTextFocus && hasWordHighlights',
  },
  {
    key: 'escape',
    command: 'editor.cancelOperation',
    when: 'cancellableOperation',
  },
  { key: 'cmd+.', command: 'editor.changeDropType', when: 'dropWidgetVisible' },
  {
    key: 'cmd+.',
    command: 'editor.changePasteType',
    when: 'pasteWidgetVisible',
  },
  {
    key: 'cmd+r cmd+,',
    command: 'editor.createFoldingRangeFromSelection',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'escape',
    command: 'editor.debug.action.closeExceptionWidget',
    when: 'exceptionWidgetVisible',
  },
  {
    key: 'cmd+r cmd+i',
    command: 'editor.debug.action.showDebugHover',
    when: 'editorTextFocus && inDebugMode',
  },
  {
    key: 'f9',
    command: 'editor.debug.action.toggleBreakpoint',
    when: 'debuggersAvailable && disassemblyViewFocus || debuggersAvailable && editorTextFocus',
  },
  {
    key: 'tab',
    command: 'editor.emmet.action.expandAbbreviation',
    when: 'config.emmet.triggerExpansionOnTab && editorTextFocus && !editorReadonly && !editorTabMovesFocus',
  },
  {
    key: 'alt+cmd+[',
    command: 'editor.fold',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+0',
    command: 'editor.foldAll',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+/',
    command: 'editor.foldAllBlockComments',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+-',
    command: 'editor.foldAllExcept',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+8',
    command: 'editor.foldAllMarkerRegions',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+1',
    command: 'editor.foldLevel1',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+2',
    command: 'editor.foldLevel2',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+3',
    command: 'editor.foldLevel3',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+4',
    command: 'editor.foldLevel4',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+5',
    command: 'editor.foldLevel5',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+6',
    command: 'editor.foldLevel6',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+7',
    command: 'editor.foldLevel7',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+[',
    command: 'editor.foldRecursively',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'f12',
    command: 'editor.gotoNextSymbolFromResult',
    when: 'hasSymbols',
  },
  {
    key: 'escape',
    command: 'editor.gotoNextSymbolFromResult.cancel',
    when: 'hasSymbols',
  },
  {
    key: 'escape',
    command: 'editor.hideDropWidget',
    when: 'dropWidgetVisible',
  },
  {
    key: 'escape',
    command: 'editor.hidePasteWidget',
    when: 'pasteWidgetVisible',
  },
  {
    key: 'cmd+r cmd+.',
    command: 'editor.removeManualFoldingRanges',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+l',
    command: 'editor.toggleFold',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r shift+cmd+l',
    command: 'editor.toggleFoldRecursively',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'alt+cmd+]',
    command: 'editor.unfold',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+j',
    command: 'editor.unfoldAll',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+=',
    command: 'editor.unfoldAllExcept',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+9',
    command: 'editor.unfoldAllMarkerRegions',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'cmd+r cmd+]',
    command: 'editor.unfoldRecursively',
    when: 'editorTextFocus && foldingEnabled',
  },
  {
    key: 'escape',
    command: 'inlayHints.stopReadingLineWithHint',
    when: 'isReadingLineWithInlayHints',
  },
  {
    key: 'tab',
    command: 'insertSnippet',
    when: 'editorTextFocus && hasSnippetCompletions && !editorTabMovesFocus && !inSnippetMode',
  },
  {
    key: 'cmd+enter',
    command: 'interactive.execute',
    when: "activeEditor == 'workbench.editor.interactive'",
  },
  {
    key: 'shift+enter',
    command: 'interactive.execute',
    when: "config.interactiveWindow.executeWithShiftEnter && activeEditor == 'workbench.editor.interactive'",
  },
  {
    key: 'enter',
    command: 'interactive.execute',
    when: "!config.interactiveWindow.executeWithShiftEnter && activeEditor == 'workbench.editor.interactive'",
  },
  {
    key: 'escape',
    command: 'notebook.cell.chat.discard',
    when: 'inlineChatFocused && notebookCellChatFocused && !notebookCellEditorFocused && !notebookChatUserDidEdit',
  },
  {
    key: 'pagedown',
    command: 'notebook.cell.cursorPageDown',
    when: 'editorTextFocus && inputFocus && notebookEditorFocused',
  },
  {
    key: 'shift+pagedown',
    command: 'notebook.cell.cursorPageDownSelect',
    when: 'editorTextFocus && inputFocus && notebookEditorFocused && !notebookOutputFocused',
  },
  {
    key: 'pageup',
    command: 'notebook.cell.cursorPageUp',
    when: 'editorTextFocus && inputFocus && notebookEditorFocused',
  },
  {
    key: 'shift+pageup',
    command: 'notebook.cell.cursorPageUpSelect',
    when: 'editorTextFocus && inputFocus && notebookEditorFocused && !notebookOutputFocused',
  },
  {
    key: 'ctrl+enter',
    command: 'notebook.cell.execute',
    when: "notebookCellListFocused && notebookMissingKernelExtension && !notebookCellExecuting && notebookCellType == 'code' || !notebookCellExecuting && notebookCellType == 'code' && notebookCellListFocused || inlineChatFocused && notebookCellChatFocused && notebookKernelCount > 0 || !notebookCellExecuting && notebookCellType == 'code' && notebookCellListFocused || inlineChatFocused && notebookCellChatFocused && notebookKernelSourceCount > 0 || inlineChatFocused && notebookCellChatFocused && notebookMissingKernelExtension && !notebookCellExecuting && notebookCellType == 'code'",
  },
  {
    key: 'alt+enter',
    command: 'notebook.cell.executeAndInsertBelow',
    when: "notebookCellListFocused && notebookCellType == 'markup' || notebookCellListFocused && notebookMissingKernelExtension && !notebookCellExecuting && notebookCellType == 'code' || notebookCellListFocused && !notebookCellExecuting && notebookCellType == 'code' && notebookKernelCount > 0 || notebookCellListFocused && !notebookCellExecuting && notebookCellType == 'code' && notebookKernelSourceCount > 0",
  },
  {
    key: 'shift+enter',
    command: 'notebook.cell.executeAndSelectBelow',
    when: "notebookCellListFocused && !inlineChatFocused && notebookCellType == 'markup' || notebookCellListFocused && notebookMissingKernelExtension && !inlineChatFocused && !notebookCellExecuting && notebookCellType == 'code' || notebookCellListFocused && !inlineChatFocused && !notebookCellExecuting && notebookCellType == 'code' && notebookKernelCount > 0 || notebookCellListFocused && !inlineChatFocused && !notebookCellExecuting && notebookCellType == 'code' && notebookKernelSourceCount > 0",
  },
  {
    key: 'shift+cmd+v',
    command: 'notebook.cell.pasteAbove',
    when: 'notebookEditorFocused && !inputFocus',
  },
  {
    key: 'down',
    command: 'notebook.focusNextEditor',
    when: "config.notebook.navigation.allowNavigateToSurroundingCells && editorTextFocus && inputFocus && notebookEditorFocused && !accessibilityModeEnabled && !isEmbeddedDiffEditor && notebookEditorCursorAtBoundary != 'none' && notebookEditorCursorAtBoundary != 'top'",
  },
  {
    key: 'up',
    command: 'notebook.focusPreviousEditor',
    when: "config.notebook.navigation.allowNavigateToSurroundingCells && editorTextFocus && inputFocus && notebookEditorFocused && !accessibilityModeEnabled && !isEmbeddedDiffEditor && notebookEditorCursorAtBoundary != 'bottom' && notebookEditorCursorAtBoundary != 'none'",
  },
  {
    key: 'shift+alt+f',
    command: 'notebook.formatCell',
    when: "editorHasDocumentFormattingProvider && editorTextFocus && inCompositeEditor && notebookEditable && !editorReadonly && activeEditor == 'workbench.editor.notebook'",
  },
  {
    key: 'ctrl+enter',
    command: 'openReferenceToSide',
    when: 'listFocus && referenceSearchVisible && !inputFocus && !treeElementCanCollapse && !treeElementCanExpand && !treestickyScrollFocused',
  },
  {
    key: 'enter',
    command: 'repl.action.acceptInput',
    when: 'inDebugRepl && textInputFocus',
  },
  {
    key: 'cmd+f',
    command: 'repl.action.filter',
    when: 'inDebugRepl && textInputFocus',
  },
  {
    key: 'shift+cmd+r',
    command: 'rerunSearchEditorSearch',
    when: 'inSearchEditor',
  },
  {
    key: 'escape',
    command: 'search.action.focusQueryEditorWidget',
    when: 'inSearchEditor',
  },
  {
    key: 'shift+cmd+backspace',
    command: 'search.searchEditor.action.deleteFileResults',
    when: 'inSearchEditor',
  },
  {
    key: 'escape',
    command: 'settings.action.clearSearchResults',
    when: 'inSettingsEditor && inSettingsSearch',
  },
  {
    key: 'down',
    command: 'settings.action.focusSettingsFile',
    when: 'inSettingsSearch && !suggestWidgetVisible',
  },
  { key: 'cmd+f', command: 'settings.action.search', when: 'inSettingsEditor' },
  { key: 'cmd+/', command: 'toggleExplainMode', when: 'suggestWidgetVisible' },
  {
    key: 'cmd+r f2',
    command: 'togglePeekWidgetFocus',
    when: 'inReferenceSearchEditor || referenceSearchVisible',
  },
  { key: 'cmd+r alt+cmd+c', command: 'workbench.action.addComment' },
  {
    key: 'cmd+/',
    command: 'workbench.action.chat.attachContext',
    when: 'inChatInput',
  },
  {
    key: 'ctrl+alt+enter',
    command: 'workbench.action.chat.runInTerminal',
    when: 'accessibleViewInCodeBlock && chatIsEnabled || chatIsEnabled && inChat',
  },
  {
    key: 'enter',
    command: 'workbench.action.chat.submit',
    when: 'chatInputHasText && inChatInput && !chatSessionRequestInProgress',
  },
  {
    key: 'cmd+enter',
    command: 'workbench.action.chat.submitSecondaryAgent',
    when: 'chatInputHasText && inChatInput && !chatInputHasAgent && !chatSessionRequestInProgress',
  },
  {
    key: 'alt+f5',
    command: 'workbench.action.editor.nextChange',
    when: 'editorTextFocus && !textCompareEditorActive',
  },
  {
    key: 'shift+alt+f5',
    command: 'workbench.action.editor.previousChange',
    when: 'editorTextFocus && !textCompareEditorActive',
  },
  {
    key: 'shift+escape',
    command: 'workbench.action.hideComment',
    when: 'commentEditorFocused',
  },
  {
    key: 'escape',
    command: 'workbench.action.hideComment',
    when: 'commentEditorFocused',
  },
  {
    key: 'tab',
    command: 'editor.action.inlineEdit.accept',
    when: 'cursorAtInlineEdit && inlineEditVisible && !editorReadonly',
  },
  {
    key: 'alt+cmd+=',
    command: 'editor.action.inlineEdit.jumpTo',
    when: 'inlineEditVisible && !cursorAtInlineEdit && !editorReadonly',
  },
  {
    key: 'alt+cmd+=',
    command: 'editor.action.inlineEdit.trigger',
    when: '!editorReadonly && !inlineEditVisible',
  },
  {
    key: 'cmd+right',
    command: 'editor.action.inlineSuggest.acceptNextWord',
    when: 'cppSuggestion && !editorReadonly || inlineSuggestionVisible && !editorReadonly',
  },
  {
    key: 'alt+f8',
    command: 'testing.goToNextMessage',
    when: 'editorFocus && testing.isPeekVisible',
  },
  {
    key: 'shift+alt+f8',
    command: 'testing.goToPreviousMessage',
    when: 'editorFocus && testing.isPeekVisible',
  },
  {
    key: 'shift+escape',
    command: 'closeFindWidget',
    when: 'editorFocus && findWidgetVisible && !isComposing',
  },
  {
    key: 'escape',
    command: 'closeFindWidget',
    when: 'editorFocus && findWidgetVisible && !isComposing',
  },
  {
    key: 'alt+cmd+enter',
    command: 'editor.action.replaceAll',
    when: 'editorFocus && findWidgetVisible',
  },
  {
    key: 'cmd+enter',
    command: 'editor.action.replaceAll',
    when: 'editorFocus && findWidgetVisible && replaceInputFocussed',
  },
  {
    key: 'shift+cmd+1',
    command: 'editor.action.replaceOne',
    when: 'editorFocus && findWidgetVisible',
  },
  {
    key: 'enter',
    command: 'editor.action.replaceOne',
    when: 'editorFocus && findWidgetVisible && replaceInputFocussed',
  },
  {
    key: 'alt+enter',
    command: 'editor.action.selectAllMatches',
    when: 'editorFocus && findWidgetVisible',
  },
  { key: 'alt+cmd+c', command: 'toggleFindCaseSensitive', when: 'editorFocus' },
  { key: 'alt+cmd+l', command: 'toggleFindInSelection', when: 'editorFocus' },
  { key: 'alt+cmd+r', command: 'toggleFindRegex', when: 'editorFocus' },
  { key: 'alt+cmd+w', command: 'toggleFindWholeWord', when: 'editorFocus' },
  { key: 'alt+cmd+p', command: 'togglePreserveCase', when: 'editorFocus' },
  {
    key: 'alt+cmd+=',
    command: 'editor.action.inlineEdit.jumpBack',
    when: 'cursorAtInlineEdit && !editorReadonly',
  },
  {
    key: 'cmd+enter',
    command: 'notebook.cell.chat.acceptChanges',
    when: 'inlineChatFocused && notebookCellChatFocused && !notebookCellEditorFocused',
  },
  {
    key: 'tab',
    command: 'jumpToNextSnippetPlaceholder',
    when: 'hasNextTabstop && inSnippetMode && textInputFocus',
  },
  {
    key: 'shift+tab',
    command: 'jumpToPrevSnippetPlaceholder',
    when: 'hasPrevTabstop && inSnippetMode && textInputFocus',
  },
  { key: 'escape', command: 'leaveEditorMessage', when: 'messageVisible' },
  {
    key: 'shift+escape',
    command: 'leaveSnippet',
    when: 'inSnippetMode && textInputFocus',
  },
  {
    key: 'escape',
    command: 'leaveSnippet',
    when: 'inSnippetMode && textInputFocus',
  },
  { key: 'shift+escape', command: 'closeDirtyDiff', when: 'dirtyDiffVisible' },
  { key: 'escape', command: 'closeDirtyDiff', when: 'dirtyDiffVisible' },
  {
    key: 'shift+escape',
    command: 'closeMarkersNavigation',
    when: 'editorFocus && markersNavigationVisible',
  },
  {
    key: 'escape',
    command: 'closeMarkersNavigation',
    when: 'editorFocus && markersNavigationVisible',
  },
  {
    key: 'escape',
    command: 'notifications.hideToasts',
    when: 'notificationToastsVisible',
  },
  {
    key: 'shift+escape',
    command: 'closeParameterHints',
    when: 'editorFocus && parameterHintsVisible',
  },
  {
    key: 'escape',
    command: 'closeParameterHints',
    when: 'editorFocus && parameterHintsVisible',
  },
  {
    key: 'ctrl+n',
    command: 'showNextParameterHint',
    when: 'editorFocus && parameterHintsMultipleSignatures && parameterHintsVisible',
  },
  {
    key: 'alt+down',
    command: 'showNextParameterHint',
    when: 'editorFocus && parameterHintsMultipleSignatures && parameterHintsVisible',
  },
  {
    key: 'down',
    command: 'showNextParameterHint',
    when: 'editorFocus && parameterHintsMultipleSignatures && parameterHintsVisible',
  },
  {
    key: 'ctrl+p',
    command: 'showPrevParameterHint',
    when: 'editorFocus && parameterHintsMultipleSignatures && parameterHintsVisible',
  },
  {
    key: 'alt+up',
    command: 'showPrevParameterHint',
    when: 'editorFocus && parameterHintsMultipleSignatures && parameterHintsVisible',
  },
  {
    key: 'up',
    command: 'showPrevParameterHint',
    when: 'editorFocus && parameterHintsMultipleSignatures && parameterHintsVisible',
  },
  {
    key: 'shift+tab',
    command: 'acceptAlternativeSelectedSuggestion',
    when: 'suggestWidgetHasFocusedSuggestion && suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'shift+enter',
    command: 'acceptAlternativeSelectedSuggestion',
    when: 'suggestWidgetHasFocusedSuggestion && suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'tab',
    command: 'acceptSelectedSuggestion',
    when: 'suggestWidgetHasFocusedSuggestion && suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'enter',
    command: 'acceptSelectedSuggestion',
    when: 'acceptSuggestionOnEnter && suggestWidgetHasFocusedSuggestion && suggestWidgetVisible && suggestionMakesTextEdit && textInputFocus',
  },
  {
    key: 'cmd+i',
    command: 'focusSuggestion',
    when: 'suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'ctrl+space',
    command: 'focusSuggestion',
    when: 'suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'shift+escape',
    command: 'hideSuggestWidget',
    when: 'suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'escape',
    command: 'hideSuggestWidget',
    when: 'suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'tab',
    command: 'insertBestCompletion',
    when: "atEndOfWord && textInputFocus && !hasOtherSuggestions && !inSnippetMode && !suggestWidgetVisible && config.editor.tabCompletion == 'on'",
  },
  {
    key: 'tab',
    command: 'insertNextSuggestion',
    when: "hasOtherSuggestions && textInputFocus && !inSnippetMode && !suggestWidgetVisible && config.editor.tabCompletion == 'on'",
  },
  {
    key: 'shift+tab',
    command: 'insertPrevSuggestion',
    when: "hasOtherSuggestions && textInputFocus && !inSnippetMode && !suggestWidgetVisible && config.editor.tabCompletion == 'on'",
  },
  {
    key: 'cmd+pagedown',
    command: 'selectNextPageSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'pagedown',
    command: 'selectNextPageSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'ctrl+n',
    command: 'selectNextSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'cmd+down',
    command: 'selectNextSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'down',
    command: 'selectNextSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'cmd+pageup',
    command: 'selectPrevPageSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'pageup',
    command: 'selectPrevPageSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'ctrl+p',
    command: 'selectPrevSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'cmd+up',
    command: 'selectPrevSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'up',
    command: 'selectPrevSuggestion',
    when: 'suggestWidgetMultipleSuggestions && suggestWidgetVisible && textInputFocus || suggestWidgetVisible && textInputFocus && !suggestWidgetHasFocusedSuggestion',
  },
  {
    key: 'cmd+i',
    command: 'toggleSuggestionDetails',
    when: 'suggestWidgetHasFocusedSuggestion && suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'ctrl+space',
    command: 'toggleSuggestionDetails',
    when: 'suggestWidgetHasFocusedSuggestion && suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'ctrl+alt+space',
    command: 'toggleSuggestionFocus',
    when: 'suggestWidgetVisible && textInputFocus',
  },
  {
    key: 'enter',
    command: 'acceptRenameInput',
    when: 'editorFocus && renameInputVisible && !isComposing',
  },
  {
    key: 'cmd+enter',
    command: 'acceptRenameInputWithPreview',
    when: 'config.editor.rename.enablePreview && editorFocus && renameInputVisible && !isComposing',
  },
  {
    key: 'shift+escape',
    command: 'cancelLinkedEditingInput',
    when: 'LinkedEditingInputVisible && editorTextFocus',
  },
  {
    key: 'escape',
    command: 'cancelLinkedEditingInput',
    when: 'LinkedEditingInputVisible && editorTextFocus',
  },
  {
    key: 'shift+escape',
    command: 'cancelRenameInput',
    when: 'editorFocus && renameInputVisible',
  },
  {
    key: 'escape',
    command: 'cancelRenameInput',
    when: 'editorFocus && renameInputVisible',
  },
  {
    key: 'down',
    command: 'focusNextRenameSuggestion',
    when: 'renameInputVisible',
  },
  {
    key: 'up',
    command: 'focusPreviousRenameSuggestion',
    when: 'renameInputVisible',
  },
  {
    key: 'shift+cmd+l',
    command: 'addCursorsAtSearchResults',
    when: 'fileMatchOrMatchFocus && searchViewletVisible',
  },
  { key: 'cmd+r cmd+g', command: 'aiFeedback.action.open' },
  {
    key: 'shift+cmd+;',
    command: 'breadcrumbs.focus',
    when: 'breadcrumbsPossible && breadcrumbsVisible',
  },
  {
    key: 'shift+cmd+.',
    command: 'breadcrumbs.focusAndSelect',
    when: 'breadcrumbsPossible && breadcrumbsVisible',
  },
  {
    key: 'alt+right',
    command: 'breadcrumbs.focusNext',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'right',
    command: 'breadcrumbs.focusNext',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'alt+left',
    command: 'breadcrumbs.focusPrevious',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'left',
    command: 'breadcrumbs.focusPrevious',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'cmd+enter',
    command: 'breadcrumbs.revealFocused',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'space',
    command: 'breadcrumbs.revealFocused',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'cmd+enter',
    command: 'breadcrumbs.revealFocusedFromTreeAside',
    when: 'breadcrumbsActive && breadcrumbsVisible && listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'down',
    command: 'breadcrumbs.selectFocused',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'enter',
    command: 'breadcrumbs.selectFocused',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'shift+cmd+.',
    command: 'breadcrumbs.toggleToOn',
    when: '!config.breadcrumbs.enabled',
  },
  {
    key: 'escape',
    command: 'closeReplaceInFilesWidget',
    when: 'replaceInputBoxFocus && searchViewletVisible',
  },
  {
    key: 'escape',
    command: 'commentsClearFilterText',
    when: 'commentsFilterFocus',
  },
  {
    key: 'cmd+f',
    command: 'commentsFocusFilter',
    when: "focusedView == 'workbench.panel.comments'",
  },
  {
    key: 'cmd+down',
    command: 'commentsFocusViewFromFilter',
    when: 'commentsFilterFocus',
  },
  { key: 'alt+cmd+c', command: 'copyFilePath', when: '!editorFocus' },
  { key: 'cmd+r alt+cmd+c', command: 'copyFilePath', when: 'editorFocus' },
  {
    key: 'shift+alt+cmd+c',
    command: 'copyRelativeFilePath',
    when: '!editorFocus',
  },
  {
    key: 'cmd+r shift+alt+cmd+c',
    command: 'copyRelativeFilePath',
    when: 'editorFocus',
  },
  {
    key: 'alt+enter',
    command: 'debug.openBreakpointToSide',
    when: 'breakpointsFocused',
  },
  {
    key: 'cmd+enter',
    command: 'debug.openBreakpointToSide',
    when: 'breakpointsFocused',
  },
  { key: 'cmd+f5', command: 'debug.openView', when: '!debuggersAvailable' },
  { key: 'f5', command: 'debug.openView', when: '!debuggersAvailable' },
  {
    key: 'cmd+backspace',
    command: 'debug.removeBreakpoint',
    when: 'breakpointsFocused && !breakpointInputFocused',
  },
  {
    key: 'cmd+backspace',
    command: 'debug.removeWatchExpression',
    when: 'watchExpressionsFocused && !expressionSelected',
  },
  {
    key: 'alt+-',
    command: 'decreaseSearchEditorContextLines',
    when: 'inSearchEditor',
  },
  {
    key: 'alt+f1',
    command: 'editor.action.accessibilityHelp',
    when: '!accessibilityHelpIsShown',
  },
  {
    key: 'alt+k',
    command: 'editor.action.accessibilityHelpConfigureKeybindings',
    when: 'accessibilityHelpIsShown',
  },
  {
    key: 'alt+h',
    command: 'editor.action.accessibilityHelpOpenHelpLink',
    when: 'accessibilityHelpIsShown',
  },
  { key: 'alt+f2', command: 'editor.action.accessibleView' },
  {
    key: 'ctrl+/',
    command: 'editor.action.accessibleViewAcceptInlineCompletion',
    when: "accessibleViewIsShown && accessibleViewCurrentProviderId == 'inlineCompletions'",
  },
  {
    key: 'alt+f6',
    command: 'editor.action.accessibleViewDisableHint',
    when: 'accessibilityHelpIsShown && accessibleViewVerbosityEnabled || accessibleViewIsShown && accessibleViewVerbosityEnabled',
  },
  {
    key: 'alt+]',
    command: 'editor.action.accessibleViewNext',
    when: 'accessibleViewIsShown && accessibleViewSupportsNavigation',
  },
  {
    key: 'alt+cmd+pagedown',
    command: 'editor.action.accessibleViewNextCodeBlock',
    when: "accessibleViewContainsCodeBlocks && accessibleViewCurrentProviderId == 'panelChat'",
  },
  {
    key: 'alt+[',
    command: 'editor.action.accessibleViewPrevious',
    when: 'accessibleViewIsShown && accessibleViewSupportsNavigation',
  },
  {
    key: 'alt+cmd+pageup',
    command: 'editor.action.accessibleViewPreviousCodeBlock',
    when: "accessibleViewContainsCodeBlocks && accessibleViewCurrentProviderId == 'panelChat'",
  },
  {
    key: 'cmd+r cmd+k',
    command: 'editor.action.defineKeybinding',
    when: "resource == 'vscode-userdata:/Users/ianleblanc/Library/Application%20Support/Cursor/User/keybindings.json'",
  },
  {
    key: 'tab',
    command: 'editor.action.inlineSuggest.commit',
    when: 'inlineSuggestionHasIndentationLessThanTabSize && inlineSuggestionVisible && !editorHoverFocused && !editorTabMovesFocus && !suggestWidgetVisible',
  },
  {
    key: 'shift+f9',
    command: 'editor.debug.action.toggleInlineBreakpoint',
    when: 'editorTextFocus',
  },
  {
    key: 'shift+alt+d',
    command: 'editor.detectLanguage',
    when: 'editorTextFocus && !notebookEditable',
  },
  {
    key: 'shift+enter',
    command: 'editor.refocusCallHierarchy',
    when: 'callHierarchyVisible',
  },
  {
    key: 'shift+enter',
    command: 'editor.refocusTypeHierarchy',
    when: 'typeHierarchyVisible',
  },
  {
    key: 'shift+alt+h',
    command: 'editor.showCallHierarchy',
    when: 'editorHasCallHierarchyProvider && editorTextFocus && !inReferenceSearchEditor',
  },
  {
    key: 'shift+alt+h',
    command: 'editor.showIncomingCalls',
    when: "callHierarchyVisible && callHierarchyDirection == 'outgoingCalls'",
  },
  {
    key: 'shift+alt+h',
    command: 'editor.showOutgoingCalls',
    when: "callHierarchyVisible && callHierarchyDirection == 'incomingCalls'",
  },
  {
    key: 'shift+alt+h',
    command: 'editor.showSubtypes',
    when: "typeHierarchyVisible && typeHierarchyDirection == 'supertypes'",
  },
  {
    key: 'shift+alt+h',
    command: 'editor.showSupertypes',
    when: "typeHierarchyVisible && typeHierarchyDirection == 'subtypes'",
  },
  {
    key: 'ctrl+enter',
    command: 'explorer.openToSide',
    when: 'explorerViewletFocus && foldersViewVisible && !inputFocus',
  },
  {
    key: 'shift+alt+f',
    command: 'filesExplorer.findInFolder',
    when: 'explorerResourceIsFolder && filesExplorerFocus && foldersViewVisible && !inputFocus',
  },
  {
    key: 'alt+down',
    command: 'history.showNext',
    when: 'historyNavigationForwardsEnabled && historyNavigationWidgetFocus && !isComposing && !suggestWidgetVisible',
  },
  {
    key: 'down',
    command: 'history.showNext',
    when: 'historyNavigationForwardsEnabled && historyNavigationWidgetFocus && !isComposing && !suggestWidgetVisible',
  },
  {
    key: 'alt+up',
    command: 'history.showPrevious',
    when: 'historyNavigationBackwardsEnabled && historyNavigationWidgetFocus && !isComposing && !suggestWidgetVisible',
  },
  {
    key: 'up',
    command: 'history.showPrevious',
    when: 'historyNavigationBackwardsEnabled && historyNavigationWidgetFocus && !isComposing && !suggestWidgetVisible',
  },
  {
    key: 'down',
    command: 'iconSelectBox.focusDown',
    when: 'iconSelectBoxFocus',
  },
  {
    key: 'right',
    command: 'iconSelectBox.focusNext',
    when: 'iconSelectBoxFocus && iconSelectBoxInputEmpty || iconSelectBoxFocus && !iconSelectBoxInputFocus',
  },
  {
    key: 'left',
    command: 'iconSelectBox.focusPrevious',
    when: 'iconSelectBoxFocus && iconSelectBoxInputEmpty || iconSelectBoxFocus && !iconSelectBoxInputFocus',
  },
  { key: 'up', command: 'iconSelectBox.focusUp', when: 'iconSelectBoxFocus' },
  {
    key: 'enter',
    command: 'iconSelectBox.selectFocused',
    when: 'iconSelectBoxFocus',
  },
  {
    key: 'alt+=',
    command: 'increaseSearchEditorContextLines',
    when: 'inSearchEditor',
  },
  {
    key: 'escape',
    command: 'inlineChat.close',
    when: 'inlineChatHasProvider && inlineChatVisible',
  },
  {
    key: 'escape',
    command: 'inlineChat.discardHunkChange',
    when: "inlineChatHasProvider && inlineChatVisible && inlineChatResponseType == 'messagesAndEdits'",
  },
  {
    key: 'cmd+i',
    command: 'inlineChat.holdForSpeech',
    when: 'hasSpeechProvider && inlineChatHasProvider && inlineChatVisible && textInputFocus',
  },
  {
    key: 'f7',
    command: 'inlineChat.moveToNextHunk',
    when: 'inlineChatHasProvider && inlineChatVisible',
  },
  {
    key: 'shift+f7',
    command: 'inlineChat.moveToPreviousHunk',
    when: 'inlineChatHasProvider && inlineChatVisible',
  },
  {
    key: 'cmd+r',
    command: 'inlineChat.regenerate',
    when: 'inlineChatHasProvider && inlineChatVisible',
  },
  {
    key: 'cmd+r i',
    command: 'inlineChat.start',
    when: 'editorFocus && inlineChatHasProvider && !editorReadonly',
  },
  {
    key: 'cmd+i',
    command: 'inlineChat.start',
    when: 'editorFocus && inlineChatHasProvider && !editorReadonly',
  },
  {
    key: 'cmd+z',
    command: 'inlineChat.unstash',
    when: 'inlineChatHasStashedSession && !editorReadonly',
  },
  {
    key: 'cmd+down',
    command: 'inlineChat.viewInChat',
    when: 'inlineChatHasProvider && inlineChatVisible',
  },
  {
    key: 'down',
    command: 'interactive.history.next',
    when: "!suggestWidgetVisible && activeEditor == 'workbench.editor.interactive' && interactiveInputCursorAtBoundary != 'none' && interactiveInputCursorAtBoundary != 'top'",
  },
  {
    key: 'down',
    command: 'interactive.history.next',
    when: "!suggestWidgetVisible && activeEditor == 'workbench.editor.repl' && interactiveInputCursorAtBoundary != 'none' && interactiveInputCursorAtBoundary != 'top'",
  },
  {
    key: 'up',
    command: 'interactive.history.previous',
    when: "!suggestWidgetVisible && activeEditor == 'workbench.editor.interactive' && interactiveInputCursorAtBoundary != 'bottom' && interactiveInputCursorAtBoundary != 'none'",
  },
  {
    key: 'up',
    command: 'interactive.history.previous',
    when: "!suggestWidgetVisible && activeEditor == 'workbench.editor.repl' && interactiveInputCursorAtBoundary != 'bottom' && interactiveInputCursorAtBoundary != 'none'",
  },
  {
    key: 'cmd+down',
    command: 'interactive.scrollToBottom',
    when: "activeEditor == 'workbench.editor.interactive'",
  },
  {
    key: 'cmd+up',
    command: 'interactive.scrollToTop',
    when: "activeEditor == 'workbench.editor.interactive'",
  },
  {
    key: 'enter',
    command: 'keybindings.editor.acceptWhenExpression',
    when: 'inKeybindings && whenFocus && !suggestWidgetVisible',
  },
  {
    key: 'cmd+r cmd+a',
    command: 'keybindings.editor.addKeybinding',
    when: 'inKeybindings && keybindingFocus',
  },
  {
    key: 'escape',
    command: 'keybindings.editor.clearSearchResults',
    when: 'inKeybindings && inKeybindingsSearch',
  },
  {
    key: 'cmd+c',
    command: 'keybindings.editor.copyKeybindingEntry',
    when: 'inKeybindings && keybindingFocus && !whenFocus',
  },
  {
    key: 'enter',
    command: 'keybindings.editor.defineKeybinding',
    when: 'inKeybindings && keybindingFocus && !whenFocus',
  },
  {
    key: 'cmd+r cmd+e',
    command: 'keybindings.editor.defineWhenExpression',
    when: 'inKeybindings && keybindingFocus',
  },
  {
    key: 'cmd+down',
    command: 'keybindings.editor.focusKeybindings',
    when: 'inKeybindings && inKeybindingsSearch',
  },
  {
    key: 'alt+cmd+k',
    command: 'keybindings.editor.recordSearchKeys',
    when: 'inKeybindings && inKeybindingsSearch',
  },
  {
    key: 'escape',
    command: 'keybindings.editor.rejectWhenExpression',
    when: 'inKeybindings && whenFocus && !suggestWidgetVisible',
  },
  {
    key: 'cmd+backspace',
    command: 'keybindings.editor.removeKeybinding',
    when: 'inKeybindings && keybindingFocus && !inputFocus',
  },
  {
    key: 'cmd+f',
    command: 'keybindings.editor.searchKeybindings',
    when: 'inKeybindings',
  },
  {
    key: 'alt+cmd+p',
    command: 'keybindings.editor.toggleSortByPrecedence',
    when: 'inKeybindings',
  },
  {
    key: 'escape',
    command: 'list.clear',
    when: 'listFocus && listHasSelectionOrFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'escape',
    command: 'list.closeFind',
    when: 'listFocus && treeFindOpen',
  },
  {
    key: 'cmd+up',
    command: 'list.collapse',
    when: 'listFocus && treeElementCanCollapse && !inputFocus && !treestickyScrollFocused || listFocus && treeElementHasParent && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'left',
    command: 'list.collapse',
    when: 'listFocus && treeElementCanCollapse && !inputFocus && !treestickyScrollFocused || listFocus && treeElementHasParent && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'shift+cmd+up',
    command: 'list.collapseAll',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'cmd+left',
    command: 'list.collapseAll',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'right',
    command: 'list.expand',
    when: 'listFocus && treeElementCanExpand && !inputFocus && !treestickyScrollFocused || listFocus && treeElementHasChild && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'shift+down',
    command: 'list.expandSelectionDown',
    when: 'listFocus && listSupportsMultiselect && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'shift+up',
    command: 'list.expandSelectionUp',
    when: 'listFocus && listSupportsMultiselect && !inputFocus && !treestickyScrollFocused',
  },
  { key: 'f3', command: 'list.find', when: 'listFocus && listSupportsFind' },
  {
    key: 'alt+cmd+f',
    command: 'list.find',
    when: 'listFocus && listSupportsFind',
  },
  {
    key: 'ctrl+alt+n',
    command: 'list.focusAnyDown',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'alt+down',
    command: 'list.focusAnyDown',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'alt+home',
    command: 'list.focusAnyFirst',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'alt+end',
    command: 'list.focusAnyLast',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'ctrl+alt+p',
    command: 'list.focusAnyUp',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'alt+up',
    command: 'list.focusAnyUp',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'ctrl+n',
    command: 'list.focusDown',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'down',
    command: 'list.focusDown',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'home',
    command: 'list.focusFirst',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'end',
    command: 'list.focusLast',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'pagedown',
    command: 'list.focusPageDown',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'pageup',
    command: 'list.focusPageUp',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'ctrl+p',
    command: 'list.focusUp',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'up',
    command: 'list.focusUp',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'cmd+down',
    command: 'list.scrollDown',
    when: "listFocus && !inputFocus && !treestickyScrollFocused && listScrollAtBoundary != 'both' && listScrollAtBoundary != 'bottom'",
  },
  {
    key: 'cmd+up',
    command: 'list.scrollUp',
    when: "listFocus && !inputFocus && !treestickyScrollFocused && listScrollAtBoundary != 'both' && listScrollAtBoundary != 'top'",
  },
  {
    key: 'cmd+down',
    command: 'list.select',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'enter',
    command: 'list.select',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'cmd+a',
    command: 'list.selectAll',
    when: 'listFocus && listSupportsMultiselect && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'cmd+r cmd+i',
    command: 'list.showHover',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'space',
    command: 'list.toggleExpand',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'shift+cmd+enter',
    command: 'list.toggleSelection',
    when: 'listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'y',
    command: 'notebook.cell.changeToCode',
    when: "notebookEditorFocused && !inputFocus && !notebookOutputFocused && activeEditor == 'workbench.editor.notebook' && notebookCellType == 'markup'",
  },
  {
    key: 'm',
    command: 'notebook.cell.changeToMarkdown',
    when: "notebookEditorFocused && !inputFocus && !notebookOutputFocused && activeEditor == 'workbench.editor.notebook' && notebookCellType == 'code'",
  },
  {
    key: 'enter',
    command: 'notebook.cell.chat.accept',
    when: 'inlineChatFocused && notebookCellChatFocused && !notebookCellEditorFocused',
  },
  {
    key: 'cmd+enter',
    command: 'notebook.cell.chat.acceptChanges',
    when: "notebookEditorFocused && !inputFocus && !notebookCellEditorFocused && notebookChatOuterFocusPosition == 'below'",
  },
  {
    key: 'cmd+down',
    command: 'notebook.cell.chat.focus',
    when: "notebookEditorFocused && !inputFocus && notebookChatOuterFocusPosition == 'above'",
  },
  {
    key: 'cmd+up',
    command: 'notebook.cell.chat.focus',
    when: "notebookEditorFocused && !inputFocus && notebookChatOuterFocusPosition == 'below'",
  },
  {
    key: 'cmd+down',
    command: 'notebook.cell.chat.focusNextCell',
    when: 'inlineChatFocused && notebookCellChatFocused',
  },
  {
    key: 'cmd+up',
    command: 'notebook.cell.chat.focusPreviousCell',
    when: 'inlineChatFocused && notebookCellChatFocused',
  },
  {
    key: 'cmd+r i',
    command: 'notebook.cell.chat.start',
    when: 'config.notebook.experimental.cellChat && notebookChatAgentRegistered && notebookEditable && notebookEditorFocused && !inputFocus || config.notebook.experimental.generate && notebookChatAgentRegistered && notebookEditable && notebookEditorFocused && !inputFocus',
  },
  {
    key: 'cmd+i',
    command: 'notebook.cell.chat.start',
    when: 'config.notebook.experimental.cellChat && notebookChatAgentRegistered && notebookEditable && notebookEditorFocused && !inputFocus || config.notebook.experimental.generate && notebookChatAgentRegistered && notebookEditable && notebookEditorFocused && !inputFocus',
  },
  {
    key: 'alt+delete',
    command: 'notebook.cell.clearOutputs',
    when: 'notebookCellEditable && notebookCellHasOutputs && notebookEditable && notebookEditorFocused && !inputFocus',
  },
  {
    key: 'cmd+r cmd+c',
    command: 'notebook.cell.collapseCellInput',
    when: 'notebookCellListFocused && !inputFocus && !notebookCellInputIsCollapsed',
  },
  {
    key: 'cmd+r t',
    command: 'notebook.cell.collapseCellOutput',
    when: 'notebookCellHasOutputs && notebookCellListFocused && !inputFocus && !notebookCellOutputIsCollapsed',
  },
  {
    key: 'shift+alt+down',
    command: 'notebook.cell.copyDown',
    when: 'notebookEditorFocused && !inputFocus',
  },
  {
    key: 'shift+alt+up',
    command: 'notebook.cell.copyUp',
    when: 'notebookEditorFocused && !inputFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'notebook.cell.delete',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputInputFocused',
  },
  {
    key: 'shift+alt+d',
    command: 'notebook.cell.detectLanguage',
    when: 'notebookCellEditable && notebookEditable',
  },
  {
    key: 'enter',
    command: 'notebook.cell.edit',
    when: 'notebookCellListFocused && notebookEditable && !editorHoverFocused && !inputFocus && !notebookOutputInputFocused',
  },
  {
    key: 'cmd+r cmd+c',
    command: 'notebook.cell.expandCellInput',
    when: 'notebookCellInputIsCollapsed && notebookCellListFocused',
  },
  {
    key: 'cmd+r t',
    command: 'notebook.cell.expandCellOutput',
    when: 'notebookCellListFocused && notebookCellOutputIsCollapsed',
  },
  {
    key: 'ctrl+cmd+down',
    command: 'notebook.cell.focusInOutput',
    when: 'notebookCellHasOutputs && notebookEditorFocused',
  },
  {
    key: 'ctrl+cmd+up',
    command: 'notebook.cell.focusOutOutput',
    when: 'notebookEditorFocused && notebookOutputFocused',
  },
  {
    key: 'shift+cmd+enter',
    command: 'notebook.cell.insertCodeCellAbove',
    when: 'notebookCellListFocused && !inputFocus',
  },
  {
    key: 'cmd+enter',
    command: 'notebook.cell.insertCodeCellBelow',
    when: "notebookCellListFocused && !inputFocus && notebookChatOuterFocusPosition == ''",
  },
  {
    key: 'ctrl+shift+alt+j',
    command: 'notebook.cell.joinAbove',
    when: 'notebookEditorFocused',
  },
  {
    key: 'ctrl+alt+j',
    command: 'notebook.cell.joinBelow',
    when: 'notebookEditorFocused',
  },
  {
    key: 'alt+down',
    command: 'notebook.cell.moveDown',
    when: 'notebookEditorFocused && !inputFocus',
  },
  {
    key: 'alt+up',
    command: 'notebook.cell.moveUp',
    when: 'notebookEditorFocused && !inputFocus',
  },
  {
    key: 'cmd+.',
    command: 'notebook.cell.openFailureActions',
    when: 'notebookCellFocused && notebookCellHasErrorDiagnostics && !notebookCellEditorFocused',
  },
  {
    key: 'cmd+r shift+cmd+\\',
    command: 'notebook.cell.split',
    when: 'editorTextFocus && notebookCellEditable && notebookEditable && notebookEditorFocused',
  },
  {
    key: 'cmd+r y',
    command: 'notebook.cell.toggleOutputScrolling',
    when: 'notebookCellHasOutputs && notebookCellListFocused && !inputFocus',
  },
  {
    key: 'ctrl+l',
    command: 'notebook.centerActiveCell',
    when: 'notebookEditorFocused',
  },
  {
    key: 'alt+f3',
    command: 'notebook.diff.action.next',
    when: "activeEditor == 'workbench.editor.notebookTextDiffEditor'",
  },
  {
    key: 'shift+alt+f3',
    command: 'notebook.diff.action.previous',
    when: "activeEditor == 'workbench.editor.notebookTextDiffEditor'",
  },
  {
    key: 'cmd+f',
    command: 'notebook.find',
    when: "notebookEditorFocused && !editorFocus && activeEditor == 'workbench.editor.interactive' || notebookEditorFocused && !editorFocus && activeEditor == 'workbench.editor.notebook'",
  },
  {
    key: 'cmd+end',
    command: 'notebook.focusBottom',
    when: 'notebookEditorFocused && !inputFocus',
  },
  {
    key: 'cmd+down',
    command: 'notebook.focusBottom',
    when: "notebookEditorFocused && !inputFocus && notebookChatOuterFocusPosition == ''",
  },
  {
    key: 'down',
    command: 'notebook.focusNextEditor',
    when: "config.notebook.navigation.allowNavigateToSurroundingCells && notebookCursorNavigationMode && notebookEditorFocused && !accessibilityModeEnabled && !isEmbeddedDiffEditor && !notebookCellMarkdownEditMode && notebookCellType == 'markup'",
  },
  {
    key: 'ctrl+cmd+down',
    command: 'notebook.focusNextEditor',
    when: 'notebookEditorFocused && notebookOutputFocused',
  },
  {
    key: 'up',
    command: 'notebook.focusPreviousEditor',
    when: "config.notebook.navigation.allowNavigateToSurroundingCells && notebookCursorNavigationMode && notebookEditorFocused && !accessibilityModeEnabled && !isEmbeddedDiffEditor && !notebookCellMarkdownEditMode && notebookCellType == 'markup'",
  },
  {
    key: 'cmd+home',
    command: 'notebook.focusTop',
    when: 'notebookEditorFocused && !inputFocus',
  },
  {
    key: 'cmd+up',
    command: 'notebook.focusTop',
    when: "notebookEditorFocused && !inputFocus && notebookChatOuterFocusPosition == ''",
  },
  {
    key: 'left',
    command: 'notebook.fold',
    when: "notebookEditorFocused && !inputFocus && activeEditor == 'workbench.editor.notebook'",
  },
  {
    key: 'alt+cmd+[',
    command: 'notebook.fold',
    when: "notebookEditorFocused && !inputFocus && activeEditor == 'workbench.editor.notebook'",
  },
  {
    key: 'shift+alt+f',
    command: 'notebook.format',
    when: "notebookEditable && !editorTextFocus && activeEditor == 'workbench.editor.notebook'",
  },
  {
    key: 'escape',
    command: 'notebook.hideFind',
    when: 'notebookEditorFocused && notebookFindWidgetFocused',
  },
  {
    key: 'right',
    command: 'notebook.unfold',
    when: "notebookEditorFocused && !inputFocus && activeEditor == 'workbench.editor.notebook'",
  },
  {
    key: 'alt+cmd+]',
    command: 'notebook.unfold',
    when: "notebookEditorFocused && !inputFocus && activeEditor == 'workbench.editor.notebook'",
  },
  {
    key: 'shift+cmd+a',
    command: 'notification.acceptPrimaryAction',
    when: 'notificationFocus || notificationToastsVisible',
  },
  {
    key: 'cmd+backspace',
    command: 'notification.clear',
    when: 'notificationFocus',
  },
  { key: 'left', command: 'notification.collapse', when: 'notificationFocus' },
  { key: 'right', command: 'notification.expand', when: 'notificationFocus' },
  { key: 'enter', command: 'notification.toggle', when: 'notificationFocus' },
  { key: 'space', command: 'notification.toggle', when: 'notificationFocus' },
  {
    key: 'home',
    command: 'notifications.focusFirstToast',
    when: 'notificationFocus && notificationToastsVisible',
  },
  {
    key: 'pageup',
    command: 'notifications.focusFirstToast',
    when: 'notificationFocus && notificationToastsVisible',
  },
  {
    key: 'end',
    command: 'notifications.focusLastToast',
    when: 'notificationFocus && notificationToastsVisible',
  },
  {
    key: 'pagedown',
    command: 'notifications.focusLastToast',
    when: 'notificationFocus && notificationToastsVisible',
  },
  {
    key: 'down',
    command: 'notifications.focusNextToast',
    when: 'notificationFocus && notificationToastsVisible',
  },
  {
    key: 'up',
    command: 'notifications.focusPreviousToast',
    when: 'notificationFocus && notificationToastsVisible',
  },
  { key: 'cmd+r shift+cmd+n', command: 'notifications.showList' },
  {
    key: 'escape',
    command: 'problems.action.clearFilterText',
    when: 'problemsFilterFocus',
  },
  {
    key: 'cmd+c',
    command: 'problems.action.copy',
    when: "problemsVisibility && !relatedInformationFocus && focusedView == 'workbench.panel.markers.view'",
  },
  {
    key: 'cmd+f',
    command: 'problems.action.focusFilter',
    when: "focusedView == 'workbench.panel.markers.view'",
  },
  {
    key: 'cmd+down',
    command: 'problems.action.focusProblemsFromFilter',
    when: 'problemsFilterFocus',
  },
  { key: 'cmd+down', command: 'problems.action.open', when: 'problemFocus' },
  { key: 'enter', command: 'problems.action.open', when: 'problemFocus' },
  {
    key: 'ctrl+enter',
    command: 'problems.action.openToSide',
    when: 'problemFocus',
  },
  {
    key: 'cmd+.',
    command: 'problems.action.showQuickFixes',
    when: 'problemFocus',
  },
  {
    key: 'ctrl+alt+cmd+home',
    command: 'quickInput.first',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+cmd+home',
    command: 'quickInput.first',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+home',
    command: 'quickInput.first',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+home',
    command: 'quickInput.first',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+cmd+end',
    command: 'quickInput.last',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+cmd+end',
    command: 'quickInput.last',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+end',
    command: 'quickInput.last',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+end',
    command: 'quickInput.last',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+down',
    command: 'quickInput.next',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'down',
    command: 'quickInput.next',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+cmd+down',
    command: 'quickInput.nextSeparator',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+cmd+down',
    command: 'quickInput.nextSeparator',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+cmd+down',
    command: 'quickInput.nextSeparator',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'cmd+down',
    command: 'quickInput.nextSeparatorWithQuickAccessFallback',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+cmd+pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+cmd+pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+cmd+pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'cmd+pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'pagedown',
    command: 'quickInput.pageNext',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+cmd+pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+cmd+pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+cmd+pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'cmd+pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'pageup',
    command: 'quickInput.pagePrevious',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+up',
    command: 'quickInput.previous',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'up',
    command: 'quickInput.previous',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+cmd+up',
    command: 'quickInput.previousSeparator',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+cmd+up',
    command: 'quickInput.previousSeparator',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+cmd+up',
    command: 'quickInput.previousSeparator',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'cmd+up',
    command: 'quickInput.previousSeparatorWithQuickAccessFallback',
    when: "inQuickInput && quickInputType == 'quickPick'",
  },
  {
    key: 'space',
    command: 'refactorPreview.toggleCheckedState',
    when: 'listFocus && refactorPreview.enabled && !inputFocus && !treestickyScrollFocused',
  },
  { key: 'alt+cmd+r', command: 'revealFileInOS', when: '!editorFocus' },
  {
    key: 'cmd+down',
    command: 'revealReference',
    when: 'listFocus && referenceSearchVisible && !inputFocus && !treeElementCanCollapse && !treeElementCanExpand && !treestickyScrollFocused',
  },
  {
    key: 'enter',
    command: 'revealReference',
    when: 'listFocus && referenceSearchVisible && !inputFocus && !treeElementCanCollapse && !treeElementCanExpand && !treestickyScrollFocused',
  },
  { key: 'alt+cmd+s', command: 'saveAll' },
  { key: 'cmd+enter', command: 'scm.acceptInput', when: 'scmRepository' },
  {
    key: 'escape',
    command: 'scm.clearInput',
    when: 'scmRepository && !suggestWidgetVisible',
  },
  {
    key: 'alt+down',
    command: 'scm.forceViewNextCommit',
    when: 'scmRepository',
  },
  {
    key: 'alt+up',
    command: 'scm.forceViewPreviousCommit',
    when: 'scmRepository',
  },
  {
    key: 'down',
    command: 'scm.viewNextCommit',
    when: 'scmInputIsInLastPosition && scmRepository && !suggestWidgetVisible',
  },
  {
    key: 'up',
    command: 'scm.viewPreviousCommit',
    when: 'scmInputIsInFirstPosition && scmRepository && !suggestWidgetVisible',
  },
  {
    key: 'escape',
    command: 'search.action.cancel',
    when: "listFocus && searchViewletVisible && !inputFocus && !treestickyScrollFocused && searchState != '0'",
  },
  {
    key: 'cmd+c',
    command: 'search.action.copyMatch',
    when: 'fileMatchOrMatchFocus',
  },
  {
    key: 'alt+cmd+c',
    command: 'search.action.copyPath',
    when: 'fileMatchOrFolderMatchWithResourceFocus',
  },
  {
    key: 'f4',
    command: 'search.action.focusNextSearchResult',
    when: 'hasSearchResult || inSearchEditor',
  },
  {
    key: 'shift+f4',
    command: 'search.action.focusPreviousSearchResult',
    when: 'hasSearchResult || inSearchEditor',
  },
  {
    key: 'cmd+up',
    command: 'search.action.focusSearchFromResults',
    when: 'accessibilityModeEnabled && searchViewletVisible || firstMatchFocus && searchViewletVisible',
  },
  {
    key: 'cmd+enter',
    command: 'search.action.openInEditor',
    when: 'hasSearchResult && searchViewletFocus',
  },
  {
    key: 'cmd+down',
    command: 'search.action.openResult',
    when: 'fileMatchOrMatchFocus && searchViewletVisible',
  },
  {
    key: 'enter',
    command: 'search.action.openResult',
    when: 'fileMatchOrMatchFocus && searchViewletVisible',
  },
  {
    key: 'ctrl+enter',
    command: 'search.action.openResultToSide',
    when: 'fileMatchOrMatchFocus && searchViewletVisible',
  },
  {
    key: 'cmd+backspace',
    command: 'search.action.remove',
    when: 'fileMatchOrMatchFocus && searchViewletVisible',
  },
  {
    key: 'shift+cmd+1',
    command: 'search.action.replace',
    when: 'isEditableItem && matchFocus && replaceActive && searchViewletVisible',
  },
  {
    key: 'alt+cmd+enter',
    command: 'search.action.replaceAll',
    when: 'replaceActive && searchViewletVisible && !findWidgetVisible',
  },
  {
    key: 'shift+cmd+enter',
    command: 'search.action.replaceAllInFile',
    when: 'fileMatchFocus && isEditableItem && replaceActive && searchViewletVisible',
  },
  {
    key: 'shift+cmd+1',
    command: 'search.action.replaceAllInFile',
    when: 'fileMatchFocus && isEditableItem && replaceActive && searchViewletVisible',
  },
  {
    key: 'shift+cmd+enter',
    command: 'search.action.replaceAllInFolder',
    when: 'folderMatchFocus && isEditableItem && replaceActive && searchViewletVisible',
  },
  {
    key: 'shift+cmd+1',
    command: 'search.action.replaceAllInFolder',
    when: 'folderMatchFocus && isEditableItem && replaceActive && searchViewletVisible',
  },
  {
    key: 'shift+alt+f',
    command: 'search.action.restrictSearchToFolder',
    when: 'folderMatchWithResourceFocus && searchViewletVisible',
  },
  {
    key: 'cmd+down',
    command: 'search.focus.nextInputBox',
    when: 'inSearchEditor && inputBoxFocus || inputBoxFocus && searchViewletVisible',
  },
  {
    key: 'cmd+up',
    command: 'search.focus.previousInputBox',
    when: 'inSearchEditor && inputBoxFocus || inputBoxFocus && searchViewletVisible && !searchInputBoxFocus',
  },
  {
    key: 'shift+cmd+l',
    command: 'selectAllSearchEditorMatches',
    when: 'inSearchEditor',
  },
  {
    key: 'escape',
    command: 'settings.action.focusLevelUp',
    when: 'inSettingsEditor && !inSettingsJSONEditor && !inSettingsSearch',
  },
  {
    key: 'enter',
    command: 'settings.action.focusSettingControl',
    when: 'inSettingsEditor && settingRowFocus',
  },
  {
    key: 'down',
    command: 'settings.action.focusSettingsFromSearch',
    when: 'inSettingsSearch && !suggestWidgetVisible',
  },
  {
    key: 'enter',
    command: 'settings.action.focusSettingsList',
    when: 'inSettingsEditor && settingsTocRowFocus',
  },
  {
    key: 'left',
    command: 'settings.action.focusTOC',
    when: 'inSettingsEditor && settingRowFocus',
  },
  {
    key: 'shift+f9',
    command: 'settings.action.showContextMenu',
    when: 'inSettingsEditor',
  },
  { key: 'cmd+; cmd+x', command: 'testing.cancelRun' },
  { key: 'cmd+; shift+cmd+a', command: 'testing.coverageAll' },
  {
    key: 'cmd+; shift+cmd+c',
    command: 'testing.coverageAtCursor',
    when: 'editorTextFocus',
  },
  {
    key: 'cmd+; shift+cmd+f',
    command: 'testing.coverageCurrentFile',
    when: 'editorTextFocus',
  },
  { key: 'cmd+; shift+cmd+l', command: 'testing.coverageLastRun' },
  { key: 'cmd+; cmd+a', command: 'testing.debugAll' },
  {
    key: 'cmd+; cmd+c',
    command: 'testing.debugAtCursor',
    when: 'editorTextFocus',
  },
  {
    key: 'cmd+; cmd+f',
    command: 'testing.debugCurrentFile',
    when: 'editorTextFocus',
  },
  { key: 'cmd+; cmd+e', command: 'testing.debugFailTests' },
  { key: 'cmd+; cmd+l', command: 'testing.debugLastRun' },
  { key: 'cmd+; cmd+m', command: 'testing.openOutputPeek' },
  { key: 'cmd+; e', command: 'testing.reRunFailTests' },
  { key: 'cmd+; l', command: 'testing.reRunLastRun' },
  {
    key: 'cmd+; cmd+r',
    command: 'testing.refreshTests',
    when: 'testing.canRefresh',
  },
  { key: 'cmd+; a', command: 'testing.runAll' },
  { key: 'cmd+; c', command: 'testing.runAtCursor', when: 'editorTextFocus' },
  {
    key: 'cmd+; f',
    command: 'testing.runCurrentFile',
    when: 'editorTextFocus',
  },
  {
    key: 'cmd+; cmd+o',
    command: 'testing.showMostRecentOutput',
    when: 'testing.hasAnyResults',
  },
  { key: 'cmd+; shift+cmd+i', command: 'testing.toggleInlineCoverage' },
  { key: 'cmd+; cmd+i', command: 'testing.toggleInlineTestOutput' },
  {
    key: 'alt+h',
    command: 'testing.toggleTestingPeekHistory',
    when: 'testing.isPeekVisible',
  },
  {
    key: 'alt+cmd+c',
    command: 'toggleSearchCaseSensitive',
    when: 'searchViewletFocus && !fileMatchOrFolderMatchFocus',
  },
  {
    key: 'alt+cmd+c',
    command: 'toggleSearchEditorCaseSensitive',
    when: 'inSearchEditor && searchInputBoxFocus',
  },
  {
    key: 'alt+cmd+l',
    command: 'toggleSearchEditorContextLines',
    when: 'inSearchEditor',
  },
  {
    key: 'alt+cmd+r',
    command: 'toggleSearchEditorRegex',
    when: 'inSearchEditor && searchInputBoxFocus',
  },
  {
    key: 'alt+cmd+w',
    command: 'toggleSearchEditorWholeWord',
    when: 'inSearchEditor && searchInputBoxFocus',
  },
  {
    key: 'alt+cmd+p',
    command: 'toggleSearchPreserveCase',
    when: 'searchViewletFocus',
  },
  {
    key: 'alt+cmd+r',
    command: 'toggleSearchRegex',
    when: 'searchViewletFocus',
  },
  {
    key: 'alt+cmd+w',
    command: 'toggleSearchWholeWord',
    when: 'searchViewletFocus',
  },
  {
    key: 'cmd+down',
    command: 'widgetNavigation.focusNext',
    when: "inputFocus && navigableContainerFocused || navigableContainerFocused && treestickyScrollFocused || navigableContainerFocused && !listFocus || navigableContainerFocused && listScrollAtBoundary == 'both' || navigableContainerFocused && listScrollAtBoundary == 'bottom'",
  },
  {
    key: 'cmd+up',
    command: 'widgetNavigation.focusPrevious',
    when: "inputFocus && navigableContainerFocused || navigableContainerFocused && treestickyScrollFocused || navigableContainerFocused && !listFocus || navigableContainerFocused && listScrollAtBoundary == 'both' || navigableContainerFocused && listScrollAtBoundary == 'top'",
  },
  { key: 'cmd+escape', command: 'workbench.action.chat.cancel' },
  {
    key: 'cmd+down',
    command: 'workbench.action.chat.focusInput',
    when: 'inChat && !inChatInput',
  },
  {
    key: 'cmd+i',
    command: 'workbench.action.chat.holdToVoiceChatInChatView',
    when: 'chatIsEnabled && hasSpeechProvider && !chatSessionRequestInProgress && !editorFocus && !inChatInput && !inlineChatFocused && !notebookEditorFocused',
  },
  {
    key: 'ctrl+l',
    command: 'workbench.action.chat.newChat',
    when: 'chatIsEnabled && inChat',
  },
  {
    key: 'alt+cmd+pagedown',
    command: 'workbench.action.chat.nextCodeBlock',
    when: 'chatIsEnabled && inChat',
  },
  {
    key: 'cmd+f9',
    command: 'workbench.action.chat.nextFileTree',
    when: 'chatIsEnabled && inChat',
  },
  { key: 'ctrl+cmd+i', command: 'workbench.action.chat.open' },
  {
    key: 'alt+cmd+pageup',
    command: 'workbench.action.chat.previousCodeBlock',
    when: 'chatIsEnabled && inChat',
  },
  {
    key: 'shift+cmd+f9',
    command: 'workbench.action.chat.previousFileTree',
    when: 'chatIsEnabled && inChat',
  },
  {
    key: 'cmd+backspace',
    command: 'workbench.action.chat.remove',
    when: 'inChat && !inChatInput',
  },
  {
    key: 'shift+cmd+enter',
    command: 'workbench.action.chat.sendToNewChat',
    when: 'chatInputHasText && inChatInput && !chatSessionRequestInProgress',
  },
  {
    key: 'cmd+i',
    command: 'workbench.action.chat.startVoiceChat',
    when: 'chatIsEnabled && hasSpeechProvider && inChatInput && !chatSessionRequestInProgress && !editorFocus && !notebookEditorFocused && !scopedVoiceChatGettingReady && !speechToTextInProgress && !terminalChatActiveRequest || chatIsEnabled && hasSpeechProvider && inlineChatFocused && !chatSessionRequestInProgress && !editorFocus && !notebookEditorFocused && !scopedVoiceChatGettingReady && !speechToTextInProgress && !terminalChatActiveRequest',
  },
  {
    key: 'cmd+i',
    command: 'workbench.action.chat.stopListeningAndSubmit',
    when: "inChatInput && voiceChatInProgress && scopedVoiceChatInProgress == 'editor' || inChatInput && voiceChatInProgress && scopedVoiceChatInProgress == 'inline' || inChatInput && voiceChatInProgress && scopedVoiceChatInProgress == 'quick' || inChatInput && voiceChatInProgress && scopedVoiceChatInProgress == 'terminal' || inChatInput && voiceChatInProgress && scopedVoiceChatInProgress == 'view' || inlineChatFocused && voiceChatInProgress && scopedVoiceChatInProgress == 'editor' || inlineChatFocused && voiceChatInProgress && scopedVoiceChatInProgress == 'inline' || inlineChatFocused && voiceChatInProgress && scopedVoiceChatInProgress == 'quick' || inlineChatFocused && voiceChatInProgress && scopedVoiceChatInProgress == 'terminal' || inlineChatFocused && voiceChatInProgress && scopedVoiceChatInProgress == 'view'",
  },
  { key: 'cmd+w', command: 'workbench.action.closeActiveEditor' },
  { key: 'cmd+r cmd+w', command: 'workbench.action.closeAllEditors' },
  { key: 'cmd+r shift+cmd+w', command: 'workbench.action.closeAllGroups' },
  { key: 'cmd+r w', command: 'workbench.action.closeEditorsInGroup' },
  {
    key: 'cmd+r f',
    command: 'workbench.action.closeFolder',
    when: "emptyWorkspaceSupport && workbenchState != 'empty'",
  },
  {
    key: 'cmd+w',
    command: 'workbench.action.closeGroup',
    when: 'activeEditorGroupEmpty && multipleEditorGroups',
  },
  { key: 'alt+cmd+t', command: 'workbench.action.closeOtherEditors' },
  {
    key: 'shift+escape',
    command: 'workbench.action.closeQuickOpen',
    when: 'inQuickOpen',
  },
  {
    key: 'escape',
    command: 'workbench.action.closeQuickOpen',
    when: 'inQuickOpen',
  },
  { key: 'cmd+r u', command: 'workbench.action.closeUnmodifiedEditors' },
  { key: 'shift+cmd+w', command: 'workbench.action.closeWindow' },
  {
    key: 'cmd+w',
    command: 'workbench.action.closeWindow',
    when: '!editorIsOpen && !multipleEditorGroups',
  },
  {
    key: 'alt+f5',
    command: 'workbench.action.compareEditor.nextChange',
    when: 'textCompareEditorVisible',
  },
  {
    key: 'cmd+r shift+o',
    command: 'workbench.action.compareEditor.openSide',
    when: 'inDiffEditor',
  },
  {
    key: 'shift+alt+f5',
    command: 'workbench.action.compareEditor.previousChange',
    when: 'textCompareEditorVisible',
  },
  {
    key: 'cmd+r o',
    command: 'workbench.action.copyEditorToNewWindow',
    when: 'activeEditor',
  },
  {
    key: 'shift+f5',
    command: 'workbench.action.debug.disconnect',
    when: 'focusedSessionIsAttach && inDebugMode',
  },
  {
    key: 'shift+cmd+f5',
    command: 'workbench.action.debug.restart',
    when: 'inDebugMode',
  },
  {
    key: 'ctrl+f5',
    command: 'workbench.action.debug.run',
    when: "debuggersAvailable && debugState != 'initializing'",
  },
  {
    key: 'f5',
    command: 'workbench.action.debug.start',
    when: "debuggersAvailable && debugState == 'inactive'",
  },
  {
    key: 'cmd+f11',
    command: 'workbench.action.debug.stepIntoTarget',
    when: "inDebugMode && stepIntoTargetsSupported && debugState == 'stopped'",
  },
  {
    key: 'shift+f11',
    command: 'workbench.action.debug.stepOut',
    when: "debugState == 'stopped'",
  },
  {
    key: 'f10',
    command: 'workbench.action.debug.stepOver',
    when: "debugState == 'stopped'",
  },
  {
    key: 'shift+f5',
    command: 'workbench.action.debug.stop',
    when: 'inDebugMode && !focusedSessionIsAttach',
  },
  {
    key: 'cmd+r m',
    command: 'workbench.action.editor.changeLanguageMode',
    when: '!notebookEditorFocused',
  },
  {
    key: 'alt+cmd+v',
    command: 'workbench.action.editorDictation.start',
    when: 'hasSpeechProvider && !editorReadonly && !speechToTextInProgress',
  },
  { key: 'cmd+r p', command: 'workbench.action.files.copyPathOfActiveFile' },
  { key: 'cmd+n', command: 'workbench.action.files.newUntitledFile' },
  { key: 'cmd+o', command: 'workbench.action.files.openFile', when: 'false' },
  {
    key: 'cmd+o',
    command: 'workbench.action.files.openFileFolder',
    when: 'isMacNative && openFolderWorkspaceSupport',
  },
  {
    key: 'cmd+o',
    command: 'workbench.action.files.openFolderViaWorkspace',
    when: "!openFolderWorkspaceSupport && workbenchState == 'workspace'",
  },
  {
    key: 'cmd+o',
    command: 'workbench.action.files.openLocalFileFolder',
    when: 'remoteFileDialogVisible',
  },
  {
    key: 'cmd+r r',
    command: 'workbench.action.files.revealActiveFileInWindows',
  },
  { key: 'cmd+s', command: 'workbench.action.files.save' },
  { key: 'shift+cmd+s', command: 'workbench.action.files.saveAs' },
  {
    key: 'shift+cmd+s',
    command: 'workbench.action.files.saveLocalFile',
    when: 'remoteFileDialogVisible',
  },
  { key: 'cmd+r s', command: 'workbench.action.files.saveWithoutFormatting' },
  { key: 'shift+cmd+f', command: 'workbench.action.findInFiles' },
  { key: 'cmd+r cmd+up', command: 'workbench.action.focusAboveGroup' },
  { key: 'cmd+r cmd+down', command: 'workbench.action.focusBelowGroup' },
  { key: 'cmd+8', command: 'workbench.action.focusEighthEditorGroup' },
  { key: 'cmd+5', command: 'workbench.action.focusFifthEditorGroup' },
  { key: 'cmd+1', command: 'workbench.action.focusFirstEditorGroup' },
  { key: 'cmd+4', command: 'workbench.action.focusFourthEditorGroup' },
  { key: 'cmd+r cmd+left', command: 'workbench.action.focusLeftGroup' },
  { key: 'f6', command: 'workbench.action.focusNextPart' },
  { key: 'shift+f6', command: 'workbench.action.focusPreviousPart' },
  { key: 'cmd+r cmd+right', command: 'workbench.action.focusRightGroup' },
  { key: 'cmd+2', command: 'workbench.action.focusSecondEditorGroup' },
  { key: 'cmd+7', command: 'workbench.action.focusSeventhEditorGroup' },
  { key: 'cmd+0', command: 'workbench.action.focusSideBar' },
  { key: 'cmd+6', command: 'workbench.action.focusSixthEditorGroup' },
  { key: 'cmd+3', command: 'workbench.action.focusThirdEditorGroup' },
  { key: 'ctrl+g', command: 'workbench.action.gotoLine' },
  {
    key: 'shift+cmd+o',
    command: 'workbench.action.gotoSymbol',
    when: '!accessibilityHelpIsShown && !accessibleViewIsShown',
  },
  {
    key: 'down',
    command: 'workbench.action.interactivePlayground.down',
    when: 'interactivePlaygroundFocus && !editorTextFocus',
  },
  {
    key: 'up',
    command: 'workbench.action.interactivePlayground.up',
    when: 'interactivePlaygroundFocus && !editorTextFocus',
  },
  {
    key: 'pagedown',
    command: 'workbench.action.interactivePlayground.pageDown',
    when: 'interactivePlaygroundFocus && !editorTextFocus',
  },
  {
    key: 'pageup',
    command: 'workbench.action.interactivePlayground.pageUp',
    when: 'interactivePlaygroundFocus && !editorTextFocus',
  },
  {
    key: 'cmd+r shift+cmd+\\',
    command: 'workbench.action.joinEditorInGroup',
    when: 'sideBySideEditorActive',
  },
  { key: 'cmd+r enter', command: 'workbench.action.keepEditor' },
  { key: 'cmd+r', command: 'workbench.action.keychord.leader', when: 'false' },
  { key: 'cmd+9', command: 'workbench.action.lastEditorInGroup' },
  { key: 'ctrl+0', command: 'workbench.action.lastEditorInGroup' },
  { key: 'cmd+r down', command: 'workbench.action.moveActiveEditorGroupDown' },
  { key: 'cmd+r left', command: 'workbench.action.moveActiveEditorGroupLeft' },
  {
    key: 'cmd+r right',
    command: 'workbench.action.moveActiveEditorGroupRight',
  },
  { key: 'cmd+r up', command: 'workbench.action.moveActiveEditorGroupUp' },
  {
    key: 'cmd+r shift+cmd+left',
    command: 'workbench.action.moveEditorLeftInGroup',
  },
  {
    key: 'cmd+r shift+cmd+right',
    command: 'workbench.action.moveEditorRightInGroup',
  },
  { key: 'ctrl+cmd+1', command: 'workbench.action.moveEditorToFirstGroup' },
  { key: 'ctrl+cmd+9', command: 'workbench.action.moveEditorToLastGroup' },
  { key: 'ctrl+cmd+right', command: 'workbench.action.moveEditorToNextGroup' },
  {
    key: 'ctrl+cmd+left',
    command: 'workbench.action.moveEditorToPreviousGroup',
  },
  {
    key: 'ctrl+-',
    command: 'workbench.action.navigateBack',
    when: 'canNavigateBack',
  },
  {
    key: 'ctrl+shift+-',
    command: 'workbench.action.navigateForward',
    when: 'canNavigateForward',
  },
  {
    key: 'cmd+r cmd+q',
    command: 'workbench.action.navigateToLastEditLocation',
  },
  { key: 'shift+cmd+n', command: 'workbench.action.newWindow' },
  { key: 'shift+cmd+]', command: 'workbench.action.nextEditor' },
  { key: 'alt+cmd+right', command: 'workbench.action.nextEditor' },
  { key: 'cmd+r alt+cmd+right', command: 'workbench.action.nextEditorInGroup' },
  { key: 'ctrl+1', command: 'workbench.action.openEditorAtIndex1' },
  { key: 'ctrl+2', command: 'workbench.action.openEditorAtIndex2' },
  { key: 'ctrl+3', command: 'workbench.action.openEditorAtIndex3' },
  { key: 'ctrl+4', command: 'workbench.action.openEditorAtIndex4' },
  { key: 'ctrl+5', command: 'workbench.action.openEditorAtIndex5' },
  { key: 'ctrl+6', command: 'workbench.action.openEditorAtIndex6' },
  { key: 'ctrl+7', command: 'workbench.action.openEditorAtIndex7' },
  { key: 'ctrl+8', command: 'workbench.action.openEditorAtIndex8' },
  { key: 'ctrl+9', command: 'workbench.action.openEditorAtIndex9' },
  { key: 'cmd+r cmd+s', command: 'workbench.action.openGlobalKeybindings' },
  { key: 'ctrl+r', command: 'workbench.action.openRecent' },
  { key: 'cmd+,', command: 'workbench.action.openSettings' },
  {
    key: 'shift+cmd+u',
    command: 'workbench.action.output.toggleOutput',
    when: 'workbench.panel.output.active',
  },
  {
    key: 'cmd+r shift+enter',
    command: 'workbench.action.pinEditor',
    when: '!activeEditorIsPinned',
  },
  { key: 'shift+cmd+[', command: 'workbench.action.previousEditor' },
  { key: 'alt+cmd+left', command: 'workbench.action.previousEditor' },
  {
    key: 'cmd+r alt+cmd+left',
    command: 'workbench.action.previousEditorInGroup',
  },
  { key: 'cmd+p', command: 'workbench.action.quickOpen' },
  {
    key: 'ctrl+shift+tab',
    command: 'workbench.action.quickOpenLeastRecentlyUsedEditorInGroup',
    when: '!activeEditorGroupEmpty',
  },
  {
    key: 'ctrl+tab',
    command: 'workbench.action.quickOpenPreviousRecentlyUsedEditorInGroup',
    when: '!activeEditorGroupEmpty',
  },
  { key: 'ctrl+q', command: 'workbench.action.quickOpenView' },
  {
    key: 'shift+cmd+i',
    command: 'workbench.action.quickchat.toggle',
    when: 'chatIsEnabled',
  },
  { key: 'cmd+q', command: 'workbench.action.quit' },
  { key: 'alt+cmd+o', command: 'workbench.action.remote.showMenu' },
  { key: 'shift+cmd+t', command: 'workbench.action.reopenClosedEditor' },
  { key: 'shift+cmd+h', command: 'workbench.action.replaceInFiles' },
  {
    key: 'shift+cmd+j',
    command: 'workbench.action.search.toggleQueryDetails',
    when: 'inSearchEditor || searchViewletFocus',
  },
  { key: 'cmd+r cmd+t', command: 'workbench.action.selectTheme' },
  { key: 'alt+cmd+tab', command: 'workbench.action.showAllEditors' },
  { key: 'cmd+t', command: 'workbench.action.showAllSymbols' },
  { key: 'f1', command: 'workbench.action.showCommands' },
  { key: 'shift+cmd+p', command: 'workbench.action.showCommands' },
  { key: 'cmd+\\', command: 'workbench.action.splitEditor' },
  { key: 'cmd+r cmd+\\', command: 'workbench.action.splitEditorDown' },
  {
    key: 'cmd+r shift+cmd+\\',
    command: 'workbench.action.splitEditorInGroup',
    when: 'activeEditorCanSplitInGroup',
  },
  { key: 'cmd+r cmd+\\', command: 'workbench.action.splitEditorLeft' },
  { key: 'cmd+r cmd+\\', command: 'workbench.action.splitEditorOrthogonal' },
  { key: 'cmd+r cmd+\\', command: 'workbench.action.splitEditorRight' },
  { key: 'cmd+r cmd+\\', command: 'workbench.action.splitEditorUp' },
  { key: 'ctrl+w', command: 'workbench.action.switchWindow' },
  {
    key: 'shift+cmd+b',
    command: 'workbench.action.tasks.build',
    when: 'taskCommandsRegistered',
  },
  {
    key: 'shift+escape',
    command: 'workbench.action.terminal.chat.close',
    when: 'terminalChatFocus && terminalChatVisible',
  },
  {
    key: 'escape',
    command: 'workbench.action.terminal.chat.close',
    when: 'terminalChatFocus && terminalChatVisible',
  },
  {
    key: 'cmd+i',
    command: 'workbench.action.terminal.chat.focusInput',
    when: 'terminalChatFocus && !inlineChatFocused',
  },
  {
    key: 'cmd+up',
    command: 'workbench.action.terminal.chat.focusInput',
    when: 'terminalChatFocus && !inlineChatFocused',
  },
  {
    key: 'cmd+down',
    command: 'workbench.action.terminal.chat.focusResponse',
    when: 'terminalChatFocus',
  },
  {
    key: 'alt+cmd+enter',
    command: 'workbench.action.terminal.chat.insertCommand',
    when: 'terminalChatResponseContainsCodeBlock && terminalHasBeenCreated && !terminalChatActiveRequest && !terminalChatResponseContainsMultipleCodeBlocks || terminalChatResponseContainsCodeBlock && terminalProcessSupported && !terminalChatActiveRequest && !terminalChatResponseContainsMultipleCodeBlocks',
  },
  {
    key: 'alt+enter',
    command: 'workbench.action.terminal.chat.insertCommand',
    when: 'terminalChatResponseContainsCodeBlock && terminalHasBeenCreated && !terminalChatActiveRequest && !terminalChatResponseContainsMultipleCodeBlocks || terminalChatResponseContainsCodeBlock && terminalProcessSupported && !terminalChatActiveRequest && !terminalChatResponseContainsMultipleCodeBlocks',
  },
  {
    key: 'alt+cmd+enter',
    command: 'workbench.action.terminal.chat.insertFirstCommand',
    when: 'terminalChatResponseContainsMultipleCodeBlocks && terminalHasBeenCreated && !terminalChatActiveRequest || terminalChatResponseContainsMultipleCodeBlocks && terminalProcessSupported && !terminalChatActiveRequest',
  },
  {
    key: 'alt+enter',
    command: 'workbench.action.terminal.chat.insertFirstCommand',
    when: 'terminalChatResponseContainsMultipleCodeBlocks && terminalHasBeenCreated && !terminalChatActiveRequest || terminalChatResponseContainsMultipleCodeBlocks && terminalProcessSupported && !terminalChatActiveRequest',
  },
  {
    key: 'enter',
    command: 'workbench.action.terminal.chat.makeRequest',
    when: 'terminalChatFocus && terminalHasBeenCreated && !inlineChatEmpty && !terminalChatActiveRequest || terminalChatFocus && terminalProcessSupported && !inlineChatEmpty && !terminalChatActiveRequest',
  },
  {
    key: 'down',
    command: 'workbench.action.terminal.chat.nextFromHistory',
    when: 'terminalChatFocus',
  },
  {
    key: 'up',
    command: 'workbench.action.terminal.chat.previousFromHistory',
    when: 'terminalChatFocus',
  },
  {
    key: 'cmd+enter',
    command: 'workbench.action.terminal.chat.runCommand',
    when: 'terminalChatResponseContainsCodeBlock && terminalHasBeenCreated && !terminalChatActiveRequest && !terminalChatResponseContainsMultipleCodeBlocks || terminalChatResponseContainsCodeBlock && terminalProcessSupported && !terminalChatActiveRequest && !terminalChatResponseContainsMultipleCodeBlocks',
  },
  {
    key: 'cmd+enter',
    command: 'workbench.action.terminal.chat.runFirstCommand',
    when: 'terminalChatResponseContainsMultipleCodeBlocks && terminalHasBeenCreated && !terminalChatActiveRequest || terminalChatResponseContainsMultipleCodeBlocks && terminalProcessSupported && !terminalChatActiveRequest',
  },
  {
    key: 'escape',
    command: 'workbench.action.terminal.clearSelection',
    when: 'terminalFocusInAny && terminalHasBeenCreated && terminalTextSelected && !terminalFindVisible || terminalFocusInAny && terminalProcessSupported && terminalTextSelected && !terminalFindVisible',
  },
  {
    key: 'cmd+c',
    command: 'workbench.action.terminal.copySelection',
    when: 'terminalTextSelectedInFocused || terminalFocus && terminalHasBeenCreated && terminalTextSelected || terminalFocus && terminalProcessSupported && terminalTextSelected || terminalFocus && terminalTextSelected && terminalTextSelectedInFocused || terminalHasBeenCreated && terminalTextSelected && terminalTextSelectedInFocused || terminalProcessSupported && terminalTextSelected && terminalTextSelectedInFocused',
  },
  {
    key: 'f3',
    command: 'workbench.action.terminal.findNext',
    when: 'terminalFindFocused && terminalHasBeenCreated || terminalFindFocused && terminalProcessSupported || terminalFocusInAny && terminalHasBeenCreated || terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'cmd+g',
    command: 'workbench.action.terminal.findNext',
    when: 'terminalFindFocused && terminalHasBeenCreated || terminalFindFocused && terminalProcessSupported || terminalFocusInAny && terminalHasBeenCreated || terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'shift+enter',
    command: 'workbench.action.terminal.findNext',
    when: 'terminalFindInputFocused && terminalHasBeenCreated || terminalFindInputFocused && terminalProcessSupported',
  },
  {
    key: 'shift+f3',
    command: 'workbench.action.terminal.findPrevious',
    when: 'terminalFindFocused && terminalHasBeenCreated || terminalFindFocused && terminalProcessSupported || terminalFocusInAny && terminalHasBeenCreated || terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'shift+cmd+g',
    command: 'workbench.action.terminal.findPrevious',
    when: 'terminalFindFocused && terminalHasBeenCreated || terminalFindFocused && terminalProcessSupported || terminalFocusInAny && terminalHasBeenCreated || terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'enter',
    command: 'workbench.action.terminal.findPrevious',
    when: 'terminalFindInputFocused && terminalHasBeenCreated || terminalFindInputFocused && terminalProcessSupported',
  },
  {
    key: 'cmd+down',
    command: 'workbench.action.terminal.focus',
    when: "accessibilityModeEnabled && accessibleViewOnLastLine && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal' || accessibilityModeEnabled && accessibleViewOnLastLine && terminalProcessSupported && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'cmd+up',
    command: 'workbench.action.terminal.focusAccessibleBuffer',
    when: 'accessibilityModeEnabled && terminalFocus && terminalHasBeenCreated || accessibilityModeEnabled && terminalFocus && terminalProcessSupported',
  },
  {
    key: 'alt+f2',
    command: 'workbench.action.terminal.focusAccessibleBuffer',
    when: 'accessibilityModeEnabled && terminalFocus && terminalHasBeenCreated || accessibilityModeEnabled && terminalFocus && terminalProcessSupported',
  },
  {
    key: 'cmd+f',
    command: 'workbench.action.terminal.focusFind',
    when: 'terminalFindFocused && terminalHasBeenCreated || terminalFindFocused && terminalProcessSupported || terminalFocusInAny && terminalHasBeenCreated || terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'cmd+r cmd+i',
    command: 'workbench.action.terminal.focusHover',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalIsOpen || terminalFocus && terminalProcessSupported || terminalHasBeenCreated && terminalTabsFocus || terminalIsOpen && terminalTabsFocus || terminalProcessSupported && terminalTabsFocus',
  },
  {
    key: 'shift+cmd+]',
    command: 'workbench.action.terminal.focusNext',
    when: 'terminalFocus && terminalHasBeenCreated && !terminalEditorFocus || terminalFocus && terminalProcessSupported && !terminalEditorFocus',
  },
  {
    key: 'alt+cmd+down',
    command: 'workbench.action.terminal.focusNextPane',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'alt+cmd+right',
    command: 'workbench.action.terminal.focusNextPane',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'shift+cmd+[',
    command: 'workbench.action.terminal.focusPrevious',
    when: 'terminalFocus && terminalHasBeenCreated && !terminalEditorFocus || terminalFocus && terminalProcessSupported && !terminalEditorFocus',
  },
  {
    key: 'alt+cmd+up',
    command: 'workbench.action.terminal.focusPreviousPane',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'alt+cmd+left',
    command: 'workbench.action.terminal.focusPreviousPane',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'shift+cmd+\\',
    command: 'workbench.action.terminal.focusTabs',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported || terminalHasBeenCreated && terminalTabsFocus || terminalProcessSupported && terminalTabsFocus',
  },
  {
    key: 'cmd+g',
    command: 'workbench.action.terminal.goToRecentDirectory',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'shift+escape',
    command: 'workbench.action.terminal.hideFind',
    when: 'terminalFindVisible && terminalFocusInAny && terminalHasBeenCreated || terminalFindVisible && terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'escape',
    command: 'workbench.action.terminal.hideFind',
    when: 'terminalFindVisible && terminalFocusInAny && terminalHasBeenCreated || terminalFindVisible && terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'delete',
    command: 'workbench.action.terminal.killActiveTab',
    when: 'terminalHasBeenCreated && terminalTabsFocus || terminalIsOpen && terminalTabsFocus || terminalProcessSupported && terminalTabsFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'workbench.action.terminal.killActiveTab',
    when: 'terminalHasBeenCreated && terminalTabsFocus || terminalIsOpen && terminalTabsFocus || terminalProcessSupported && terminalTabsFocus',
  },
  {
    key: 'cmd+w',
    command: 'workbench.action.terminal.killEditor',
    when: 'terminalEditorFocus && terminalFocus && terminalHasBeenCreated || terminalEditorFocus && terminalFocus && terminalProcessSupported',
  },
  {
    key: 'ctrl+shift+`',
    command: 'workbench.action.terminal.new',
    when: 'terminalProcessSupported || terminalWebExtensionContributedProfile',
  },
  {
    key: 'shift+cmd+c',
    command: 'workbench.action.terminal.openNativeConsole',
    when: '!terminalFocus',
  },
  {
    key: 'cmd+v',
    command: 'workbench.action.terminal.paste',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'enter',
    command: 'workbench.action.terminal.renameActiveTab',
    when: 'terminalHasBeenCreated && terminalTabsFocus && terminalTabsSingularSelection || terminalProcessSupported && terminalTabsFocus && terminalTabsSingularSelection',
  },
  {
    key: 'ctrl+cmd+down',
    command: 'workbench.action.terminal.resizePaneDown',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'ctrl+cmd+left',
    command: 'workbench.action.terminal.resizePaneLeft',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'ctrl+cmd+right',
    command: 'workbench.action.terminal.resizePaneRight',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'ctrl+cmd+up',
    command: 'workbench.action.terminal.resizePaneUp',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'cmd+r',
    command: 'workbench.action.terminal.runRecentCommand',
    when: "accessibilityModeEnabled && terminalFocus && terminalHasBeenCreated || accessibilityModeEnabled && terminalFocus && terminalProcessSupported || accessibilityModeEnabled && accessibleViewIsShown && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal' || accessibilityModeEnabled && accessibleViewIsShown && terminalProcessSupported && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'ctrl+alt+r',
    command: 'workbench.action.terminal.runRecentCommand',
    when: 'terminalFocus && terminalHasBeenCreated && !accessibilityModeEnabled || terminalFocus && terminalProcessSupported && !accessibilityModeEnabled',
  },
  {
    key: 'alt+cmd+pagedown',
    command: 'workbench.action.terminal.scrollDown',
    when: 'terminalFocusInAny && terminalHasBeenCreated && !terminalAltBufferActive || terminalFocusInAny && terminalProcessSupported && !terminalAltBufferActive',
  },
  {
    key: 'pagedown',
    command: 'workbench.action.terminal.scrollDownPage',
    when: 'terminalFocusInAny && terminalHasBeenCreated && !terminalAltBufferActive || terminalFocusInAny && terminalProcessSupported && !terminalAltBufferActive',
  },
  {
    key: 'cmd+end',
    command: 'workbench.action.terminal.scrollToBottom',
    when: 'terminalFocusInAny && terminalHasBeenCreated && !terminalAltBufferActive || terminalFocusInAny && terminalProcessSupported && !terminalAltBufferActive',
  },
  {
    key: 'cmd+end',
    command: 'workbench.action.terminal.scrollToBottomAccessibleView',
    when: "accessibleViewIsShown && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal' || accessibleViewIsShown && terminalProcessSupported && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'cmd+down',
    command: 'workbench.action.terminal.scrollToNextCommand',
    when: 'terminalFocus && terminalHasBeenCreated && !accessibilityModeEnabled || terminalFocus && terminalProcessSupported && !accessibilityModeEnabled',
  },
  {
    key: 'cmd+up',
    command: 'workbench.action.terminal.scrollToPreviousCommand',
    when: 'terminalFocus && terminalHasBeenCreated && !accessibilityModeEnabled || terminalFocus && terminalProcessSupported && !accessibilityModeEnabled',
  },
  {
    key: 'cmd+home',
    command: 'workbench.action.terminal.scrollToTop',
    when: 'terminalFocusInAny && terminalHasBeenCreated && !terminalAltBufferActive || terminalFocusInAny && terminalProcessSupported && !terminalAltBufferActive',
  },
  {
    key: 'cmd+home',
    command: 'workbench.action.terminal.scrollToTopAccessibleView',
    when: "accessibleViewIsShown && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal' || accessibleViewIsShown && terminalProcessSupported && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'alt+cmd+pageup',
    command: 'workbench.action.terminal.scrollUp',
    when: 'terminalFocusInAny && terminalHasBeenCreated && !terminalAltBufferActive || terminalFocusInAny && terminalProcessSupported && !terminalAltBufferActive',
  },
  {
    key: 'pageup',
    command: 'workbench.action.terminal.scrollUpPage',
    when: 'terminalFocusInAny && terminalHasBeenCreated && !terminalAltBufferActive || terminalFocusInAny && terminalProcessSupported && !terminalAltBufferActive',
  },
  {
    key: 'cmd+a',
    command: 'workbench.action.terminal.selectAll',
    when: 'terminalFocusInAny && terminalHasBeenCreated || terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'shift+cmd+down',
    command: 'workbench.action.terminal.selectToNextCommand',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'shift+cmd+up',
    command: 'workbench.action.terminal.selectToPreviousCommand',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'ctrl+space',
    command: 'workbench.action.terminal.sendSequence',
    when: "terminalFocus && terminalShellIntegrationEnabled && !accessibilityModeEnabled && terminalShellType == 'pwsh'",
    args: { text: '\u001b[24~a' },
  },
  {
    key: 'alt+space',
    command: 'workbench.action.terminal.sendSequence',
    when: "terminalFocus && terminalShellIntegrationEnabled && !accessibilityModeEnabled && terminalShellType == 'pwsh'",
    args: { text: '\u001b[24~b' },
  },
  {
    key: 'shift+enter',
    command: 'workbench.action.terminal.sendSequence',
    when: "terminalFocus && terminalShellIntegrationEnabled && !accessibilityModeEnabled && terminalShellType == 'pwsh'",
    args: { text: '\u001b[24~c' },
  },
  {
    key: 'shift+cmd+right',
    command: 'workbench.action.terminal.sendSequence',
    when: "terminalFocus && terminalShellIntegrationEnabled && !accessibilityModeEnabled && terminalShellType == 'pwsh'",
    args: { text: '\u001b[24~d' },
  },
  {
    key: 'ctrl+space',
    command: 'workbench.action.terminal.sendSequence',
    when: "config.terminal.integrated.suggest.enabled && terminalFocus && terminalShellIntegrationEnabled && !accessibilityModeEnabled && terminalShellType == 'pwsh'",
    args: { text: '\u001b[24~e' },
  },
  {
    key: 'shift+cmd+left',
    command: 'workbench.action.terminal.sendSequence',
    when: "terminalFocus && terminalShellType == 'pwsh'",
    args: { text: '\u001b[1;2H' },
  },
  {
    key: 'ctrl+alt+r',
    command: 'workbench.action.terminal.sendSequence',
    when: 'accessibilityModeEnabled && terminalFocus',
    args: { text: '\u0012' },
  },
  {
    key: 'ctrl+alt+g',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u0007' },
  },
  {
    key: 'alt+backspace',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u0017' },
  },
  {
    key: 'alt+delete',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u001bd' },
  },
  {
    key: 'cmd+backspace',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u0015' },
  },
  {
    key: 'cmd+left',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u0001' },
  },
  {
    key: 'cmd+right',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u0005' },
  },
  {
    key: 'ctrl+shift+2',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u0000' },
  },
  {
    key: 'ctrl+shift+6',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u001e' },
  },
  {
    key: 'ctrl+/',
    command: 'workbench.action.terminal.sendSequence',
    when: 'terminalFocus',
    args: { text: '\u001f' },
  },
  {
    key: 'cmd+.',
    command: 'workbench.action.terminal.showQuickFixes',
    when: 'terminalFocus',
  },
  {
    key: 'alt+z',
    command: 'workbench.action.terminal.sizeToContentWidth',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen || terminalFocus && terminalIsOpen && terminalProcessSupported',
  },
  {
    key: 'ctrl+shift+5',
    command: 'workbench.action.terminal.split',
    when: 'terminalFocus && terminalProcessSupported || terminalFocus && terminalWebExtensionContributedProfile',
  },
  {
    key: 'cmd+\\',
    command: 'workbench.action.terminal.split',
    when: 'terminalFocus && terminalProcessSupported || terminalFocus && terminalWebExtensionContributedProfile',
  },
  {
    key: 'ctrl+shift+5',
    command: 'workbench.action.terminal.splitActiveTab',
    when: 'terminalProcessSupported && terminalTabsFocus',
  },
  {
    key: 'cmd+\\',
    command: 'workbench.action.terminal.splitActiveTab',
    when: 'terminalProcessSupported && terminalTabsFocus',
  },
  {
    key: 'alt+cmd+c',
    command: 'workbench.action.terminal.toggleFindCaseSensitive',
    when: 'terminalFindVisible && terminalHasBeenCreated || terminalFindVisible && terminalProcessSupported',
  },
  {
    key: 'alt+cmd+r',
    command: 'workbench.action.terminal.toggleFindRegex',
    when: 'terminalFindVisible && terminalHasBeenCreated || terminalFindVisible && terminalProcessSupported',
  },
  {
    key: 'alt+cmd+w',
    command: 'workbench.action.terminal.toggleFindWholeWord',
    when: 'terminalFindVisible && terminalHasBeenCreated || terminalFindVisible && terminalProcessSupported',
  },
  {
    key: 'ctrl+`',
    command: 'workbench.action.terminal.toggleTerminal',
    when: 'terminal.active',
  },
  { key: 'alt+cmd+b', command: 'workbench.action.toggleAuxiliaryBar' },
  { key: 'alt+cmd+0', command: 'workbench.action.toggleEditorGroupLayout' },
  {
    key: 'ctrl+cmd+f',
    command: 'workbench.action.toggleFullScreen',
    when: '!isIOS',
  },
  {
    key: 'cmd+r cmd+m',
    command: 'workbench.action.toggleMaximizeEditorGroup',
    when: 'editorPartMaximizedEditorGroup || editorPartMultipleEditorGroups',
  },
  { key: 'cmd+j', command: 'workbench.action.togglePanel' },
  { key: 'cmd+b', command: 'workbench.action.toggleSidebarVisibility' },
  {
    key: 'cmd+r z',
    command: 'workbench.action.toggleZenMode',
    when: '!isAuxiliaryWindowFocusedContext',
  },
  {
    key: 'cmd+r shift+enter',
    command: 'workbench.action.unpinEditor',
    when: 'activeEditorIsPinned',
  },
  { key: 'cmd+numpad_add', command: 'workbench.action.zoomIn' },
  { key: 'shift+cmd+=', command: 'workbench.action.zoomIn' },
  { key: 'cmd+=', command: 'workbench.action.zoomIn' },
  { key: 'cmd+numpad_subtract', command: 'workbench.action.zoomOut' },
  { key: 'shift+cmd+-', command: 'workbench.action.zoomOut' },
  { key: 'cmd+-', command: 'workbench.action.zoomOut' },
  { key: 'cmd+numpad0', command: 'workbench.action.zoomReset' },
  {
    key: 'shift+cmd+m',
    command: 'workbench.actions.view.problems',
    when: 'workbench.panel.markers.view.active',
  },
  {
    key: 'escape',
    command: 'workbench.banner.focusBanner',
    when: 'bannerFocused',
  },
  {
    key: 'down',
    command: 'workbench.banner.focusNextAction',
    when: 'bannerFocused',
  },
  {
    key: 'right',
    command: 'workbench.banner.focusNextAction',
    when: 'bannerFocused',
  },
  {
    key: 'up',
    command: 'workbench.banner.focusPreviousAction',
    when: 'bannerFocused',
  },
  {
    key: 'left',
    command: 'workbench.banner.focusPreviousAction',
    when: 'bannerFocused',
  },
  {
    key: 'shift+alt+cmd+y',
    command: 'workbench.debug.action.toggleRepl',
    when: 'workbench.panel.repl.view.active',
  },
  { key: 'cmd+r c', command: 'workbench.files.action.compareWithClipboard' },
  { key: 'cmd+r d', command: 'workbench.files.action.compareWithSaved' },
  {
    key: 'cmd+r e',
    command: 'workbench.files.action.focusOpenEditorsView',
    when: 'workbench.explorer.openEditorsView.active',
  },
  {
    key: 'escape',
    command: 'workbench.statusBar.clearFocus',
    when: 'statusBarFocused',
  },
  {
    key: 'home',
    command: 'workbench.statusBar.focusFirst',
    when: 'statusBarFocused',
  },
  {
    key: 'end',
    command: 'workbench.statusBar.focusLast',
    when: 'statusBarFocused',
  },
  {
    key: 'down',
    command: 'workbench.statusBar.focusNext',
    when: 'statusBarFocused',
  },
  {
    key: 'right',
    command: 'workbench.statusBar.focusNext',
    when: 'statusBarFocused',
  },
  {
    key: 'up',
    command: 'workbench.statusBar.focusPrevious',
    when: 'statusBarFocused',
  },
  {
    key: 'left',
    command: 'workbench.statusBar.focusPrevious',
    when: 'statusBarFocused',
  },
  {
    key: 'shift+cmd+d',
    command: 'workbench.view.debug',
    when: 'viewContainer.workbench.view.debug.enabled',
  },
  {
    key: 'shift+cmd+e',
    command: 'workbench.view.explorer',
    when: 'viewContainer.workbench.view.explorer.enabled',
  },
  {
    key: 'shift+cmd+x',
    command: 'workbench.view.extensions',
    when: 'viewContainer.workbench.view.extensions.enabled',
  },
  {
    key: 'ctrl+shift+g',
    command: 'workbench.view.scm',
    when: 'workbench.scm.active',
  },
  {
    key: 'shift+cmd+f',
    command: 'workbench.view.search',
    when: 'workbench.view.search.active && neverMatch =~ /doesNotMatch/',
  },
  {
    key: 'alt+right',
    command: 'breadcrumbs.focusNextWithPicker',
    when: 'breadcrumbsActive && breadcrumbsVisible && listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'alt+left',
    command: 'breadcrumbs.focusPreviousWithPicker',
    when: 'breadcrumbsActive && breadcrumbsVisible && listFocus && !inputFocus && !treestickyScrollFocused',
  },
  {
    key: 'escape',
    command: 'breadcrumbs.selectEditor',
    when: 'breadcrumbsActive && breadcrumbsVisible',
  },
  {
    key: 'down',
    command: 'notebook.cell.nullAction',
    when: 'notebookOutputInputFocused',
  },
  {
    key: 'up',
    command: 'notebook.cell.nullAction',
    when: 'notebookOutputInputFocused',
  },
  {
    key: 'cmd+a',
    command: 'notebook.cell.output.selectAll',
    when: 'notebookEditorFocused && notebookOutputFocused',
  },
  {
    key: 'ctrl+pageup',
    command: 'notebook.focusNextEditor',
    when: 'accessibilityModeEnabled && notebookCellEditorFocused',
  },
  {
    key: 'ctrl+pageup',
    command: 'notebook.focusPreviousEditor',
    when: 'accessibilityModeEnabled && notebookCellEditorFocused',
  },
  {
    key: 'cmd+r down',
    command: 'views.moveViewDown',
    when: "focusedView != ''",
  },
  {
    key: 'cmd+r left',
    command: 'views.moveViewLeft',
    when: "focusedView != ''",
  },
  {
    key: 'cmd+r right',
    command: 'views.moveViewRight',
    when: "focusedView != ''",
  },
  { key: 'cmd+r up', command: 'views.moveViewUp', when: "focusedView != ''" },
  {
    key: 'shift+cmd+]',
    command: 'workbench.action.debug.nextConsole',
    when: 'inDebugRepl',
  },
  {
    key: 'shift+cmd+[',
    command: 'workbench.action.debug.prevConsole',
    when: 'inDebugRepl',
  },
  {
    key: 'tab',
    command: 'workbench.action.terminal.acceptSelectedSuggestion',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen && terminalSuggestWidgetVisible || terminalFocus && terminalIsOpen && terminalProcessSupported && terminalSuggestWidgetVisible',
  },
  {
    key: 'enter',
    command: 'workbench.action.terminal.acceptSelectedSuggestion',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen && terminalSuggestWidgetVisible || terminalFocus && terminalIsOpen && terminalProcessSupported && terminalSuggestWidgetVisible',
  },
  {
    key: 'cmd+k',
    command: 'workbench.action.terminal.clear',
    when: "terminalFocus && terminalHasBeenCreated && !accessibilityModeEnabled || terminalFocus && terminalProcessSupported && !accessibilityModeEnabled || accessibilityModeEnabled && accessibleViewIsShown && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal' || accessibilityModeEnabled && accessibleViewIsShown && terminalProcessSupported && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'escape',
    command: 'workbench.action.terminal.hideSuggestWidget',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen && terminalSuggestWidgetVisible || terminalFocus && terminalIsOpen && terminalProcessSupported && terminalSuggestWidgetVisible',
  },
  {
    key: 'shift+cmd+o',
    command: 'workbench.action.terminal.openDetectedLink',
    when: 'terminalFocus && terminalHasBeenCreated',
  },
  {
    key: 'shift+cmd+g',
    command: 'workbench.action.terminal.openDetectedLink',
    when: "accessibleViewIsShown && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'pagedown',
    command: 'workbench.action.terminal.selectNextPageSuggestion',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen && terminalSuggestWidgetVisible || terminalFocus && terminalIsOpen && terminalProcessSupported && terminalSuggestWidgetVisible',
  },
  {
    key: 'down',
    command: 'workbench.action.terminal.selectNextSuggestion',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen && terminalSuggestWidgetVisible || terminalFocus && terminalIsOpen && terminalProcessSupported && terminalSuggestWidgetVisible',
  },
  {
    key: 'pageup',
    command: 'workbench.action.terminal.selectPrevPageSuggestion',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen && terminalSuggestWidgetVisible || terminalFocus && terminalIsOpen && terminalProcessSupported && terminalSuggestWidgetVisible',
  },
  {
    key: 'up',
    command: 'workbench.action.terminal.selectPrevSuggestion',
    when: 'terminalFocus && terminalHasBeenCreated && terminalIsOpen && terminalSuggestWidgetVisible || terminalFocus && terminalIsOpen && terminalProcessSupported && terminalSuggestWidgetVisible',
  },
  {
    key: 'f6',
    command: 'workbench.action.debug.pause',
    when: "debugState == 'running'",
  },
  {
    key: 'alt+down',
    command: 'workbench.action.terminal.accessibleBufferGoToNextCommand',
    when: "accessibleViewIsShown && accessibleViewCurrentProviderId == 'terminal' || accessibleViewIsShown && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal' || accessibleViewIsShown && terminalProcessSupported && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'alt+up',
    command: 'workbench.action.terminal.accessibleBufferGoToPreviousCommand',
    when: "accessibleViewIsShown && terminalHasBeenCreated && accessibleViewCurrentProviderId == 'terminal' || accessibleViewIsShown && terminalProcessSupported && accessibleViewCurrentProviderId == 'terminal'",
  },
  {
    key: 'enter',
    command: 'debug.renameWatchExpression',
    when: 'watchExpressionsFocused',
  },
  { key: 'enter', command: 'debug.setVariable', when: 'variablesFocused' },
  {
    key: 'space',
    command: 'debug.toggleBreakpoint',
    when: 'breakpointsFocused && !inputFocus',
  },
  {
    key: 'escape',
    command: 'notebook.cell.quitEdit',
    when: 'notebookEditorFocused && notebookOutputFocused',
  },
  {
    key: 'cmd+v',
    command: 'filesExplorer.paste',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceReadonly && !inputFocus',
  },
  {
    key: 'alt+cmd+backspace',
    command: 'deleteFile',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceReadonly && !inputFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'deleteFile',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceMoveableToTrash && !explorerResourceReadonly && !inputFocus',
  },
  {
    key: 'shift+cmd+.',
    command: 'editor.action.accessibleViewGoToSymbol',
    when: 'accessibilityHelpIsShown && accessibleViewGoToSymbolSupported || accessibleViewGoToSymbolSupported && accessibleViewIsShown',
  },
  {
    key: 'shift+cmd+o',
    command: 'editor.action.accessibleViewGoToSymbol',
    when: 'accessibilityHelpIsShown && accessibleViewGoToSymbolSupported || accessibleViewGoToSymbolSupported && accessibleViewIsShown',
  },
  {
    key: 'cmd+e',
    command: 'editor.action.toggleScreenReaderAccessibilityMode',
    when: 'accessibilityHelpIsShown',
  },
  {
    key: 'shift+alt+f1',
    command: 'editor.action.toggleScreenReaderAccessibilityMode',
  },
  {
    key: 'escape',
    command: 'editor.closeCallHierarchy',
    when: 'callHierarchyVisible && !config.editor.stablePeek',
  },
  {
    key: 'escape',
    command: 'editor.closeTypeHierarchy',
    when: 'typeHierarchyVisible && !config.editor.stablePeek',
  },
  {
    key: 'cmd+down',
    command: 'explorer.openAndPassFocus',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceIsFolder && !inputFocus',
  },
  {
    key: 'escape',
    command: 'filesExplorer.cancelCut',
    when: 'explorerResourceCut && filesExplorerFocus && foldersViewVisible && !inputFocus',
  },
  {
    key: 'cmd+c',
    command: 'filesExplorer.copy',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceIsRoot && !inputFocus',
  },
  {
    key: 'cmd+x',
    command: 'filesExplorer.cut',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceIsRoot && !explorerResourceReadonly && !inputFocus',
  },
  {
    key: 'space',
    command: 'filesExplorer.openFilePreserveFocus',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceIsFolder && !inputFocus',
  },
  {
    key: 'home',
    command: 'firstCompressedFolder',
    when: 'explorerViewletCompressedFocus && filesExplorerFocus && foldersViewVisible && !explorerViewletCompressedFirstFocus && !inputFocus',
  },
  {
    key: 'cmd+enter',
    command: 'inlineChat.acceptChanges',
    when: "inlineChatHasProvider && inlineChatVisible && !inlineChatDocumentChanged || inlineChatHasProvider && inlineChatVisible && config.inlineChat.mode != 'preview'",
  },
  {
    key: 'end',
    command: 'lastCompressedFolder',
    when: 'explorerViewletCompressedFocus && filesExplorerFocus && foldersViewVisible && !explorerViewletCompressedLastFocus && !inputFocus',
  },
  {
    key: 'delete',
    command: 'moveFileToTrash',
    when: 'explorerResourceMoveableToTrash && filesExplorerFocus && foldersViewVisible && !explorerResourceReadonly && !inputFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'moveFileToTrash',
    when: 'explorerResourceMoveableToTrash && filesExplorerFocus && foldersViewVisible && !explorerResourceReadonly && !inputFocus',
  },
  {
    key: 'right',
    command: 'nextCompressedFolder',
    when: 'explorerViewletCompressedFocus && filesExplorerFocus && foldersViewVisible && !explorerViewletCompressedLastFocus && !inputFocus',
  },
  {
    key: 'left',
    command: 'previousCompressedFolder',
    when: 'explorerViewletCompressedFocus && filesExplorerFocus && foldersViewVisible && !explorerViewletCompressedFirstFocus && !inputFocus',
  },
  {
    key: 'delete',
    command: 'remote.tunnel.closeInline',
    when: 'tunnelCloseable && tunnelViewFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'remote.tunnel.closeInline',
    when: 'tunnelCloseable && tunnelViewFocus',
  },
  {
    key: 'cmd+c',
    command: 'remote.tunnel.copyAddressInline',
    when: "tunnelViewFocus && tunnelType == 'Detected' && tunnelViewMultiSelection == 'undefined' || tunnelViewFocus && tunnelType == 'Forwarded' && tunnelViewMultiSelection == 'undefined'",
  },
  {
    key: 'enter',
    command: 'remote.tunnel.label',
    when: "tunnelViewFocus && tunnelType == 'Forwarded' && tunnelViewMultiSelection == 'undefined'",
  },
  {
    key: 'enter',
    command: 'renameFile',
    when: 'filesExplorerFocus && foldersViewVisible && !explorerResourceIsRoot && !explorerResourceReadonly && !inputFocus',
  },
  {
    key: 'f5',
    command: 'workbench.action.debug.continue',
    when: "debugState == 'stopped'",
  },
  {
    key: 'f11',
    command: 'workbench.action.debug.stepInto',
    when: "debugState != 'inactive'",
  },
  {
    key: 'shift+escape',
    command: 'closeReferenceSearch',
    when: 'editorTextFocus && referenceSearchVisible && !config.editor.stablePeek || referenceSearchVisible && !config.editor.stablePeek && !inputFocus',
  },
  {
    key: 'escape',
    command: 'closeReferenceSearch',
    when: 'editorTextFocus && referenceSearchVisible && !config.editor.stablePeek || referenceSearchVisible && !config.editor.stablePeek && !inputFocus',
  },
  {
    key: 'cmd+up',
    command: 'list.stickyScroll.collapse',
    when: 'treestickyScrollFocused',
  },
  {
    key: 'left',
    command: 'list.stickyScroll.collapse',
    when: 'treestickyScrollFocused',
  },
  {
    key: 'cmd+down',
    command: 'list.stickyScrollselect',
    when: 'treestickyScrollFocused',
  },
  {
    key: 'enter',
    command: 'list.stickyScrollselect',
    when: 'treestickyScrollFocused',
  },
  {
    key: 'space',
    command: 'list.stickyScrolltoggleExpand',
    when: 'treestickyScrollFocused',
  },
  {
    key: 'escape',
    command: 'notifications.hideList',
    when: 'notificationCenterVisible',
  },
  {
    key: 'ctrl+alt+cmd+right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+cmd+right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+cmd+right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'cmd+right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+alt+right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'alt+right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'right',
    command: 'quickInput.acceptInBackground',
    when: "cursorAtEndOfQuickInputBox && inQuickInput && quickInputType == 'quickPick' || inQuickInput && !inputFocus && quickInputType == 'quickPick'",
  },
  {
    key: 'ctrl+-',
    command: 'workbench.action.quickInputBack',
    when: 'inQuickOpen',
  },
  {
    key: 'ctrl+tab',
    command: 'workbench.action.quickOpenNavigateNextInEditorPicker',
    when: 'inEditorsPicker && inQuickOpen',
  },
  {
    key: 'cmd+p',
    command: 'workbench.action.quickOpenNavigateNextInFilePicker',
    when: 'inFilesPicker && inQuickOpen',
  },
  {
    key: 'ctrl+r',
    command: 'workbench.action.quickOpenNavigateNextInRecentFilesPicker',
    when: 'inQuickOpen && inRecentFilesPicker',
  },
  {
    key: 'ctrl+q',
    command: 'workbench.action.quickOpenNavigateNextInViewPicker',
    when: 'inQuickOpen && inViewsPicker',
  },
  {
    key: 'ctrl+shift+tab',
    command: 'workbench.action.quickOpenNavigatePreviousInEditorPicker',
    when: 'inEditorsPicker && inQuickOpen',
  },
  {
    key: 'shift+cmd+p',
    command: 'workbench.action.quickOpenNavigatePreviousInFilePicker',
    when: 'inFilesPicker && inQuickOpen',
  },
  {
    key: 'ctrl+shift+r',
    command: 'workbench.action.quickOpenNavigatePreviousInRecentFilesPicker',
    when: 'inQuickOpen && inRecentFilesPicker',
  },
  {
    key: 'ctrl+shift+q',
    command: 'workbench.action.quickOpenNavigatePreviousInViewPicker',
    when: 'inQuickOpen && inViewsPicker',
  },
  {
    key: 'ctrl+n',
    command: 'workbench.action.quickOpenSelectNext',
    when: 'inQuickOpen',
  },
  {
    key: 'ctrl+p',
    command: 'workbench.action.quickOpenSelectPrevious',
    when: 'inQuickOpen',
  },
  {
    key: 'shift+cmd+r',
    command: 'workbench.action.reloadWindow',
    when: 'isDevelopment',
  },
  {
    key: 'shift+cmd+f',
    command: 'workbench.action.terminal.searchWorkspace',
    when: 'terminalFocus && terminalProcessSupported && terminalTextSelected',
  },
  {
    key: 'alt+cmd+i',
    command: 'workbench.action.toggleDevTools',
    when: 'isDevelopment',
  },
  {
    key: 'escape',
    command: 'notifications.hideToasts',
    when: 'notificationFocus && notificationToastsVisible',
  },
  {
    key: 'escape',
    command: 'workbench.action.chat.stopListening',
    when: "voiceChatInProgress && scopedVoiceChatInProgress == 'editor' || voiceChatInProgress && scopedVoiceChatInProgress == 'inline' || voiceChatInProgress && scopedVoiceChatInProgress == 'quick' || voiceChatInProgress && scopedVoiceChatInProgress == 'terminal' || voiceChatInProgress && scopedVoiceChatInProgress == 'view'",
  },
  {
    key: 'escape',
    command: 'workbench.action.chat.stopReadChatItemAloud',
    when: 'scopedChatSynthesisInProgress',
  },
  {
    key: 'escape',
    command: 'workbench.action.editorDictation.stop',
    when: 'editorDictation.inProgress',
  },
  {
    key: 'escape',
    command: 'workbench.action.speech.stopReadAloud',
    when: 'scopedChatSynthesisInProgress && textToSpeechInProgress',
  },
  {
    key: 'f10',
    command: 'extension.node-debug.startWithStopOnEntry',
    when: "!inDebugMode && debugConfigurationType == 'node' || !inDebugMode && debugConfigurationType == 'pwa-extensionHost' || !inDebugMode && debugConfigurationType == 'pwa-node'",
  },
  {
    key: 'cmd+k alt+cmd+s',
    command: 'git.stageSelectedRanges',
    when: 'isInDiffEditor && !operationInProgress',
  },
  {
    key: 'shift+cmd+v',
    command: 'markdown.showPreview',
    when: "!notebookEditorFocused && editorLangId == 'markdown'",
  },
  {
    key: 'f',
    command: 'notebook.find',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'shift+alt+f12',
    command: 'references-view.findReferences',
    when: 'editorHasReferenceProvider',
  },
  {
    key: 'f11',
    command: 'extension.node-debug.startWithStopOnEntry',
    when: "!inDebugMode && activeViewlet == 'workbench.view.debug' && debugConfigurationType == 'node' || !inDebugMode && activeViewlet == 'workbench.view.debug' && debugConfigurationType == 'pwa-extensionHost' || !inDebugMode && activeViewlet == 'workbench.view.debug' && debugConfigurationType == 'pwa-node'",
  },
  {
    key: 'cmd+k cmd+n',
    command: 'git.unstageSelectedRanges',
    when: 'isInDiffEditor && !operationInProgress',
  },
  {
    key: 'k',
    command: 'list.focusUp',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'cmd+k v',
    command: 'markdown.showPreviewToSide',
    when: "!notebookEditorFocused && editorLangId == 'markdown'",
  },
  {
    key: 'f4',
    command: 'references-view.next',
    when: 'reference-list.hasResult && references-view.canNavigate',
  },
  {
    key: 'cmd+k cmd+r',
    command: 'git.revertSelectedRanges',
    when: 'isInDiffEditor && !operationInProgress',
  },
  {
    key: 'j',
    command: 'list.focusDown',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'shift+f4',
    command: 'references-view.prev',
    when: 'reference-list.hasResult && references-view.canNavigate',
  },
  {
    key: 'a',
    command: 'notebook.cell.insertCodeCellAboveAndFocusContainer',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'shift+alt+h',
    command: 'references-view.showCallHierarchy',
    when: 'editorHasCallHierarchyProvider',
  },
  {
    key: 'b',
    command: 'notebook.cell.insertCodeCellBelowAndFocusContainer',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'd d',
    command: 'notebook.cell.delete',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'z',
    command: 'undo',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 's',
    command: 'workbench.action.files.save',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'c',
    command: 'notebook.cell.copy',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'x',
    command: 'notebook.cell.cut',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'v',
    command: 'notebook.cell.paste',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'shift+l',
    command: 'notebook.toggleLineNumbers',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'o',
    command: 'notebook.cell.toggleOutputs',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'l',
    command: 'notebook.cell.toggleLineNumbers',
    when: 'notebookEditorFocused && !inputFocus && !notebookOutputFocused',
  },
  {
    key: 'ctrl+shift+-',
    command: 'notebook.cell.split',
    when: 'editorTextFocus && inputFocus && notebookEditorFocused && !notebookOutputFocused',
  },
  {
    key: 'ctrl+enter',
    command: 'notebook.cell.executeAndFocusContainer',
    when: 'notebookCellListFocused || editorTextFocus && inputFocus && notebookEditorFocused',
  },
  {
    key: 'shift+enter',
    command: 'notebook.cell.executeAndSelectBelow',
    when: "notebookCellListFocused && notebookCellType == 'code' || editorTextFocus && inputFocus && notebookEditorFocused",
  },
  {
    key: 'shift+cmd+j',
    command: 'aiSettings.action.open',
    when: '!isSettingsPaneOpen',
  },
  { key: 'shift+cmd+0', command: 'aiSettings.usingOpenAIKey.toggle' },
  {
    key: 'ctrl+enter',
    command: 'github.copilot.generate',
    when: 'editorTextFocus && github.copilot.activated && !commentEditorFocused && !inInteractiveInput && !interactiveEditorFocused',
  },
  {
    key: 'shift+alt+u',
    command: 'java.projectConfiguration.update',
    when: 'editorFocus',
  },
  {
    key: 'alt+cmd+r',
    command: 'java.view.package.revealFileInOS',
    when: "focusedView == 'javaProjectExplorer' && java:serverMode == 'Standard'",
  },
  { key: 'shift+f1', command: 'java.welcome' },
  {
    key: 'shift+enter',
    command: 'jupyter.execSelectionInteractive',
    when: "editorTextFocus && isWorkspaceTrusted && jupyter.ownsSelection && !findInputFocussed && !notebookEditorFocused && !replaceInputFocussed && editorLangId == 'python' && activeEditor != 'workbench.editor.interactive'",
  },
  {
    key: 'shift+enter',
    command: 'python.execSelectionInTerminal',
    when: "editorTextFocus && !findInputFocussed && !jupyter.ownsSelection && !notebookEditorFocused && !replaceInputFocussed && editorLangId == 'python' && activeEditor != 'workbench.editor.interactive'",
  },
  {
    key: 'cmd+k q',
    command: 'quokka.makeQuokkaFromExistingFile',
    when: '!quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'shift+cmd+r',
    command: 'reactSnippets.search',
    when: 'editorTextFocus',
  },
  {
    key: 'ctrl+enter',
    command: 'workbench.action.chat.insertCodeBlock',
    when: 'accessibleViewInCodeBlock && chatIsEnabled || chatIsEnabled && inChat && !inChatInput',
  },
  {
    key: 'cmd+i',
    command: 'workbench.action.terminal.chat.start',
    when: 'terminalChatAgentRegistered && terminalFocusInAny && terminalHasBeenCreated || terminalChatAgentRegistered && terminalFocusInAny && terminalProcessSupported',
  },
  {
    key: 'ctrl+/',
    command: 'github.copilot.acceptCursorPanelSolution',
    when: "github.copilot.activated && github.copilot.panelVisible && activeWebviewPanelId == 'GitHub Copilot Suggestions'",
  },
  {
    key: 'alt+cmd+c',
    command: 'java.view.package.copyFilePath',
    when: "focusedView == 'javaProjectExplorer' && java:serverMode == 'Standard'",
  },
  { key: 'shift+alt+b', command: 'java.workspace.compile' },
  {
    key: 'shift+enter',
    command: 'jupyter.runcurrentcelladvance',
    when: 'editorTextFocus && isWorkspaceTrusted && jupyter.hascodecells && !editorHasSelection && !notebookEditorFocused',
  },
  {
    key: 'shift+enter',
    command: 'python.execInREPL',
    when: "config.python.REPL.sendToNativeREPL && editorTextFocus && !accessibilityModeEnabled && !jupyter.ownsSelection && !notebookEditorFocused && editorLangId == 'python' && activeEditor != 'workbench.editor.interactive'",
  },
  {
    key: 'cmd+k j',
    command: 'quokka.createJavaScriptFile',
    when: '!quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'alt+[',
    command: 'github.copilot.previousPanelSolution',
    when: "github.copilot.activated && github.copilot.panelVisible && activeWebviewPanelId == 'GitHub Copilot Suggestions'",
  },
  {
    key: 'shift+cmd+v',
    command: 'java.action.clipboardPasteAction',
    when: "javaLSReady && editorLangId == 'java'",
  },
  {
    key: 'shift+alt+cmd+c',
    command: 'java.view.package.copyRelativeFilePath',
    when: "focusedView == 'javaProjectExplorer' && java:serverMode == 'Standard'",
  },
  {
    key: 'ctrl+enter',
    command: 'jupyter.runcurrentcell',
    when: 'editorTextFocus && isWorkspaceTrusted && jupyter.hascodecells && !editorHasSelection && !jupyter.havenativecells && !notebookEditorFocused',
  },
  {
    key: 'enter',
    command: 'python.execInREPLEnter',
    when: "!config.interactiveWindow.executeWithShiftEnter && activeEditor == 'workbench.editor.interactive'",
  },
  {
    key: 'cmd+k t',
    command: 'quokka.createTypeScriptFile',
    when: '!quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'alt+]',
    command: 'github.copilot.nextPanelSolution',
    when: "github.copilot.activated && github.copilot.panelVisible && activeWebviewPanelId == 'GitHub Copilot Suggestions'",
  },
  {
    key: 'shift+cmd+v',
    command: 'java.action.filesExplorerPasteAction',
    when: 'config.editor.pasteAs.enabled && explorerViewletFocus',
  },
  {
    key: 'enter',
    command: 'java.view.package.renameFile',
    when: "focusedView == 'javaProjectExplorer' && java:serverMode == 'Standard'",
  },
  {
    key: 'alt+enter',
    command: 'jupyter.runcurrentcellandaddbelow',
    when: 'editorTextFocus && jupyter.hascodecells && !editorHasSelection && !notebookEditorFocused',
  },
  {
    key: 'cmd+r',
    command: 'python.refreshTensorBoard',
    when: 'python.hasActiveTensorBoardSession',
  },
  {
    key: 'cmd+k l',
    command: 'quokka.createFile',
    when: '!quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'alt+\\',
    command: 'editor.action.inlineSuggest.trigger',
    when: 'config.github.copilot.inlineSuggest.enable && editorTextFocus && !editorHasSelection && !inlineSuggestionsVisible',
  },
  {
    key: 'escape',
    command: 'interactive.input.clear',
    when: "!LinkedEditingInputVisible && !accessibilityHelpWidgetVisible && !breakpointWidgetVisible && !editorHasMultipleSelections && !editorHasSelection && !editorHoverVisible && !exceptionWidgetVisible && !findWidgetVisible && !inSnippetMode && !isComposing && !markersNavigationVisible && !notificationToastsVisible && !parameterHintsVisible && !renameInputVisible && !selectionAnchorSet && !suggestWidgetVisible && activeEditor == 'workbench.editor.interactive'",
  },
  {
    key: 'ctrl+shift+alt+s',
    command: 'java.action.doCleanup',
    when: "javaLSReady && editorLangId == 'java'",
  },
  {
    key: 'cmd+backspace',
    command: 'java.view.package.moveFileToTrash',
    when: "explorerResourceMoveableToTrash && focusedView == 'javaProjectExplorer' && java:serverMode == 'Standard'",
  },
  {
    key: 'cmd+k s',
    command: 'quokka.stopCurrent',
    when: 'quokka.hasActiveSession && !quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'java.view.package.deleteFilePermanently',
    when: "!explorerResourceMoveableToTrash && focusedView == 'javaProjectExplorer' && java:serverMode == 'Standard'",
  },
  {
    key: 'ctrl+; s',
    command: 'jupyter.insertCellBelowPosition',
    when: 'editorTextFocus && isWorkspaceTrusted && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'cmd+k v',
    command: 'quokka.showValue',
    when: 'quokka.hasActiveSession && quokka.isActiveEditorRunningQuokka && !quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'ctrl+; b',
    command: 'jupyter.insertCellBelow',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'ctrl+; a',
    command: 'jupyter.insertCellAbove',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'ctrl+; x',
    command: 'jupyter.deleteCells',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'cmd+k x',
    command: 'quokka.copyValue',
    when: 'quokka.hasActiveSession && quokka.isActiveEditorRunningQuokka && !quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'cmd+backspace',
    command: 'aichat.cancelchat',
    when: "activeAIChatEditor || view == 'workbench.panel.aichat.view' && (arbitrary function)",
  },
  { key: 'shift+cmd+l', command: 'aichat.insertselectionintochat' },
  { key: 'shift+cmd+y', command: 'aichat.insertselectionintofollowup' },
  { key: 'cmd+y', command: 'aichat.newfollowupaction' },
  {
    key: 'ctrl+shift+tab',
    command: 'aichat.selectNextTab',
    when: "view == 'workbench.panel.aichat.view'",
  },
  {
    key: 'ctrl+tab',
    command: 'aichat.selectPreviousTab',
    when: "view == 'workbench.panel.aichat.view'",
  },
  { key: 'alt+d', command: 'aidebug.autodebugterminal', when: 'terminalFocus' },
  {
    key: 'cmd+.',
    command: 'cursor.aichat.toggleChatMode',
    when: '(arbitrary function) && (arbitrary function)',
  },
  {
    key: 'cmd+enter',
    command: 'cursorai.action.executeInterpreterAction',
    when: '(arbitrary function)',
  },
  {
    key: 'cmd+u',
    command: 'cursorai.action.retryInterpreterAction',
    when: "activeAIChatEditor || view == 'workbench.panel.aichat.view' || (arbitrary function) && (arbitrary function)",
  },
  {
    key: 'ctrl+shift+alt+[',
    command: 'jupyter.extendSelectionByCellAbove',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'cmd+k i',
    command: 'quokka.installMissingPackageToQuokka',
    when: 'quokka.hasActiveSession && !quokka.isLiveShareClient && !terminalFocus',
  },
  {
    key: 'ctrl+shift+alt+]',
    command: 'jupyter.extendSelectionByCellBelow',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'escape',
    command: 'quokka.clearValue',
    when: "editorTextFocus && quokka.isActiveEditorRunningQuokka && quokka.lineHasRemovableInlineValues && !findWidgetVisible && !inReferenceSearchEditor && !parameterHintsVisible && !quickFixWidgetVisible && !referenceSearchVisible && !renameInputVisible && !suggestWidgetVisible && !vim.active || editorTextFocus && quokka.isActiveEditorRunningQuokka && quokka.lineHasRemovableInlineValues && !findWidgetVisible && !inReferenceSearchEditor && !parameterHintsVisible && !quickFixWidgetVisible && !referenceSearchVisible && !renameInputVisible && !suggestWidgetVisible && vim.mode == 'Normal'",
  },
  {
    key: 'ctrl+; u',
    command: 'jupyter.moveCellsUp',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'escape escape',
    command: 'quokka.clearFileValues',
    when: "editorTextFocus && quokka.fileHasRemovableInlineValues && quokka.isActiveEditorRunningQuokka && !editorHasSelection && !findWidgetVisible && !inReferenceSearchEditor && !parameterHintsVisible && !quickFixWidgetVisible && !quokka.lineHasRemovableInlineValues && !referenceSearchVisible && !renameInputVisible && !suggestWidgetVisible && !vim.active || editorTextFocus && quokka.fileHasRemovableInlineValues && quokka.isActiveEditorRunningQuokka && !editorHasSelection && !findWidgetVisible && !inReferenceSearchEditor && !parameterHintsVisible && !quickFixWidgetVisible && !quokka.lineHasRemovableInlineValues && !referenceSearchVisible && !renameInputVisible && !suggestWidgetVisible && vim.mode == 'Normal'",
  },
  {
    key: 'ctrl+; d',
    command: 'jupyter.moveCellsDown',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'shift+f5',
    command: 'quokka.stopTraceNavigation',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+; m',
    command: 'jupyter.changeCellToMarkdown',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'shift+f5',
    command: 'quokka.debug',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && !inDebugMode && !quokka.isLiveShareClient && !quokka.traceBeingNavigated || activeEditor && !inDebugMode && !quokka.isLiveShareClient && !quokka.traceBeingNavigated && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+; c',
    command: 'jupyter.changeCellToCode',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'shift+alt+f5',
    command: 'quokka.debugAutoPlay',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && !inDebugMode && !quokka.isLiveShareClient && !quokka.traceBeingNavigated || activeEditor && !inDebugMode && !quokka.isLiveShareClient && !quokka.traceBeingNavigated && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+alt+]',
    command: 'jupyter.gotoNextCellInFile',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'f11',
    command: 'quokka.playTraceNextStep',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+alt+[',
    command: 'jupyter.gotoPrevCellInFile',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'f5',
    command: 'quokka.playTraceForwardToSelection',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+alt+\\',
    command: 'jupyter.selectCellContents',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'f8',
    command: 'quokka.playTraceForwardToBreakpoint',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+shift+alt+\\',
    command: 'jupyter.selectCell',
    when: 'editorTextFocus && jupyter.hascodecells && !jupyter.webExtension && !notebookEditorFocused',
  },
  {
    key: 'f10',
    command: 'quokka.playTraceNextStepOver',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'cmd+r',
    command: 'jupyter.refreshDataViewer',
    when: 'isWorkspaceTrusted && jupyter.dataViewerActive',
  },
  {
    key: 'shift+f11',
    command: 'quokka.playTraceNextStepOut',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+shift+enter',
    command: 'jupyter.runAndDebugCell',
    when: "isWorkspaceTrusted && jupyter.ispythonnotebook && !notebookKernel && notebookCellType == 'code' || isWorkspaceTrusted && jupyter.ispythonnotebook && notebookCellType == 'code' && notebookKernel =~ /^ms-toolsai.jupyter\\// && resource not in 'jupyter.notebookeditor.debugDocuments'",
  },
  {
    key: 'ctrl+f11',
    command: 'quokka.playTracePrevStep',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'f10',
    command: 'jupyter.runByLine',
    when: "isWorkspaceTrusted && jupyter.ispythonnotebook && !notebookKernel && notebookCellType == 'code' || isWorkspaceTrusted && jupyter.ispythonnotebook && notebookCellType == 'code' && notebookKernel =~ /^ms-toolsai.jupyter\\// && resource not in 'jupyter.notebookeditor.debugDocuments'",
  },
  {
    key: 'ctrl+f5',
    command: 'quokka.playTraceBackwardToSelection',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'f10',
    command: 'jupyter.runByLineNext',
    when: "notebookCellResource in 'jupyter.notebookeditor.runByLineCells'",
  },
  {
    key: 'ctrl+f8',
    command: 'quokka.playTraceBackwardToBreakpoint',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+enter',
    command: 'jupyter.runByLineStop',
    when: "notebookCellResource in 'jupyter.notebookeditor.runByLineCells'",
  },
  {
    key: 'ctrl+f10',
    command: 'quokka.playTracePrevStepOver',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  {
    key: 'ctrl+shift+f11',
    command: 'quokka.playTracePrevStepOut',
    when: "activeEditor && quokka.isActiveEditorRunningQuokka && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient || activeEditor && quokka.traceBeingNavigated && !inDebugMode && !quokka.isLiveShareClient && resourceScheme == 'quokka-code-timeline'",
  },
  { key: 'cmd+l', command: 'aichat.newchataction' },
  { key: 'alt+cmd+l', command: 'aichat.showchatdropdown' },
  {
    key: 'cmd+enter',
    command: 'editor.action.inlineDiffs.acceptAll',
    when: 'editorTextFocus && (arbitrary function)',
  },
  {
    key: 'cmd+pausebreak',
    command: 'editor.action.inlineDiffs.acceptAllAcrossAllEditors',
    when: 'editorHasPromptBar && editorTextFocus || editorTextFocus && hasDisplayedDiff',
  },
  {
    key: 'cmd+y',
    command: 'editor.action.inlineDiffs.acceptPartialEdit',
    when: 'editorTextFocus && (arbitrary function)',
  },
  {
    key: 'cmd+backspace',
    command: 'editor.action.inlineDiffs.rejectAll',
    when: 'editorTextFocus && (arbitrary function)',
  },
  {
    key: 'cmd+ctrl',
    command: 'editor.action.inlineDiffs.rejectAllAcrossAllEditors',
    when: 'editorHasPromptBar && editorTextFocus || editorTextFocus && hasDisplayedDiff',
  },
  {
    key: 'cmd+ctrl',
    command: 'editor.action.inlineDiffs.rejectAllAcrossAllEditors',
    when: 'editorHasPromptBar && editorTextFocus || editorTextFocus && hasDisplayedDiff',
  },
  {
    key: 'cmd+n',
    command: 'editor.action.inlineDiffs.rejectPartialEdit',
    when: 'editorTextFocus && (arbitrary function)',
  },
  {
    key: 'cmd+i',
    command: 'composer.startComposerPrompt',
    when: 'composerIsEnabled',
  },
  {
    key: 'cmd+backspace',
    command: 'editor.action.inlineDiffs.cancelEdits',
    when: 'editorTextFocus && hasActivelyGeneratingDiff',
  },
  {
    key: 'cmd+.',
    command: 'acceptSelectedCodeAction',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'enter',
    command: 'acceptSelectedCodeAction',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'escape',
    command: 'editor.action.cancelGPT4WithCmdDelete',
    when: 'hadGPT4InlineCompletionRunning',
  },
  {
    key: 'cmd+backspace',
    command: 'editor.action.cancelGPT4WithCmdDelete',
    when: 'hadGPT4InlineCompletionRunning',
  },
  {
    key: 'cmd+n',
    command: 'editor.action.rejectGPT4',
    when: 'editorTextFocus && hasGPT4Diff',
  },
  {
    key: 'cmd+g',
    command: 'editor.action.simpleInlineDiffs.acceptAll',
    when: 'editorTextFocus && hasDisplayedSimpleDiff',
  },
  {
    key: 'cmd+h',
    command: 'editor.action.simpleInlineDiffs.rejectAll',
    when: 'editorTextFocus && hasDisplayedSimpleDiff',
  },
  {
    key: 'shift+escape',
    command: 'hideCodeActionWidget',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'escape',
    command: 'hideCodeActionWidget',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'cmd+enter',
    command: 'previewSelectedCodeAction',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'ctrl+n',
    command: 'selectNextCodeAction',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'cmd+down',
    command: 'selectNextCodeAction',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'down',
    command: 'selectNextCodeAction',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'ctrl+p',
    command: 'selectPrevCodeAction',
    when: 'codeActionMenuVisible',
  },
  {
    key: 'cmd+up',
    command: 'selectPrevCodeAction',
    when: 'codeActionMenuVisible',
  },
  { key: 'up', command: 'selectPrevCodeAction', when: 'codeActionMenuVisible' },
  {
    key: 'shift+cmd+k',
    command: 'aipopup.action.modal.generate',
    when: 'editorFocus && !composerBarIsVisible && !composerControlPanelIsVisible',
    args: { invocationType: 'toggle' },
  },
  {
    key: 'cmd+k',
    command: 'aipopup.action.modal.generate',
    when: 'editorFocus && !composerBarIsVisible && !composerControlPanelIsVisible',
    args: { invocationType: 'new' },
  },
  { key: 'shift+cmd+i', command: 'controlPanel.openControlPanel' },
  {
    key: 'cmd+backspace',
    command: 'editor.action.inlineDiffs.cancelPromptBar',
    when: 'editorTextFocus && hasActivelyGeneratingPromptBarDiff',
  },
  {
    key: 'escape',
    command: 'aipopup.action.closePromptBar',
    when: 'editorHasPromptBar && editorPromptBarFocused && editorTextFocus',
  },
  {
    key: 'escape',
    command: 'diffEditor.exitCompareMove',
    when: 'comparingMovedCode',
  },
  {
    key: 'cmd+enter',
    command: 'cursorai.action.acceptGenerateInTerminal',
    when: 'terminalFocus && terminalHasBeenCreated || terminalProcessSupported && (arbitrary function)',
  },
  {
    key: 'cmd+backspace',
    command: 'cursorai.action.cancelGenerateInTerminal',
    when: 'terminalFocus && terminalHasBeenCreated || terminalProcessSupported && (arbitrary function)',
  },
  {
    key: 'cmd+k',
    command: 'cursorai.action.generateInTerminal',
    when: 'terminalFocus && terminalHasBeenCreated || terminalFocus && terminalProcessSupported',
  },
  {
    key: 'escape',
    command: 'cursorai.action.hideGenerateInTerminal',
    when: 'terminalFocus && terminalHasBeenCreated || terminalProcessSupported && (arbitrary function)',
  },
  {
    key: 'cmd+enter',
    command: 'cursorai.action.acceptAndRunGenerateInTerminal',
    when: 'terminalFocus && terminalHasBeenCreated || terminalProcessSupported && (arbitrary function)',
  },
  {
    key: 'cmd+backspace',
    command: 'cursorai.action.rejectGenerateInTerminal',
    when: 'terminalFocus && terminalHasBeenCreated || terminalProcessSupported && (arbitrary function)',
  },
  {
    key: 'shift+cmd+e',
    command: 'aichat.fixerrormessage',
    when: '(arbitrary function)',
  },
  {
    key: 'cmd+space',
    command: 'editor.action.inlineEdits.accept',
    when: 'inlineEditsVisible',
  },
];

export const vimShortcuts = [
  // Beginner Text Navigation
  { key: 'h', command: 'move cursor left' },
  { key: 'j', command: 'move cursor down' },
  { key: 'k', command: 'move cursor up' },
  { key: 'l', command: 'move cursor right' },
  { key: 'w', command: 'move to start of next word' },
  { key: 'b', command: 'move to start of previous word' },
  { key: '0', command: 'move to beginning of line' },
  { key: '$', command: 'move to end of line' },
  { key: 'gg', command: 'move to top of document' },
  { key: 'G', command: 'move to bottom of document' },
  { key: '^', command: 'move to first non-blank character' },
  { key: '%', command: 'move to matching parenthesis' },
  { key: 'ctrl+o', command: 'jump to older cursor position' },

  // Beginner Text Editing
  { key: 'i', command: 'insert mode at cursor' },
  { key: 'I', command: 'insert mode at beginning of line' },
  { key: 'a', command: 'append after cursor' },
  { key: 'A', command: 'append at end of line' },
  { key: 'o', command: 'open new line below' },
  { key: 'O', command: 'open new line above' },
  { key: 'dd', command: 'delete line' },
  { key: 'yy', command: 'yank (copy) line' },
  { key: 'p', command: 'paste after cursor' },
  { key: 'P', command: 'paste before cursor' },

  // Intermediate Text Navigation I
  { key: 'e', command: 'move to end of word' },
  { key: 'ge', command: 'move to end of previous word' },
  { key: ')', command: 'move to next sentence' },
  { key: '(', command: 'move to previous sentence' },
  { key: '}', command: 'move to next paragraph' },
  { key: '{', command: 'move to previous paragraph' },
  { key: 'H', command: 'move to top of screen' },
  { key: 'L', command: 'move to bottom of screen' },

  // Intermediate Text Navigation II
  { key: 'ctrl+u', command: 'scroll up half screen' },
  { key: 'ctrl+d', command: 'scroll down half screen' },
  { key: 'ctrl+b', command: 'scroll up full screen' },
  { key: 'ctrl+f', command: 'scroll down full screen' },
  { key: 'zt', command: 'move line to top of screen' },
  { key: 'zz', command: 'center current line' },
  { key: 'zb', command: 'move line to bottom of screen' },
  { key: '`<mark>`', command: 'jump to mark' },

  // Intermediate Text Editing I
  { key: 'x', command: 'delete character under cursor' },
  { key: 'X', command: 'delete character before cursor' },
  { key: 'r<character>', command: 'replace character' },
  { key: 's', command: 'substitute character' },
  { key: 'cw', command: 'change word' },
  { key: 'c$', command: 'change to end of line' },
  { key: 'ci(', command: 'change inside parentheses' },
  { key: 'ci"', command: 'change inside quotes' },

  // Intermediate Text Editing II
  { key: 'u', command: 'undo last action' },
  { key: 'ctrl+r', command: 'redo last undone action' },
  { key: '>>', command: 'indent line' },
  { key: '<<', command: 'unindent line' },
  { key: '=', command: 'auto-indent selection' },
  { key: 'cc', command: 'change line' },
  { key: 'daw', command: 'delete a word' },
  { key: 'di(', command: 'delete inside parentheses' },

  // Advanced Text Navigation
  { key: 'ctrl+g', command: 'display file info' },
  { key: '``', command: 'return to last jump location' },
  { key: "'.", command: 'jump to last edit location' },
  { key: '[[', command: 'go to previous section' },
  { key: ']]', command: 'go to next section' },
  { key: ':tabnext', command: 'move to next tab' },

  // Visual Mode
  { key: 'v', command: 'start visual mode' },
  { key: 'V', command: 'start line visual mode' },
  { key: 'ctrl+v', command: 'start block visual mode' },
  { key: '>', command: 'indent selection' },
  { key: '<', command: 'unindent selection' },
  { key: 'y', command: 'yank selection' },
  { key: 'd', command: 'delete selection' },
  { key: 'p', command: 'paste after selection' },
  { key: 'u', command: 'convert selection to lowercase' },
  { key: 'U', command: 'convert selection to uppercase' },

  // Window Management
  { key: ':vsp', command: 'vertical split' },
  { key: ':sp', command: 'horizontal split' },
  { key: 'ctrl+w w', command: 'cycle through windows' },
  { key: 'ctrl+w c', command: 'close window' },
  { key: 'ctrl+w =', command: 'equalize window sizes' },
  { key: 'ctrl+w h', command: 'move to left window' },
  { key: 'ctrl+w j', command: 'move to bottom window' },
  { key: 'ctrl+w k', command: 'move to top window' },
  { key: 'ctrl+w l', command: 'move to right window' },

  // File Management
  { key: ':e <file>', command: 'edit file' },
  { key: ':w', command: 'save file' },
  { key: ':wq', command: 'save and close file' },
  { key: ':q', command: 'quit' },
  { key: ':q!', command: 'force quit without saving' },
  { key: ':x', command: 'save and quit' },
  { key: ':r <file>', command: 'read file into buffer' },
  { key: ':n', command: 'next file in args' },
  { key: ':prev', command: 'previous file in args' },
  { key: ':b <buffer>', command: 'switch to buffer' },
  { key: ':bd', command: 'delete buffer' },

  // Bookmarks
  { key: 'm<letter>', command: 'set mark' },
  { key: "'<letter>", command: 'jump to mark' },
  { key: ':marks', command: 'list marks' },
  { key: 'dm<letter>', command: 'delete mark' },
  { key: '`<letter>', command: 'jump to marked position' },
  { key: "':marks", command: 'open mark line' },
  { key: "':delmarks", command: 'delete all marks' },

  // Replace
  { key: ':s/old/new', command: 'replace in line' },
  { key: ':%s/old/new', command: 'replace in file' },
  { key: ':%s/old/new/g', command: 'replace all in file' },
  { key: ':%s/old/new/gc', command: 'replace with confirmation' },
  { key: ':s/foo/bar/gn', command: 'count replacements' },
  { key: ':cfdo %s/old/new/g', command: 'replace in all open files' },
  { key: ':grep <pattern>', command: 'search files for pattern' },
  { key: ':vimgrep /pattern/g *.txt', command: 'grep pattern in file' },
  { key: ':cfdo update', command: 'update changes in open files' },

  // Search
  { key: '/', command: 'search forward' },
  { key: '?', command: 'search backward' },
  { key: 'n', command: 'next match' },
  { key: 'N', command: 'previous match' },
  { key: '*', command: 'search word under cursor forward' },
  { key: '#', command: 'search word under cursor backward' },
  { key: 'g*', command: 'partial word forward search' },
  { key: 'g#', command: 'partial word backward search' },
  { key: ':noh', command: 'remove search highlights' },

  // Misc
  { key: ':!<command>', command: 'execute shell command' },
  { key: ':source <file>', command: 'execute file commands' },
  { key: ':set <option>', command: 'set editor option' },
  { key: ':set nu', command: 'show line numbers' },
  { key: ':set rnu', command: 'show relative line numbers' },
  { key: ':set list', command: 'show invisible characters' },
  { key: ':set nohlsearch', command: 'turn off search highlights' },
  { key: ':set wrap', command: 'wrap text' },
  { key: ':set nowrap', command: 'no text wrap' },
  { key: ':registers', command: 'list registers' },
];

export const excelShortcuts = [
  // Entering Data I
  { key: 'tab', command: 'move to next cell' },
  { key: 'enter', command: 'move to cell below' },
  { key: 'shift+tab', command: 'move to previous cell' },
  { key: 'shift+enter', command: 'move to cell above' },
  { key: 'ctrl+enter', command: 'fill selected cells with entry' },
  { key: 'alt+enter', command: 'start new line in cell' },
  { key: 'esc', command: 'cancel cell entry' },

  // Entering Data II
  { key: 'ctrl+; ', command: 'enter current date' },
  { key: 'ctrl+shift+; ', command: 'enter current time' },
  { key: "ctrl+'", command: 'copy cell above' },
  { key: 'ctrl+d', command: 'fill down' },
  { key: 'ctrl+r', command: 'fill right' },
  { key: 'ctrl+-', command: 'delete row or column' },
  { key: 'ctrl+shift++', command: 'insert row or column' },

  // Row / Column Operations I
  { key: 'ctrl+space', command: 'select column' },
  { key: 'shift+space', command: 'select row' },
  { key: 'ctrl+shift+=', command: 'insert cell, row, or column' },
  { key: 'ctrl+-', command: 'delete cell, row, or column' },
  { key: 'alt+shift+arrow', command: 'group rows/columns' },
  { key: 'alt+shift+-', command: 'ungroup rows/columns' },
  { key: 'alt+; ', command: 'select visible cells only' },

  // Row / Column Operations II
  { key: 'ctrl+shift+9', command: 'unhide rows' },
  { key: 'ctrl+9', command: 'hide rows' },
  { key: 'ctrl+shift+0', command: 'unhide columns' },
  { key: 'ctrl+0', command: 'hide columns' },
  { key: 'alt+shift+arrow', command: 'auto-sum rows/columns' },

  // General I
  { key: 'ctrl+s', command: 'save workbook' },
  { key: 'ctrl+p', command: 'print workbook' },
  { key: 'ctrl+n', command: 'new workbook' },
  { key: 'ctrl+o', command: 'open workbook' },
  { key: 'ctrl+w', command: 'close workbook' },
  { key: 'ctrl+z', command: 'undo' },
  { key: 'ctrl+y', command: 'redo' },
  { key: 'f12', command: 'save as' },
  { key: 'f1', command: 'open help' },

  // General II
  { key: 'ctrl+c', command: 'copy' },
  { key: 'ctrl+v', command: 'paste' },
  { key: 'ctrl+x', command: 'cut' },
  { key: 'ctrl+f', command: 'find' },
  { key: 'ctrl+h', command: 'replace' },
  { key: 'ctrl+arrow', command: 'jump to data edge' },
  { key: 'ctrl+home', command: 'go to top of sheet' },
  { key: 'ctrl+end', command: 'go to bottom of sheet' },
  { key: 'ctrl+shift+arrow', command: 'select to data edge' },

  // Navigation I
  { key: 'ctrl+page up', command: 'move to previous sheet' },
  { key: 'ctrl+page down', command: 'move to next sheet' },
  { key: 'f5', command: 'go to specific cell' },
  { key: 'alt+arrow', command: 'scroll sheet' },
  { key: 'ctrl+scroll', command: 'zoom in/out' },
  { key: 'ctrl+shift+l', command: 'toggle filters' },

  // Navigation II
  { key: 'ctrl+tab', command: 'switch workbooks' },
  { key: 'ctrl+shift+tab', command: 'switch to previous workbook' },
  { key: 'ctrl+arrow', command: 'move to next data region' },
  { key: 'alt+q', command: 'search the ribbon' },
  { key: 'alt+h', command: 'navigate to Home tab' },
  { key: 'alt+n', command: 'navigate to Insert tab' },

  // Selection
  { key: 'ctrl+a', command: 'select all' },
  { key: 'shift+arrow', command: 'expand selection by cell' },
  { key: 'ctrl+shift+*', command: 'select data region' },
  { key: 'shift+space', command: 'select entire row' },
  { key: 'ctrl+space', command: 'select entire column' },
  { key: 'ctrl+shift+space', command: 'select entire sheet' },
  { key: 'ctrl+click', command: 'select multiple cells' },

  // Active Cell
  { key: 'f2', command: 'edit active cell' },
  { key: 'ctrl+enter', command: 'fill selected cells with active cell entry' },
  { key: 'alt+enter', command: 'insert line break in cell' },
  { key: 'shift+enter', command: 'move to previous cell' },
  { key: 'enter', command: 'move to next cell' },
  { key: 'tab', command: 'move to next cell to the right' },
  { key: 'shift+tab', command: 'move to previous cell to the left' },

  // Extend Selection
  { key: 'ctrl+shift+arrow', command: 'extend selection to data edge' },
  { key: 'shift+home', command: 'extend selection to beginning of row' },
  { key: 'ctrl+shift+home', command: 'extend selection to top of sheet' },
  { key: 'shift+end', command: 'extend selection to end of row' },
  { key: 'ctrl+shift+end', command: 'extend selection to bottom of sheet' },
  { key: 'shift+page up', command: 'extend selection up by one screen' },
  { key: 'shift+page down', command: 'extend selection down by one screen' },
  { key: 'ctrl+shift+*', command: 'select entire data region' },
  { key: 'ctrl+shift+o', command: 'select cells with comments' },
  { key: 'ctrl+shift+8', command: 'select all data in the block' },
  { key: 'ctrl+.', command: 'cycle through selection corners' },

  // Formatting
  { key: 'ctrl+1', command: 'format cells' },
  { key: 'ctrl+b', command: 'bold' },
  { key: 'ctrl+i', command: 'italic' },
  { key: 'ctrl+u', command: 'underline' },
  { key: 'ctrl+shift+&', command: 'apply border' },
  { key: 'ctrl+shift+_', command: 'remove border' },
  { key: 'ctrl+shift+$', command: 'apply currency format' },
  { key: 'ctrl+shift+%', command: 'apply percentage format' },
  { key: 'ctrl+5', command: 'strikethrough' },
  { key: 'alt+enter', command: 'wrap text' },
  { key: 'ctrl+alt+v', command: 'paste special' },
  { key: 'alt+h+o+i', command: 'auto-fit column width' },
  { key: 'alt+h+o+a', command: 'auto-fit row height' },

  // Select Special
  { key: 'ctrl+g', command: 'go to special' },
  { key: 'alt+; ', command: 'select visible cells only' },
  { key: 'ctrl+shift+8', command: 'select all data in region' },
  { key: 'ctrl+shift+o', command: 'select cells with comments' },
  { key: 'f5', command: 'go to specific cell' },
  { key: 'ctrl+shift+p', command: 'select cells with conditional formatting' },
  { key: 'ctrl+shift+r', command: 'select cells with data validation' },
  { key: 'ctrl+shift+e', command: 'select empty cells' },
  { key: 'ctrl+shift+d', command: 'select duplicate cells' },
  { key: 'ctrl+shift+k', command: 'select locked cells' },
  { key: 'ctrl+shift+u', command: 'select unlocked cells' },

  // Ribbon
  { key: 'alt', command: 'activate ribbon' },
  { key: 'alt+h', command: 'go to Home tab' },
  { key: 'alt+n', command: 'go to Insert tab' },
  { key: 'alt+f', command: 'go to File tab' },

  // Cell Edit Mode
  { key: 'f2', command: 'edit active cell' },
  { key: 'esc', command: 'exit cell edit mode' },
  { key: 'ctrl+z', command: 'undo cell edit' },
  { key: 'ctrl+y', command: 'redo cell edit' },

  // Number Formatting
  { key: 'ctrl+shift+!', command: 'apply number format' },
  { key: 'ctrl+shift+@', command: 'apply time format' },
  { key: 'ctrl+shift+#', command: 'apply date format' },
  { key: 'ctrl+shift+~', command: 'apply general format' },
  { key: 'ctrl+shift+^', command: 'apply scientific format' },
  { key: 'ctrl+shift+&', command: 'apply border' },
  { key: 'ctrl+shift+%', command: 'apply percentage format' },

  // Borders
  { key: 'ctrl+shift+&', command: 'apply border' },
  { key: 'ctrl+shift+_', command: 'remove border' },
  { key: 'alt+h+b+a', command: 'apply all borders' },
  { key: 'alt+h+b+n', command: 'remove all borders' },

  // Formulas
  { key: '=', command: 'start formula' },
  { key: 'alt+=', command: 'auto-sum' },
  { key: 'ctrl+shift+u', command: 'expand/collapse formula bar' },
  { key: 'ctrl+`', command: 'toggle formulas view' },
  { key: 'f9', command: 'calculate active sheet' },
  { key: 'shift+f3', command: 'insert function' },
  { key: 'ctrl+shift+a', command: 'insert function arguments' },

  // Pivot Tables
  { key: 'alt+n+v', command: 'create pivot table' },
  { key: 'alt+j+t', command: 'go to pivot table Analyze tab' },
  { key: 'alt+j+y', command: 'go to pivot table Design tab' },
  { key: 'alt+down', command: 'open filter dropdown' },
  { key: 'alt+shift+f', command: 'open Field List pane' },
  { key: 'alt+d+p', command: 'create pivot table wizard' },
];

export const commandLineShortcuts = [
  // System Basic
  { key: 'pwd', command: 'print working directory' },
  { key: 'whoami', command: 'show current user' },
  { key: 'hostname', command: 'display system hostname' },
  { key: 'uname', command: 'display OS information' },
  { key: 'uname -a', command: 'display detailed system info' },
  { key: 'date', command: 'display current date and time' },
  { key: 'uptime', command: 'display system uptime' },
  { key: 'clear', command: 'clear terminal screen' },
  { key: 'exit', command: 'close terminal session' },
  { key: 'echo $SHELL', command: 'display default shell' },
  { key: 'echo $PATH', command: 'display PATH environment variable' },
  { key: 'who', command: 'show who is logged in' },
  { key: 'df -h', command: 'display disk space usage' },

  // Directories Basic
  { key: 'ls', command: 'list directory contents' },
  { key: 'ls -a', command: 'list all files including hidden' },
  { key: 'ls -l', command: 'list in long format' },
  { key: 'cd [dir]', command: 'change directory' },
  { key: 'cd ..', command: 'go up one directory' },
  { key: 'cd ~', command: 'go to home directory' },
  { key: 'mkdir [dir]', command: 'create a new directory' },
  { key: 'rmdir [dir]', command: 'remove an empty directory' },
  { key: 'pwd', command: 'print working directory' },
  { key: 'tree', command: 'display directory structure' },
  { key: 'ls -R', command: 'list directory contents recursively' },
  { key: 'cd -', command: 'go to previous directory' },

  // Files Basic
  { key: 'touch [file]', command: 'create a new file' },
  { key: 'cp [source] [destination]', command: 'copy file' },
  { key: 'mv [source] [destination]', command: 'move/rename file' },
  { key: 'rm [file]', command: 'remove file' },
  { key: 'cat [file]', command: 'display file content' },
  { key: 'less [file]', command: 'view file with scroll' },
  { key: 'head [file]', command: 'view first 10 lines of file' },
  { key: 'tail [file]', command: 'view last 10 lines of file' },
  { key: 'chmod [permissions] [file]', command: 'change file permissions' },
  { key: 'chown [user]:[group] [file]', command: 'change file owner' },
  { key: 'ln -s [file] [link]', command: 'create symbolic link' },

  // Search Basic
  { key: 'grep [pattern] [file]', command: 'search for pattern in file' },
  { key: 'find [dir] -name [filename]', command: 'find files by name' },
  { key: 'locate [file]', command: 'locate file in system' },
  { key: 'which [command]', command: 'find location of command' },

  // Shortcuts
  { key: 'ctrl+a', command: 'move to start of line' },
  { key: 'ctrl+e', command: 'move to end of line' },
  { key: 'ctrl+u', command: 'clear line before cursor' },
  { key: 'ctrl+k', command: 'clear line after cursor' },
  { key: 'ctrl+w', command: 'delete word before cursor' },
  { key: 'ctrl+y', command: 'paste last deleted text' },
  { key: 'alt+b', command: 'move backward by word' },
  { key: 'alt+f', command: 'move forward by word' },
  { key: 'ctrl+d', command: 'delete character under cursor' },
  { key: 'ctrl+h', command: 'delete character before cursor' },
  { key: 'ctrl+r', command: 'reverse search history' },
  { key: 'tab', command: 'auto-complete command or filename' },

  // Processes
  { key: 'ps', command: 'display current processes' },
  { key: 'top', command: 'display live system processes' },
  { key: 'kill [pid]', command: 'terminate process by PID' },
  { key: 'pkill [name]', command: 'terminate process by name' },
  { key: 'bg', command: 'move process to background' },
  { key: 'fg', command: 'move process to foreground' },
  { key: 'jobs', command: 'list background processes' },
  { key: 'killall [name]', command: 'terminate all processes by name' },

  // Command History
  { key: 'history', command: 'list command history' },
  { key: '!!', command: 'repeat last command' },
  { key: '!n', command: 'repeat nth command' },
  { key: 'ctrl+p', command: 'previous command in history' },
  { key: 'ctrl+n', command: 'next command in history' },
  { key: 'ctrl+r', command: 'search command history' },

  // Compression
  { key: 'tar -czvf [file].tar.gz [dir]', command: 'create tar.gz archive' },
  { key: 'tar -xzvf [file].tar.gz', command: 'extract tar.gz archive' },
  { key: 'zip [file].zip [files]', command: 'create zip archive' },
  { key: 'unzip [file].zip', command: 'extract zip archive' },
  { key: 'gzip [file]', command: 'compress file with gzip' },
  { key: 'gunzip [file].gz', command: 'decompress gzip file' },
];

// export const gitShortcuts = [
//   // Git Basics
//   { key: 'git init', command: 'initialize a new Git repository' },
//   { key: 'git clone [url]', command: 'clone a repository from a URL' },
//   { key: 'git status', command: 'show the status of changes' },
//   { key: 'git add [file]', command: 'stage changes for commit' },
//   { key: "git commit -m '[message]'", command: 'commit staged changes' },
//   { key: 'git push', command: 'push changes to remote repository' },
//   { key: 'git pull', command: 'pull changes from remote repository' },
//   { key: 'git fetch', command: 'fetch changes from remote' },
//   { key: 'git log', command: 'view commit history' },
//   { key: 'git diff', command: 'show changes between commits' },
//   { key: 'git reset [file]', command: 'unstage a staged file' },
//   { key: 'git checkout [branch]', command: 'switch to a different branch' },

//   // Branching
//   { key: 'git branch', command: 'list all branches' },
//   { key: 'git branch [name]', command: 'create a new branch' },
//   {
//     key: 'git checkout -b [name]',
//     command: 'create and switch to a new branch',
//   },
//   {
//     key: 'git merge [branch]',
//     command: 'merge another branch into current branch',
//   },
//   {
//     key: 'git rebase [branch]',
//     command: 'reapply commits on top of another base branch',
//   },
//   { key: 'git branch -d [name]', command: 'delete a branch' },
//   { key: 'git branch -D [name]', command: 'force delete a branch' },
//   { key: 'git checkout [commit]', command: 'checkout a specific commit' },
//   {
//     key: 'git cherry-pick [commit]',
//     command: 'apply a commit from another branch',
//   },
//   {
//     key: 'git stash branch [branch]',
//     command: 'create a branch from stashed changes',
//   },
//   {
//     key: 'git checkout -- [file]',
//     command: 'discard changes in working directory',
//   },
//   { key: 'git switch [branch]', command: 'switch to another branch' },
//   { key: 'git switch -c [name]', command: 'create and switch to a new branch' },
//   { key: 'git switch -d [name]', command: 'delete a branch' },
//   { key: 'git switch -m [old] [new]', command: 'rename a branch' },

//   // Merging / Rebasing
//   {
//     key: 'git merge [branch]',
//     command: 'merge another branch into current branch',
//   },
//   {
//     key: 'git rebase [branch]',
//     command: 'reapply commits on top of another base branch',
//   },
//   { key: 'git merge --abort', command: 'abort a merge' },
//   { key: 'git rebase --abort', command: 'abort a rebase' },
//   {
//     key: 'git rebase --continue',
//     command: 'continue after resolving conflicts',
//   },
//   { key: 'git rebase -i [commit]', command: 'interactive rebase from commit' },
//   {
//     key: 'git merge --no-ff [branch]',
//     command: 'create a merge commit even if fast-forwarding',
//   },

//   // Remotes
//   { key: 'git remote -v', command: 'show remote repositories' },
//   {
//     key: 'git remote add [name] [url]',
//     command: 'add a new remote repository',
//   },
//   { key: 'git remote remove [name]', command: 'remove a remote repository' },
//   {
//     key: 'git push [remote] [branch]',
//     command: 'push changes to a specific remote branch',
//   },
//   {
//     key: 'git pull [remote] [branch]',
//     command: 'pull changes from a specific remote branch',
//   },
//   {
//     key: 'git fetch [remote]',
//     command: 'fetch changes from a specific remote repository',
//   },

//   // Commit Logs
//   { key: 'git log', command: 'view commit history' },
//   { key: 'git log --oneline', command: 'view commit history in one line' },
//   { key: 'git log --graph', command: 'view commit history as a graph' },
//   { key: 'git log --author=[name]', command: 'filter commits by author' },
//   {
//     key: 'git log --since=[date]',
//     command: 'filter commits since a specific date',
//   },
//   {
//     key: 'git log --until=[date]',
//     command: 'filter commits until a specific date',
//   },
//   { key: 'git show [commit]', command: 'show details of a specific commit' },

//   // Undo / Change History
//   {
//     key: 'git reset [commit]',
//     command: 'reset current branch to a specific commit',
//   },
//   {
//     key: 'git reset --hard [commit]',
//     command: 'reset to a specific commit and discard changes',
//   },
//   { key: 'git checkout -- [file]', command: 'discard changes in a file' },
//   {
//     key: 'git revert [commit]',
//     command: 'create a new commit that undoes changes',
//   },
//   { key: 'git stash', command: 'save changes temporarily' },
//   { key: 'git stash pop', command: 'apply last stashed changes' },
//   { key: 'git stash list', command: 'list all stashed changes' },

//   // Using Tags
//   { key: 'git tag', command: 'list all tags' },
//   { key: 'git tag [name]', command: 'create a new tag' },
//   { key: 'git tag -d [name]', command: 'delete a tag' },
//   { key: 'git show [tag]', command: 'show details of a tag' },
//   { key: 'git push origin [tag]', command: 'push a tag to remote' },
//   { key: 'git push --tags', command: 'push all tags to remote' },
//   { key: 'git checkout [tag]', command: 'checkout a specific tag' },
//   { key: 'git describe [tag]', command: 'describe a tag' },
//   {
//     key: "git tag -a [name] -m '[message]'",
//     command: 'create an annotated tag',
//   },
//   { key: "git tag -s [name] -m '[message]'", command: 'create a signed tag' },

//   // Using Stashes
//   { key: 'git stash', command: 'save changes temporarily' },
//   { key: 'git stash list', command: 'list all stashes' },
//   { key: 'git stash pop', command: 'apply last stashed changes' },
//   { key: 'git stash apply [stash]', command: 'apply a specific stash' },
//   { key: 'git stash drop [stash]', command: 'delete a specific stash' },
//   { key: 'git stash clear', command: 'remove all stashes' },
//   {
//     key: "git stash save '[message]'",
//     command: 'stash changes with a message',
//   },
//   { key: 'git stash show', command: 'show changes in the last stash' },
//   { key: 'git stash show -p', command: 'show patch of last stash' },
//   { key: 'git stash branch [branch]', command: 'create a branch from stash' },

//   // Using Bisect
//   { key: 'git bisect start', command: 'start bisecting' },
//   { key: 'git bisect bad', command: 'mark current commit as bad' },
//   { key: 'git bisect good [commit]', command: 'mark commit as good' },
//   { key: 'git bisect reset', command: 'end bisecting' },
//   { key: 'git bisect visualize', command: 'visualize the bisect process' },
//   { key: 'git bisect replay', command: 'replay the bisect session' },
// ];
export const windowsShortcuts = [
  // Entering Data I
  { key: 'Ctrl + N', command: 'Create a new document' },
  { key: 'Ctrl + O', command: 'Open an existing document' },
  { key: 'Ctrl + S', command: 'Save the current document' },
  { key: 'Ctrl + P', command: 'Print the current document' },
  { key: 'Ctrl + Z', command: 'Undo the last action' },
  { key: 'Ctrl + Y', command: 'Redo the last undone action' },
  { key: 'Ctrl + A', command: 'Select all content' },

  // Entering Data II
  { key: 'Ctrl + C', command: 'Copy the selected content' },
  { key: 'Ctrl + X', command: 'Cut the selected content' },
  { key: 'Ctrl + V', command: 'Paste the copied content' },
  { key: 'Ctrl + F', command: 'Open the Find dialog' },
  { key: 'Ctrl + H', command: 'Open the Replace dialog' },
  { key: 'Ctrl + B', command: 'Bold the selected text' },
  { key: 'Ctrl + I', command: 'Italicize the selected text' },

  // Row / Column Operations I
  { key: 'Ctrl + Shift + +', command: 'Insert a new row or column' },
  { key: 'Ctrl + -', command: 'Delete the selected row or column' },
  {
    key: 'Ctrl + Arrow Keys',
    command: 'Navigate to the edge of the data region',
  },
  { key: 'Shift + Space', command: 'Select the entire row' },
  { key: 'Ctrl + Space', command: 'Select the entire column' },
  { key: 'Alt + Enter', command: 'Insert a new line within a cell' },
  { key: 'Ctrl + D', command: 'Fill down' },

  // Row / Column Operations II
  { key: 'Ctrl + R', command: 'Fill right' },
  { key: 'F2', command: 'Edit the selected cell' },
  { key: 'Ctrl + 1', command: 'Open the Format Cells dialog' },
  { key: 'Ctrl + Shift + L', command: 'Add or remove filters' },
  { key: 'Ctrl + T', command: 'Create a table' },

  // General I
  { key: 'Alt + F4', command: 'Close the active window' },
  { key: 'Windows + D', command: 'Show or hide the desktop' },
  { key: 'Alt + Tab', command: 'Switch between open applications' },
  { key: 'Windows + E', command: 'Open File Explorer' },
  { key: 'Windows + L', command: 'Lock your PC' },
  { key: 'Windows + I', command: 'Open Settings' },
  { key: 'Windows + R', command: 'Open the Run dialog' },
  { key: 'F1', command: 'Open Help' },
  { key: 'Ctrl + Shift + Esc', command: 'Open Task Manager' },

  // General II
  { key: 'Print Screen', command: 'Take a screenshot' },
  {
    key: 'Windows + Shift + S',
    command: 'Take a screenshot of a selected area',
  },
  { key: 'Alt + Space', command: 'Open the window menu' },
  { key: 'Ctrl + Esc', command: 'Open the Start menu' },
  { key: 'Windows + X', command: 'Open the Quick Link menu' },
  { key: 'Ctrl + Shift + N', command: 'Create a new folder' },
  { key: 'F5', command: 'Refresh the current window' },
  { key: 'Esc', command: 'Cancel the current task' },
  { key: 'Ctrl + F4', command: 'Close the current tab or window' },

  // Navigation I
  { key: 'Home', command: 'Go to the beginning of the line' },
  { key: 'End', command: 'Go to the end of the line' },
  { key: 'Page Up', command: 'Scroll up one screen' },
  { key: 'Page Down', command: 'Scroll down one screen' },
  {
    key: 'Arrow Keys',
    command: 'Move the cursor one character/line at a time',
  },
  { key: 'Ctrl + Home', command: 'Go to the beginning of the document' },

  // Navigation II
  { key: 'Ctrl + End', command: 'Go to the end of the document' },
  { key: 'Ctrl + Page Up', command: 'Move to the previous worksheet' },
  { key: 'Ctrl + Page Down', command: 'Move to the next worksheet' },
  { key: 'Alt + Left Arrow', command: 'Go back in the browsing history' },
  { key: 'Alt + Right Arrow', command: 'Go forward in the browsing history' },
  { key: 'Ctrl + Click', command: 'Select multiple non-adjacent items' },

  // Selection
  {
    key: 'Shift + Arrow Keys',
    command: 'Extend the selection by one character/line',
  },
  { key: 'Ctrl + A', command: 'Select all content' },
  { key: 'Ctrl + Shift + Arrow Keys', command: 'Select a word or line' },
  { key: 'Shift + Space', command: 'Select the entire row' },
  { key: 'Ctrl + Space', command: 'Select the entire column' },
  { key: 'Ctrl + Shift + L', command: 'Toggle filters' },
  {
    key: 'Ctrl + Shift + 8',
    command: 'Select all cells with similar formatting',
  },

  // Active Cell
  { key: 'F2', command: 'Edit the active cell' },
  { key: 'Enter', command: 'Complete a cell entry and move down' },
  { key: 'Tab', command: 'Complete a cell entry and move right' },
  { key: 'Shift + Enter', command: 'Complete a cell entry and move up' },
  { key: 'Shift + Tab', command: 'Complete a cell entry and move left' },
  { key: "Ctrl + '", command: 'Copy the formula from the cell above' },
  { key: 'Ctrl + D', command: 'Fill the cell down' },

  // Extend Selection
  {
    key: 'Ctrl + Shift + End',
    command: 'Select from current cell to the last used cell',
  },
  {
    key: 'Ctrl + Shift + Home',
    command: 'Select from current cell to the first cell',
  },
  {
    key: 'Ctrl + Shift + Page Up',
    command: 'Select from current cell to the previous worksheet',
  },
  {
    key: 'Ctrl + Shift + Page Down',
    command: 'Select from current cell to the next worksheet',
  },
  { key: 'Shift + Click', command: 'Select a range of cells' },
  { key: 'Ctrl + A', command: 'Select all cells in the worksheet' },
  {
    key: 'Ctrl + Shift + Arrow Keys',
    command: 'Extend selection to the next data region',
  },
  { key: 'Shift + Space', command: 'Select the entire row' },
  { key: 'Ctrl + Space', command: 'Select the entire column' },
  { key: 'Ctrl + Shift + *', command: 'Select the current region' },
  { key: 'Ctrl + Shift + 9', command: 'Unhide rows' },

  // Formatting
  { key: 'Ctrl + B', command: 'Toggle bold formatting' },
  { key: 'Ctrl + I', command: 'Toggle italic formatting' },
  { key: 'Ctrl + U', command: 'Toggle underline formatting' },
  { key: 'Ctrl + 1', command: 'Open the Format Cells dialog' },
  { key: 'Alt + H, H', command: 'Open fill color menu' },
  { key: 'Alt + H, F, C', command: 'Open font color menu' },
  { key: 'Alt + H, A, C', command: 'Align center' },
  { key: 'Ctrl + 5', command: 'Toggle strikethrough formatting' },
  { key: 'Ctrl + Shift + &', command: 'Add outline border' },
  { key: 'Ctrl + Shift + _', command: 'Remove outline border' },
  { key: 'Ctrl + Shift + $', command: 'Apply currency format' },
  { key: 'Ctrl + Shift + %', command: 'Apply percentage format' },

  // Miscellaneous
  { key: 'F12', command: 'Open the Save As dialog' },
  { key: 'Ctrl + N', command: 'Open a new window in File Explorer' },
  { key: 'Alt + F', command: 'Open the File menu' },
  { key: 'Alt + E', command: 'Open the Edit menu' },
  { key: 'Alt + V', command: 'Open the View menu' },
  { key: 'Ctrl + Y', command: 'Redo the last action' },
  { key: 'Ctrl + R', command: 'Refresh the active window' },
  { key: 'Ctrl + K', command: 'Insert a hyperlink' },
  { key: 'Alt + H, A, R', command: 'Align right' },
  { key: 'Ctrl + Shift + 5', command: 'Apply percentage format' },
  { key: 'Ctrl + Shift + P', command: 'Open the font size menu' },
];

export const browserShortcuts = [
  // Navigation
  { key: 'Ctrl + T', command: 'Open a new tab' },
  { key: 'Ctrl + W', command: 'Close the current tab' },
  { key: 'Ctrl + Shift + T', command: 'Reopen the last closed tab' },
  { key: 'Ctrl + Tab', command: 'Switch to the next tab' },
  { key: 'Ctrl + Shift + Tab', command: 'Switch to the previous tab' },
  { key: 'Ctrl + L', command: 'Focus the address bar' },
  { key: 'Alt + Home', command: 'Open your homepage' },

  // Bookmarking
  { key: 'Ctrl + D', command: 'Bookmark the current page' },
  { key: 'Ctrl + Shift + B', command: 'Show or hide the bookmarks bar' },
  { key: 'Ctrl + Shift + O', command: 'Open the bookmarks manager' },

  // Page Navigation
  { key: 'Space', command: 'Scroll down the page' },
  { key: 'Shift + Space', command: 'Scroll up the page' },
  { key: 'Home', command: 'Go to the top of the page' },
  { key: 'End', command: 'Go to the bottom of the page' },

  // History
  { key: 'Ctrl + H', command: 'Open the browsing history' },
  { key: 'Backspace', command: 'Go back to the previous page' },
  { key: 'Shift + Backspace', command: 'Go forward to the next page' },

  // Miscellaneous
  { key: 'F5', command: 'Refresh the current page' },
  { key: 'Ctrl + F', command: 'Open the Find bar' },
  { key: 'F11', command: 'Toggle fullscreen mode' },
  { key: 'Ctrl + P', command: 'Print the current page' },
  { key: 'Ctrl + U', command: 'View page source' },
  { key: 'Ctrl + Shift + I', command: 'Open Developer Tools' },
];
